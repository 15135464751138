import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// mui
import { Button } from "@mui/material";

// components
import SpotlightResultLoader from "../../spotlight/spotlight-result-loader";
import CustomReactPlayer from "../../custom-react-player";
import DialogAlert from "../../dialog-alert";

// redux
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight-slice";

// utils

// constants

// styles
import useStyles from "./index.styles";

// lottie

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CustomisedVideoPlayer from "../../video-player-customised";

/**
 * 
import durationLottie from '../../../lottie-assets/duration.json';
import speechRateLottie from '../../../lottie-assets/speech-rate.json';
import keywordMatchLottie from '../../../lottie-assets/keyword-match.json';
import clarityLottie from '../../../lottie-assets/clarity.json';
import transcriptMatchLottie from '../../../lottie-assets/transcript-match.json';
import toneLottie from '../../../lottie-assets/tone.json';

const LOTTIE_ARRAY = [
    {
        id: 'durationLottie',
        lottie: durationLottie,
    },
    {
        id: 'speechrateLottie',
        lottie: speechRateLottie,
    },
    {
        id: 'keymatchLottie',
        lottie: keywordMatchLottie,
    },
    {
        id: 'clarityLottie',
        lottie: clarityLottie,
    },
    {
        id: 'transcriptmatchLottie',
        lottie: transcriptMatchLottie,
    },
    {
        id: 'toneLottie',
        lottie: toneLottie,
    },

]
 */

const PreviewVideo = (props) => {
    const {
        screenStateList,
        videoBlob,
        spotlightData,
        showAnalyzeOverlay,
        isSubmitting,
        videoUploadProgress,
        isEmptySpeech,
        setIsEmptySpeech,
        attemptId,
        handleMediaSubmit
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const params = useParams();
    // const dialogueContentDefault = "No speech detected";
    const dialogueContentDefault = "No speech detected, please speak more clearly and be louder.";
    const { t } = useTranslation()

    // const getChallengeAsync = async () => {
    //     let respData = await startChallengeAttempt({ microskillId, challengeId });
    //     if (respData.status === 200 || respData.status === 201) {
    //         handleMediaSubmit(respData?.data?.data.id)
    //     }
    // }
    
    useEffect(() => {
        window.onbeforeunload = function () {
            return t('You have unsaved data. do you really want to reload?');
        }
        return () => {
            window.onbeforeunload = "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirmBtnClick = () => {
        setIsEmptySpeech(false);
        dispatch(handleSpotlightScreenState(screenStateList.RECORD));
        // navigate(-1);
    }

    return (
        <div className={classes.recordVideoRoot}>
            <div className={classes.recordingText}>{t("VIDEO PREVIEW")}</div>
            {/* <div className={classes.recordingText}>{t("RECORDING COMPLETE")}</div> */}
            <div className={`${classes.previewVideoBox} ${classes.centerFlex}`}>
                {/* <CustomReactPlayer mediaUrl={videoBlob} height={'80%'} /> */}
                <CustomisedVideoPlayer videoPath={videoBlob} rootHeight={""} />

            </div>

            <Button
                fullWidth
                onClick={() => handleMediaSubmit(attemptId)}
                disableElevation
                variant="contained"
                color={isSubmitting ? 'grey' : 'primary'}
                size="large"
                style={{
                    backgroundSize: isSubmitting ? `${videoUploadProgress}% 100%` : null,
                    backgroundImage: isSubmitting ? 'linear-gradient(#a5a5a5, #a5a5a5)' : null,
                }}
                className={`fixed_ratio_20_3 ${classes.submitBtn}`}
            >
                <div className={`${classes.btnProgressBarBox} ${classes.centerFlex}`}
                // style={{ backgroundSize: `${videoUploadProgress}% 100%`, }}
                >
                    <div>
                        <b>{isSubmitting ? `Uploading (${videoUploadProgress}%)` : true ? t('Analyze') : t('Submit')}</b>
                    </div>
                </div>
            </Button>

            <Button variant="text"
                // color="white"
                sx={theme => ({
                    color: theme.palette.greyText4,
                })}
                size="medium"
                fullWidth
                disableElevation
                className={`fixed_ratio_20_3`}
                onClick={() => dispatch(handleSpotlightScreenState(screenStateList.RECORD))}
            >
                <u><b>{t("Record Again")}</b></u>
            </Button>

            {
                showAnalyzeOverlay && (
                    <div className={`${classes.analyzeOverlayRoot} scrollbar`}>
                        <SpotlightResultLoader data={spotlightData.criteria} />
                    </div>
                )
            }

            {
                isEmptySpeech && (
                    <DialogAlert
                        isOpen={isEmptySpeech}
                        title={''}
                        content={t(dialogueContentDefault)}
                        confirmBtnName={'Record Again'}
                        handleConfirmBtnClick={handleConfirmBtnClick}
                        errorIcon={true}
                        fullWidthBtn={true}
                        messageInCenter={true}
                        messageInBold={true}
                    />
                )
            }
        </div>
    )
}

export default PreviewVideo;