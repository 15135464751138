import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    mainContentContainer: {
        padding: '1rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px',
            background: "#cdcdcd",
            borderRadius: '5px',

            [theme.breakpoints.down('sm')]: {
                width: '5px',
            }
            // display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '5px'
        }
    },

    contextQuestionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem'
    },

    contextTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: '1rem'
    },

    questionContainer: {
        width: "100%"
    },

    questionTitle: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
        lineHeight: '1.5'
    }
}));

export default useStyles;
