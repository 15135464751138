import React, { useRef, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import isEmpty from "../../utils/isEmpty";
import ImageMagnifier from "../image-magnifier";
import { markRepoViewed } from "../../redux/content-repo/content-repo.api";
import useGlobalAbortController from "../../utils/abortController";
import { useNavigate } from "react-router";

const responsive = constants.SCREEN_RESPONSIVENESS_DOCUMENT;
const FULL_HEIGHT = constants.FULL_HEIGHT;

const DocumentImageViewer = (props) => {
    const { images, index, from, setIsShareButtonVisible, setIsDownloadButtonVisible } = props;
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const carouselRef = useRef();
    const mainRef = useRef();
    const magnifierWrapperRef = useRef();
    const isRotatedMobile = useSelector(state => state?.common.isRotatedMobile)
    const { setNewAbortSignal } = useGlobalAbortController();
    const navigate = useNavigate();

    useEffect(() => {
        if (carouselRef && carouselRef.current) {
            carouselRef.current.goToSlide(index);

            // console.log({images, index})
            setTimeout(() => {
                if (mainRef.current) mainRef.current.style.opacity = 1
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselRef]);

    const handleAfterChange = (previousSlide, { currentSlide }) => {
        // mark file to viewed

        if (from === 'SALES_ENABLEMENT' || from === 'GENERIC_DOC_VIEWER' || from === "ILA") return;
        const signal = setNewAbortSignal();

        let repo_id = from === 'ILA' ? images[currentSlide]?.id : images[currentSlide]?.repositoryId;
        markRepoViewed(repo_id, signal);
    }

    // useEffect(() => {
    //     console.log('isRotatedMobile', isRotatedMobile)
    //     if (isRotatedMobile && isMobile) {
    //         if (!isEmpty(mainRef.current)) {
    //             console.log("main Ref is not empty")
    //             let mainRefHeight = mainRef.current.clientHeight;
    //             let mainRefWidth = mainRef.current.clientWidth;
    //             console.log({ mainRefHeight, mainRefWidth })

    //             if (!isEmpty(magnifierWrapperRef.current)) {
    //                 console.log("maginifier wrapper is not empty", magnifierWrapperRef.current)
    //                 magnifierWrapperRef.current.style.height = `${mainRefWidth}px`;
    //                 magnifierWrapperRef.current.style.width = `${mainRefHeight}px`;
    //                 magnifierWrapperRef.current.style.transform = 'rotate(90deg)';
    //             }
    //         }
    //     } else {
    //         if (!isEmpty(magnifierWrapperRef.current)) magnifierWrapperRef.current.style = "";
    //     }
    // }, [isMobile, isRotatedMobile, mainRef.current, magnifierWrapperRef.current])

    return (
        <div className={classes.documentImageViewerRoot} ref={mainRef}
        // style={isRotatedMobile ? {
        //     width: '100vw',
        //     display: 'block',
        //     transform: 'rotate(90deg)',
        // } : {}}
        >
            {
                !isEmpty(images) && (
                    <Carousel
                        ref={carouselRef}
                        additionalTransfrom={0}
                        // arrows={!isMobile}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className={classes.carouselContainer}
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={90}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        afterChange={handleAfterChange}
                    >
                        {
                            images.map((item, i) => {
                                return (
                                    <div
                                        key={item?.repositoryId}
                                        className={classes.magnifierWrapper}
                                        ref={magnifierWrapperRef}
                                        style={isRotatedMobile ? {
                                            transform: 'rotate(90deg)',
                                            width: mainRef.current?.clientHeight + 'px',
                                            height: mainRef.current?.clientWidth + 'px',
                                        } : {}}>
                                        {/* <React.Fragment key={from === 'ILA' ? item.id : item.repositoryId} > */}
                                        {
                                            isRotatedMobile ?
                                                <img key={from === 'ILA' ? item.id : item.repositoryId}
                                                    src={from === 'ILA' ? item.path : item.Repository.path}
                                                />
                                                :
                                                <ImageMagnifier
                                                    imageUrl={(from === 'ILA' || from === 'GENERIC_DOC_VIEWER') ? item.path : item.Repository.path}
                                                    setIsShareButtonVisible={setIsShareButtonVisible}
                                                    setIsDownloadButtonVisible={setIsDownloadButtonVisible}
                                                    enableFileDownload={item.Repository.enableFileDownload}
                                                    enableFileShare={item.Repository.enableFileShare}
                                                />

                                        }
                                        {/* </React.Fragment> */}
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                )
            }

        </div>
    )
}

export default DocumentImageViewer;