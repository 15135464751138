import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    root: {
        // height: '100%',
        width: '100%',
    },
    wrapper: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    loadingWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '5',
        background: 'rgba(255, 255, 255, 0.9)'

    },
    timeoutWapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '6',
        background: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        flexDirection: 'column'
    },

    controlsWrapper: {
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '3.5rem',
        padding: '1rem',
        bottom: '0',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.9) 1%, transparent)',
        display: 'grid',
        gridTemplateColumns: '10% 90%'
    },
    playWrapper: {
        justifyContent: 'flex-start !important',
        cursor: 'pointer'
    },
    seekingWrapper: {
        width: '100%'
    },
    trackSlider: {
        width: '100%'
        // 
    },
    slider: {
        height: "5px",
        WebkitAppearance: "none",
        width: "100%",
        // marginBottom: "10px",
        borderRadius: "59px",
        background: "#564273",
        transition: "background 0.2s ease",
        cursor: "pointer",
        '&::-webkit-slider-thumb': {
            '-webkit-appearance': 'none',
            appearance: 'none',
            width: '20px',
            height: '20px',
            background: theme.palette.orange,
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/like.svg)'
        },
        '&::-moz-range-thumb': {
            width: '20px',
            height: '20px',
            background: theme.palette.orange,
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/playbtn.svg)'
        }
    },
    timerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0rem 0.8rem',
        '& > div': {
            fontSize: '0.8rem',
            fontFamily: 'montserrat',
            [theme.breakpoints.down("sm")]: {
                fontSize: '1.2rem',
            },
        }
    },
}));

export default useStyle;
