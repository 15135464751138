import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

// MUI Components
import { useTheme } from '@mui/styles';
import { Button, CircularProgress, Dialog } from "@mui/material";
import { AccessAlarm } from '@mui/icons-material';

// Utils
import isEmpty from "../../utils/isEmpty";

// API's
import { fetchFormData, startFormAttempt, submitEndFormAttempt } from "../../redux/form/form.api";

// Styles
import useStyles from "./index.styles";

// Components
import FormInfoContainer from "./form-info-container";
// import FormContentContainer from "./form-content-container";
import FormContentContainer from "../form-as-challenge/form-content-container";

import DialogAlert from "../dialog-alert";
import FormsWinScreen from "./formsWinScreen";
import FormsLooseScreen from "./formsLooseScreen/index.js";

// Constants
import constants from "../../constants";

//redux 
import { setFormIdData, setIsFormOpen } from "../../redux/form/form-slice.js";
import { useSelector } from "react-redux";

const Forms = (props) => {
    const { formId, isDialog, openForm } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(true);

    const [formName, setFormName] = React.useState("");
    const [formDescription, setFormDescription] = React.useState("");
    const [isAssessment, setIsAssessment] = React.useState(false);
    const [questions, setQuestions] = React.useState([]);
    const [totalTime, setTotalTime] = React.useState(0);
    const [showResult, setShowResult] = React.useState(false);
    const [minScore, setMinScore] = React.useState(0);
    const [contexts, setContexts] = React.useState([]);
    const [score, setScore] = React.useState(0);
    const [maxScore, setMaxScore] = React.useState(0);
    const [totalMandatoryQuestions, setTotalMandatoryQuestions] = React.useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const submitButtonText = "Submit";
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
    const [isFormCompleted, setIsFormCompleted] = React.useState(false);
    const [dialogDiscardBtn, setDialogDiscardBtn] = React.useState('');
    const [isTimeUp, setIsTimeUp] = React.useState(false);


    const [attemptId, setAttemptId] = React.useState("");
    const [formData, setFormData] = React.useState([]);
    const [attemptLevelData, setAttemptLevelData] = useState([])

    // Timer
    const formTimerRef = React.useRef(null);
    const timeLimitRef = React.useRef(0);
    const timeSpentRef = React.useRef(0);

    // // Styles
    // const isMobile = useSelector(state => state?.common?.isMobile);
    // const isTablet = useSelector(state => state?.common?.isTablet);

    const classes = useStyles();

    // Dialog Alert
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [dialogConfirmBtn, setDialogConfirmBtn] = React.useState("");

    // Form Submission Screen for Assesments
    const [showLooseScreen, setShowLooseScreen] = React.useState(false);

    const fetchFormDataUsingFormId = async (formId) => {
        const respData = await fetchFormData(formId);

        if (respData.status === 200 || respData.status === 201) {
            const formData = respData.data.data;
            const formDataFromAPI = formData.Form;

            setFormName(formDataFromAPI.name);
            setFormDescription(formDataFromAPI.description);
            setIsAssessment(formDataFromAPI.assessment || false);
            setShowResult(formDataFromAPI.showScore)

            setQuestions(formDataFromAPI.Questions || []);
            setScore(formData.score || 0);

            let calculateMaxScore = 0;
            formDataFromAPI.Questions.forEach(question => {
                const totalCorrectAnswer = question.answers.filter(ans => ans.isCorrect === true).length;
                if (question.type === 'SINGLE_CHOICE_QUESTION' || question.type === 'MULTI_CHOICE_QUESTION') {
                    calculateMaxScore += totalCorrectAnswer * question.scorePerResponse;
                } else {
                    calculateMaxScore += question.scorePerResponse;
                }
            })
            setMaxScore(calculateMaxScore || 0);

            const totalNoOfMandatoryQuestions = formDataFromAPI.Questions.filter((question) => question.mandatory === true).length;
            setTotalMandatoryQuestions(totalNoOfMandatoryQuestions);
            setTotalTime(formDataFromAPI.timeLimit || 0);
            setMinScore(formDataFromAPI.settings?.minScore || 0);
            setContexts(formDataFromAPI.contexts || []);

            if (!formData.completed) {
                getAttemptIdForForm();
            } else {
                handleFormAlreadySubmitted();
            }
        }
    }

    const handleFormAlreadySubmitted = () => {
        setIsFormSubmitted(true);
        setIsFormCompleted(true);
    }

    const getAttemptIdForForm = async () => {
        let body = {
            formId
        };

        const resp = await startFormAttempt(body);
        if (resp.status === 200 || resp.status === 201) {
            const attemptId = resp.data.data.id;
            setAttemptId(attemptId);
        }
    }

    const startFormTimer = () => {
        if (isFormSubmitted) return;
        clearInterval(formTimerRef.current);
        let time = 0;
        if (isAssessment) {
            formTimerRef.current = setInterval(() => {
                timeLimitRef.current.innerHTML = totalTime - time;
                timeSpentRef.current = time;
                time += 1;

                if (time > totalTime) {
                    setIsFormSubmitted(true);
                    setShowLooseScreen(true);
                    setIsTimeUp(true);
                }
            }, 1000);
        } else {
            formTimerRef.current = setInterval(() => {
                timeLimitRef.current.innerHTML = time;
                timeSpentRef.current = time;
                time += 1;
            }, 1000);
        }
    }

    const submitForm = async () => {

        clearInterval(formTimerRef.current);

        let completion = true;
        let status = "COMPLETED";

        let scoreObtained = 0;

        attemptLevelData.forEach(data => {
            const correctAnswersCount = data.answers.filter(answer => answer.isCorrect === true).length;
            const questionScore = questions.find(ques => ques.id === data.questionId).scorePerResponse;
            scoreObtained += (correctAnswersCount * questionScore);
        });

        setScore(scoreObtained);
        if (isAssessment && (scoreObtained < minScore || isTimeUp)) {
            completion = false;
            status = isTimeUp ? 'TIMERLOSE' : 'FAILED';
            setShowLooseScreen(true);
        }

        let body = {
            formId,
            attemptId,
            completion,
            scored: scoreObtained,
            status,
            timespent: timeSpentRef.current,
            questions: attemptLevelData
        };

        const resp = await submitEndFormAttempt(body);
        if (resp.status !== 200 && resp.status !== 201) {
            setDialogConfirmBtn("Okay");
            setDialogContent("Something went wrong. Please try again later.");
            setIsDialogOpen(true);
        }

    };

    const markFormAttemptExited = async () => {
        clearInterval(formTimerRef.current);
        let body = {
            formId,
            attemptId,
            completion: false,
            scored: 0,
            status: "EXITED",
            timespent: totalTime - timeLimitRef.current,
            questions: []
        };

        const resp = await submitEndFormAttempt(body);
        if (resp.status === 200 || resp.status === 201) {
            clearOnUmMount();
        } else {
            setDialogConfirmBtn("Okay");
            setDialogContent("Something went wrong. Please try again later.");
            setIsDialogOpen(true);
        }
    }

    const handleNextButtonClickOnForm = () => {
        clearOnUmMount();
    }

    const handleCloseForm = async () => {
        if (!isFormSubmitted) {
            setDialogConfirmBtn("Yes");
            setDialogDiscardBtn("No");
            setDialogContent("Are you sure you want to exit? Your progress will be lost.");
            setIsDialogOpen(true);
        } else {
            window.location.reload();
        }
    }

    const handleDialogAlertClose = async () => {
        try {
            if (!isFormSubmitted) {
                await markFormAttemptExited();
                setIsDialogOpen(false);
                clearOnUmMount();
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleErrorAttemptDataSubmit = () => {
        setLoading(true);
        clearOnUmMount();
        setDialogConfirmBtn("Okay");
        setDialogContent("Something went wrong. Please try again later.");
        setIsDialogOpen(true);
    }


    const clearOnUmMount = () => {
        setLoading(true);
        dispatch(setFormIdData(null));
        // setFormId("");
        setIsTimeUp(false);
        // setOpenForm(false);
        dispatch(setIsFormOpen(false));
        setFormName("");
        setFormDescription("");
        setTotalTime(0);
        setShowResult(false);
        setMinScore(0);
        setTotalMandatoryQuestions(0);
        setSubmitButtonDisabled(false);
        clearInterval(formTimerRef.current);
        formTimerRef.current = null;
        setIsFormSubmitted(false);
        setIsAssessment(false);
        setQuestions([]);
        setContexts([]);
        setAttemptId("");
        setFormData([]);
        setAttemptLevelData([]);
        // attemptLevelData.current = [];
        // setSubmitButtonDisabled(false);
    }

    const handleSetAttemptLevelData = (completeAttemptData) => {
        setAttemptLevelData(completeAttemptData);
    }

    const handleTryAgain = () => {
        setLoading(true);
        getAttemptIdForForm();
        setFormData([]);
        setAttemptLevelData([]);
        setIsFormSubmitted(false);
        setShowLooseScreen(false);
        setIsTimeUp(false);
        if (isAssessment) {
            startFormTimer();
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            await fetchFormDataUsingFormId(formId);
        }

        if (!isEmpty(formId)) {
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId]);

    useEffect(() => {
        clearInterval(formTimerRef.current);

        (async () => {
            if (isFormSubmitted && !isFormCompleted) {
                await submitForm();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormSubmitted]);


    useEffect(() => {
        if (!isEmpty(formId) && (!isEmpty(attemptId) || isFormCompleted) && isEmpty(formData)) {

            if (!isEmpty(questions)) {
                const temporaryAttemptData = questions.map((question) => {
                    return { questionId: question.id, answers: [], contextId: question.contextId || "", sequence: question.sequence, isMandatory: question.mandatory };
                });

                // attemptLevelData.current = temporaryAttemptData;
                setAttemptLevelData(temporaryAttemptData);
            }

            if (!isEmpty(questions)) {
                const contextIds = contexts.map(context => context.id);

                // filter questions which do not have context
                const questionsWithoutContext = questions.filter(question => !contextIds.includes(question.contextId));
                const questionsWithContext = questions.filter(question => contextIds.includes(question.contextId));

                const tempFormData = [];
                if (!isEmpty(questionsWithoutContext)) {
                    tempFormData.push({ contextId: "", context: "", questions: questionsWithoutContext });
                }

                if (!isEmpty(questionsWithContext)) {
                    contexts.forEach(context => {
                        const contextQuestions = questionsWithContext.filter(question => question.contextId === context.id);
                        tempFormData.push({ contextId: context.id, context: context.context, questions: contextQuestions });
                    });
                }

                setFormData({ formId, attemptId, contexts: tempFormData });
            }
        }
        else if (!isEmpty(formId) && !isEmpty(attemptId) && !isEmpty(formData)) {
            setLoading(false);
            if (isAssessment) {
                startFormTimer();
            }
        } else if (!isEmpty(formData) && isFormSubmitted) {
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, contexts, formId, attemptId, formData]);


    // Screen Resize Event handling
    const initialHeight = window.innerHeight;
    const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < initialHeight) {
                setIsKeyboardOpen(true);
            } else {
                setIsKeyboardOpen(false);
            }
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [initialHeight]);

    const handleDialogDiscardClick = () => {
        setIsDialogOpen(false);
    }


    const handleSubmitButtonDisabled = () => {
        const mandatoryAnsweredCount = attemptLevelData.reduce((count, data) => {
            return data.isMandatory && !isEmpty(data.answers) ? count + 1 : count;
        }, 0);

        setSubmitButtonDisabled(mandatoryAnsweredCount !== totalMandatoryQuestions);
    };


    useEffect(() => {
        if (!isEmpty(attemptLevelData) && totalMandatoryQuestions > 0) {
            handleSubmitButtonDisabled();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attemptLevelData, totalMandatoryQuestions]);

    const formUI = () => {
        return (
            <>
                {loading ?
                    <div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                        <CircularProgress />
                    </div>
                    :
                    (
                        !isFormSubmitted ? (
                            <div className={classes.formContainer} style={{
                                // height: isMobile && isKeyboardOpen ? "100dvh" : "100%",
                                fontSize: () => {
                                    let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
                                    return langObj.font;
                                },
                            }}>
                                <div className={`${classes.centerFlex} ${classes.iconContainer}`}>
                                    {
                                        isAssessment ? (
                                            <div className={`${classes.timerContainer} ${classes.centerFlex}`}>
                                                <AccessAlarm style={{ color: '#F4511E', margin: 'auto' }} />
                                                <div className={classes.timerDiv}>
                                                    <span style={{ fontSize: '1.2rem' }} ref={timeLimitRef}>{totalTime}</span>
                                                    <span style={{ fontSize: '1.2rem' }}>s</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={classes.closeIcon}>
                                                <img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />
                                            </div>
                                        )
                                    }
                                </div>

                                <div className={classes.scrollingContainer}>
                                    <FormInfoContainer
                                        formName={formName}
                                        formDescription={formDescription}
                                        isAssessment={isAssessment}
                                        handleCloseForm={handleCloseForm}
                                        ref={timeLimitRef}
                                        totalTime={totalTime}
                                    />


                                    <FormContentContainer
                                        formData={formData}
                                        attemptLevelData={attemptLevelData}
                                        handleSetAttemptLevelData={handleSetAttemptLevelData}
                                        handleErrorAttemptDataSubmit={handleErrorAttemptDataSubmit}
                                    />

                                </div>

                                <div className={`${classes.centerFlex} ${classes.formSubmitContainer}`}>
                                    <Button
                                        className={classes.formSubmitButton}
                                        variant="contained"
                                        disabled={submitButtonDisabled}
                                        onClick={() => {
                                            if (!submitButtonDisabled) {
                                                setIsFormSubmitted(true);
                                            }
                                        }}

                                        style={{
                                            color: theme.palette.common.white,
                                            background: submitButtonDisabled ? "#787878" : "#F4511E"
                                        }}
                                    >
                                        {t(submitButtonText)}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            isAssessment && showLooseScreen ? (
                                <FormsLooseScreen name={formName} description={formDescription} formData={formData} attemptData={attemptLevelData} handleCloseForm={handleCloseForm} handleTryAgain={handleTryAgain} showResult={showResult} score={score} maxScore={maxScore} isTimeUp={isTimeUp} />
                            ) : (
                                <FormsWinScreen name={formName} description={formDescription} formData={formData} attemptData={attemptLevelData} handleCloseForm={handleCloseForm} showResult={showResult} score={score} maxScore={maxScore} handleNextButtonClickOnForm={handleNextButtonClickOnForm} />
                            )
                        )
                    )
                }
            </>
        )
    }

    return (
        <>
            <Dialog
                open={openForm}
                onClose={(_, reason) => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") { }
                }}

                className={classes.dialog}
                PaperProps={{
                    className: `${classes.dialogPaper} ${classes.paperProps}`,
                }}
            >
                {formUI()}
            </Dialog>

            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={dialogConfirmBtn}
                        discardBtnName={dialogDiscardBtn}
                        handleConfirmBtnClick={handleDialogAlertClose}
                        handleDiscardBtnClick={handleDialogDiscardClick}
                    />
                )
            }
        </>
    )
}

export default Forms;