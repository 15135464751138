import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

// context
import LearningAidContext from "../../../context/learningAidContext";

// mui
import LanguageIcon from '@mui/icons-material/Language';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

// components
import CustomReactPlayer from "../../custom-react-player";
import DocumentModal from "../document-modal";
// redux

// utils
import isEmpty from "../../../utils/isEmpty";

// theme
// import palette from "../../../theme/palette";

// constants
// import constants from "../../../constants";

// styles
import useStyles from "./index.styles";
import ImageMagnifier from "../../image-magnifier";
import constants from "../../../constants";
import palette from "../../../theme/palette";
import webViewJavaScriptBridge from "webview-javascript-bridge";

// card and value should be alphabatically sorted
const cardTypeList = [
    {
        id: '1',
        card: 'I',
        value: 'IMAGE'
    },
    {
        id: '2',
        card: 'V',
        value: 'VIDEO'
    },
    {
        id: '3',
        card: 'T',
        value: 'TITLE'
    },
    {
        id: '4',
        card: 'B',
        value: 'BODY'
    },
    {
        id: '5',
        card: 'IT',
        value: 'IMAGE,TITLE'
    },
    {
        id: '6',
        card: 'BT',
        value: 'BODY,TITLE'
    },
    {
        id: '7',
        card: 'TV',
        value: 'TITLE,VIDEO'
    },
    {
        id: '8',
        card: 'BIT',
        value: 'BODY,IMAGE,TITLE'
    },
    {
        id: '9',
        card: 'BTV',
        value: 'BODY,TITLE,VIDEO'
    }, {
        id: '10',
        card: 'BTY',
        value: 'BODY,TITLE,YOUTUBE'
    },
    {
        id: '11',
        card: 'Y',
        value: 'YOUTUBE'
    },
];


const VideoPlayerHoc = ({ data, class_name = "", dpip = false, ctrlList = "nodownload", styling }) => {
    return (
        <video
            className={class_name}
            preload="metadata"
            width="100%"
            controls
            playsInline
            disablePictureInPicture={dpip}
            controlsList={ctrlList}
            style={styling}
            onLoadedMetadata={(event) => {
                event.target.currentTime = 0.5
            }}
        >
            <source src={data.VIDEO && data.VIDEO.content} type="video/mp4" />
            <source src={data.VIDEO && data.VIDEO.content} type="video/ogg" />
            Your browser does not support the video tag.
        </video>
    )
}

const CustomReactPlayerHoc = (props) => {
    const {
        isVideoPlaying,
        setIsVideoPlaying,
        handleOnPlay,
        handleOnPause,
        controls = false,
        mediaUrl,
        customPlayButton = true,
        fullScreenBtn = true
    } = props;
    return (
        <CustomReactPlayer
            mediaUrl={mediaUrl}
            borderRadius={'0px'}
            onPause={handleOnPause}
            onPlay={handleOnPlay}
            isPlaying={isVideoPlaying}
            controls={controls}
            customPlayButton={customPlayButton}
            fullScreenBtn={fullScreenBtn}
            iconColor={palette.white}
            // iconColor={palette.black}
            iconOpacity="0.5"
        />
    )
}

const ORIENTATION = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE'
}

const DynamicLaCard = (props) => {
    const { data, viewFullScreen, cardDetailsData } = props;
    const { files, isFileViewerOpen, handleDocumentBtnClick } = useContext(LearningAidContext)
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const isNativeApp = useSelector(state => state?.common?.isNativeApp);
    const classes = useStyles({ viewFullScreen, isMobile });
    const cardName = (Object.keys(data)).sort().toString();
    const cardType = cardTypeList.find(d => d.value === cardName)?.card;
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [fSize, setFSize] = useState(1);
    const [imageOrientation, setImageOrientation] = React.useState(ORIENTATION.PORTRAIT);

    const [isVideoRotated, setIsVideoRotated] = React.useState(false)
    const [isYoutubeRotated, setIsYoutubeRotated] = React.useState(false)
    const videoTagRef = React.useRef()
    const videoBoxRef = React.useRef()
    const rotatedImgWrprRef = React.useRef();

    const bgImgURL = cardDetailsData.card.backgroundImageURL
    const dataInProps = data // For the auto Rotaion Flag for video and Youtube

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleOnPause = () => {
        setIsVideoPlaying(false);
    }
    const handleOnPlay = () => {
        setIsVideoPlaying(true)
    }


    useEffect(() => {
        webViewJavaScriptBridge.registerMessageHandler("rotateFlag", (data) => {
            // console.log("rotateFlag", data);
            if (data === "landscape")
                dataInProps.VIDEO && dataInProps.VIDEO.content ? setIsVideoRotated(true) : setIsYoutubeRotated(true);
            else
                dataInProps.VIDEO && dataInProps.VIDEO.content ? setIsVideoRotated(false) : setIsYoutubeRotated(false);
        })
    }, [])


    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);
    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    const handleRemoveStyling = (data) => {
        // Create a DOMParser to parse the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');

        // Function to remove the style attribute from an element
        function removeStyleAttribute(node) {
            if (node.nodeType === Node.ELEMENT_NODE) {
                node.removeAttribute('style');
                node.removeAttribute('color');
                for (let i = 0; i < node.children.length; i++) {
                    removeStyleAttribute(node.children[i]);
                }
            }
        }


        // Remove style attributes from all elements in the parsed HTML
        removeStyleAttribute(doc.body);

        // Convert the modified DOM back to an HTML string
        const sanitizedHTML = doc.documentElement.outerHTML;
        return sanitizedHTML
    }


    const RotateButtonHoc = ({ setterFunction }) => {
        return (
            <button className={classes.rotateButton}
                onClick={(e) => {
                    e.stopPropagation();
                    setterFunction(prev => !prev);
                }}
            >
                <ScreenRotationIcon className={classes.rotationBtn} />
            </button>
        )
    }


    async function animateCardTypes(card_type) {
        let delay = 1000 * 1.5;
        if (card_type === 'BTV' || card_type === 'BTY' || card_type === 'Y' || card_type === 'V') {
            delay = 1000 * 1;
        }
        const CARD_ITEMS = document.getElementsByClassName(`${card_type}_CARD_ITEMS`)
        for (let i = 0; i < CARD_ITEMS.length; i++) {
            const item = CARD_ITEMS[i]
            item.style.visibility = 'visible';
            item.style.opacity = 1;
            await sleep(delay)
        }
    }
    useEffect(() => {
        if (isEmpty(cardType)) return
        animateCardTypes(cardType)

    }, [cardType])

    const backgroundImgStying = (bgImgUrl) => {
        if (isEmpty(bgImgUrl)) return {}
        return {
            backgroundImage: `linear-gradient(rgba(0 ,0 ,0 ,0.5), rgba(0 ,0 ,0 ,0.5)), url(${bgImgUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
    }

    const isKeyExists = (obj, key) => {
        return Object.keys(obj).includes(key)
    }

    useEffect(() => {
        // console.log(cardDetailsData);
        if (isEmpty(cardDetailsData)) return;
        if (isEmpty(cardDetailsData.card)) return;
        if (isKeyExists(cardDetailsData.card, 'orientation')) {
            setImageOrientation(cardDetailsData.card.orientation)
        } else {
            setImageOrientation(ORIENTATION.PORTRAIT)
        }
    }, [cardDetailsData])

    return (
        <div className={`${classes.cardRoot}`} style={{
            fontSize: isMobile ? `${fSize}rem` : '1rem',
        }} >
            {
                (isMobile || isTablet) && !isEmpty(files) && (
                    <div className={`${classes.documentLink} ${classes.centerFlex}`} onClick={() => handleDocumentBtnClick()}>
                        {
                            files.type === 'link' ? (<LanguageIcon sx={{ fontSize: '1.8rem', color: 'white' }} id="documentLink" />)
                                :
                                (<img src="/images/icons/page_white.png" alt="icon" id="documentLink" />)
                        }
                    </div>
                )
            }

            {
                !isEmpty(data?.IMAGE?.content) && (
                    <div className={classes.backdrop}
                        style={{ backgroundImage: (isMobile || isTablet) ? `linear-gradient(#fff, #fff)` : `url(${data.IMAGE.content})` }}
                    />
                )
            }

            {/* Image */}
            {
                cardType === 'I' && (
                    <div
                        className={`${classes.imageCardRoot} ${classes.hidden} ${cardType}_CARD_ITEMS`}
                        style={backgroundImgStying(bgImgURL)}
                    >
                        {imageOrientation === ORIENTATION.LANDSCAPE && (isMobile || isTablet) ? (
                            <div
                                className={`${imageOrientation === ORIENTATION.LANDSCAPE ? classes.rotatedImage : ''}`}
                                ref={rotatedImgWrprRef}
                            >
                                <img
                                    src={data.IMAGE.content}
                                    alt="img"
                                    className={`${classes.onlyImage}`}
                                    id="rotatedImage"
                                    onLoad={() => {
                                        if (!isEmpty(rotatedImgWrprRef.current)) {
                                            const boundingRect = rotatedImgWrprRef.current.getBoundingClientRect();
                                            const topVal = boundingRect.top
                                            // Get the root font size from the computed style of the document's root element
                                            let rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

                                            const desiredTopVal = 4 * rootFontSize;
                                            // console.log(topVal, desiredTopVal)
                                            if (topVal > desiredTopVal) {
                                                const diff = topVal - desiredTopVal;
                                                rotatedImgWrprRef.current.style.top = `-${Math.floor(diff)}px`;
                                            }
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <ImageMagnifier imageUrl={data.IMAGE.content} />// Image Magnifier Component
                        )}
                    </div>
                )
            }
            {/* Image */}

            {/* Body || Title*/}
            {
                (cardType === 'B' || cardType === 'T') && (
                    <div
                        className={`${classes.btCardRoot}`}
                        style={backgroundImgStying(bgImgURL)}
                    >
                        <div
                            className={`${classes.laCardBodyMain} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                            style={{
                                // color: data.BODY.color,  // it can be use in future, please don't remove
                                color: isEmpty(bgImgURL) ? '' : palette.white
                            }}
                        >
                            {
                                cardType === 'B' ?
                                    (<div dangerouslySetInnerHTML={{ __html: data?.BODY?.content }}></div>)
                                    :
                                    (<div dangerouslySetInnerHTML={{ __html: data?.TITLE?.content }}></div>)
                            }
                        </div>
                    </div>
                )
            }
            {/* Body || Title*/}


            {/* Body Title */}
            {
                cardType === 'BT' && (
                    <div
                        className={`${classes.btCardRoot}`}
                        style={backgroundImgStying(bgImgURL)}
                    >
                        <div
                            className={`${classes.laCardTitle} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                            style={{
                                // color: data.BODY.color,  // it can be use in future, please don't remove
                                color: isEmpty(bgImgURL) ? '' : palette.white
                            }}

                        >
                            <div dangerouslySetInnerHTML={{ __html: data?.TITLE?.content }}></div>
                            {/* {data.TITLE.content} */}
                        </div>
                        <div
                            className={`${classes.laCardBodyMain} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                            style={{
                                // color: data.BODY.color,  // it can be use in future, please don't remove
                                color: isEmpty(bgImgURL) ? '' : palette.white
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: data?.BODY?.content }}></div>
                            {/* {data.BODY.content} */}
                        </div>
                    </div>
                )
            }
            {/* Body Title */}

            {/* Body Image Title */}
            {
                cardType === 'BIT' && (
                    <div
                        className={`${classes.bitCardRoot}`}
                        style={backgroundImgStying(bgImgURL)}
                    >
                        <div
                            className={`${classes.bitCardTitle} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                            style={{
                                // color: data.BODY.color,  // it can be use in future, please don't remove
                                color: isEmpty(bgImgURL) ? '' : palette.white
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: data?.TITLE?.content }}></div>
                            {/* {data.TITLE.content} */}
                        </div>
                        <div className={classes.bitSection}>
                            <div className={`${classes.bitImageBox} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS `}>
                                {/* <img src={data.IMAGE.content} /> */}

                                {/* // Image Magnifier Component */}
                                <ImageMagnifier imageUrl={data.IMAGE.content} />
                            </div>
                            <div
                                className={`${classes.bitCardBody} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                                style={{
                                    // color: data.BODY.color,  // it can be use in future, please don't remove
                                    color: isEmpty(bgImgURL) ? '' : palette.white
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: data?.BODY?.content }}></div>
                                {/* {data.BODY.content} */}
                            </div>
                        </div>
                    </div>
                )
            }
            {/* Body Image Title */}


            {/* Body Title Video*/}
            {((cardType === 'V' || cardType === 'Y') && isMobile) && (
                <div
                    className={`${classes.bitCardRootOnlyVideo}`}
                    style={backgroundImgStying(bgImgURL)}
                >
                    <div className={classes.bitSectionOnlyVideo}>
                        <div className={`${classes.bitImageBoxOnlyVideo} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS `}>
                            <div
                                className={`${classes.videoBox} ${classes.hidden} ${cardType}_CARD_ITEMS`}
                                ref={videoBoxRef}
                                style={{
                                    position: (isVideoRotated || isYoutubeRotated) ? 'static' : 'relative',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                {/* <span className={classes.rotateBtnPropmpt}>Click here to rotate.</span> */}
                                <RotateButtonHoc setterFunction={data.VIDEO && data.VIDEO.content ? setIsVideoRotated : setIsYoutubeRotated} />

                                {/* Navigation Buttons */}
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation(); // Prevent the event from reaching the video player
                                        props.handlePrevClick();
                                    }}
                                    className={classes.prevButton}
                                >
                                    <img
                                        src="/images/learning-aid/back.svg"
                                        alt="back button"
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                </button>

                                {/* Video or YouTube Player Logic */}
                                {/* {isMobile ? ( */}
                                {data.VIDEO && data.VIDEO.content ? (
                                    <div style={{ height: '100%' }}>
                                        <VideoPlayerHoc
                                            data={data}
                                            styling={{ maxHeight: "100%", height: "100%" }}
                                            class_name={isVideoRotated ? classes.rotatedVideo : classes.resetRotation}
                                            dpip={true}
                                            ctrlList={"nofullscreen nodownload noremoteplayback noplaybackrate"}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={isYoutubeRotated ? classes.rotatedVideo : ""}
                                        style={{ height: '100%' }}
                                    >
                                        <CustomReactPlayerHoc
                                            isVideoPlaying={isVideoPlaying}
                                            setIsVideoPlaying={setIsVideoPlaying}
                                            handleOnPlay={handleOnPlay}
                                            handleOnPause={handleOnPause}
                                            controls={true}
                                            mediaUrl={data.YOUTUBE && data.YOUTUBE.content}
                                            customPlayButton={false}
                                            fullScreenBtn={false}
                                        />
                                    </div>
                                )
                                    // ) : (
                                    //     <CustomReactPlayerHoc
                                    //         isVideoPlaying={isVideoPlaying}
                                    //         setIsVideoPlaying={setIsVideoPlaying}
                                    //         handleOnPlay={handleOnPlay}
                                    //         handleOnPause={handleOnPause}
                                    //         mediaUrl={(data.VIDEO && data.VIDEO.content) || (data.YOUTUBE && data.YOUTUBE.content)}
                                    //         customPlayButton={data.YOUTUBE ? false : true}
                                    //     />
                                    // )
                                }

                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation(); // Prevent the event from reaching the video player
                                        props.handleNextBtnClick();
                                    }}
                                    className={classes.nextButton}
                                >
                                    <img
                                        src="/images/learning-aid/next.svg"
                                        alt="back button"
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                (cardType === 'BTV' || cardType === 'BTY' || ((cardType === 'V' || cardType === 'Y') && !isMobile)) && (
                    isMobile ? (
                        <div
                            className={`${classes.bitCardRoot}`}
                            style={backgroundImgStying(bgImgURL)}
                        >
                            <div
                                className={`${classes.bitCardTitle} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                                style={{
                                    // color: data.BODY.color,  // it can be use in future, please don't remove
                                    color: isEmpty(bgImgURL) ? '' : palette.white
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: data?.TITLE?.content }}></div>
                                {/* {data.TITLE.content} */}
                            </div>
                            <div className={classes.bitSection}>
                                <div className={`${classes.bitImageBox} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS `}>
                                    {/* <div className={`${classes.bitImageBox} ${classes.hidden} ${cardType}_CARD_ITEMS `}> */}

                                    <div
                                        className={`${classes.videoBox} ${classes.hidden} ${cardType}_CARD_ITEMS`}
                                        // className={`${classes.videoBox} ${classes.hidden} ${(isVideoRotated || isYoutubeRotated) ? classes.rotatedVideo : "" } ${cardType}_CARD_ITEMS`}
                                        ref={videoBoxRef}
                                        style={{ position: (isVideoRotated || isYoutubeRotated) ? 'static' : 'relative', width: '100%', height: '100%' }}
                                    >
                                        <span className={classes.rotateBtnPropmpt}>Click here to rotate.</span>
                                        <RotateButtonHoc setterFunction={data.VIDEO && data.VIDEO.content ? setIsVideoRotated : setIsYoutubeRotated} />
                                        {
                                            isMobile ? (
                                                data.VIDEO && data.VIDEO.content ? (
                                                    <VideoPlayerHoc
                                                        data={data}
                                                        // !isVideoRotated && <VideoPlayerHoc
                                                        styling={{ maxHeight: "100%" }}
                                                        // styling={isVideoRotated ? rotatedVideoStyling : { maxHeight: "100%" }}
                                                        class_name={isVideoRotated ? classes.rotatedVideo : classes.resetRotation}
                                                        dpip={true}
                                                        ctrlList={"nofullscreen nodownload noremoteplayback noplaybackrate"}
                                                    />
                                                ) : (
                                                    <div className={isYoutubeRotated ? classes.rotatedVideo : ""} style={{ height: '100%' }}>
                                                        <CustomReactPlayerHoc
                                                            isVideoPlaying={isVideoPlaying}
                                                            setIsVideoPlaying={setIsVideoPlaying}
                                                            handleOnPlay={handleOnPlay}
                                                            handleOnPause={handleOnPause}
                                                            controls={true}
                                                            mediaUrl={data.YOUTUBE && data.YOUTUBE.content}
                                                            customPlayButton={false}
                                                            fullScreenBtn={false}
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <CustomReactPlayerHoc sVideoPlaying={isVideoPlaying}
                                                    setIsVideoPlaying={setIsVideoPlaying}
                                                    handleOnPlay={handleOnPlay}
                                                    handleOnPause={handleOnPause}
                                                    mediaUrl={(data.VIDEO && data.VIDEO.content) || (data.YOUTUBE && data.YOUTUBE.content)}
                                                    customPlayButton={data.YOUTUBE ? false : true}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div
                                    className={`${classes.bitCardBody} ${classes.centerFlex} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                                    style={{
                                        color: isEmpty(bgImgURL) ? '' : palette.white
                                    }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: data?.BODY?.content }}></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${classes.videoCardRoot}`}
                            style={backgroundImgStying(bgImgURL)}
                        >
                            {
                                isMobile && <RotateButtonHoc setterFunction={data.VIDEO && data.VIDEO.content ? setIsVideoRotated : setIsYoutubeRotated} />
                            }
                            <div className={`${classes.videoCardBox}`}>
                                {
                                    !isMobile && <div className={classes.videoTextGradient} />
                                }
                                <div
                                    className={`${classes.videoBox} ${classes.hidden} ${cardType}_CARD_ITEMS`}
                                    // className={`${classes.videoBox} ${classes.hidden} ${(isVideoRotated || isYoutubeRotated) ? classes.rotatedVideo : "" } ${cardType}_CARD_ITEMS`}
                                    ref={videoBoxRef}
                                    style={{ color: data?.TITLE?.color }}
                                >
                                    {
                                        isMobile ? (
                                            data.VIDEO && data.VIDEO.content ? (
                                                <VideoPlayerHoc
                                                    data={data}
                                                    // !isVideoRotated && <VideoPlayerHoc
                                                    styling={{ maxHeight: "100%" }}
                                                    // styling={isVideoRotated ? rotatedVideoStyling : { maxHeight: "100%" }}
                                                    class_name={isVideoRotated ? classes.rotatedVideo : classes.resetRotation}
                                                    dpip={true}
                                                    ctrlList={"nofullscreen nodownload noremoteplayback noplaybackrate"}
                                                />
                                            ) : (
                                                <div className={isYoutubeRotated ? classes.rotatedVideo : ""}>
                                                    <CustomReactPlayerHoc
                                                        isVideoPlaying={isVideoPlaying}
                                                        setIsVideoPlaying={setIsVideoPlaying}
                                                        handleOnPlay={handleOnPlay}
                                                        handleOnPause={handleOnPause}
                                                        controls={true}
                                                        mediaUrl={data.YOUTUBE && data.YOUTUBE.content}
                                                        customPlayButton={false}
                                                        fullScreenBtn={false}

                                                    />
                                                </div>
                                            )
                                        ) : (
                                            <CustomReactPlayerHoc sVideoPlaying={isVideoPlaying}
                                                setIsVideoPlaying={setIsVideoPlaying}
                                                handleOnPlay={handleOnPlay}
                                                handleOnPause={handleOnPause}
                                                mediaUrl={(data.VIDEO && data.VIDEO.content) || (data.YOUTUBE && data.YOUTUBE.content)}
                                                customPlayButton={data.YOUTUBE ? false : true}
                                            />
                                        )
                                    }
                                </div>
                                <div className={classes.videoBodyRoot}>
                                    <div
                                        className={`${classes.videoTitle} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                                        // style={{ color: isMobile ? palette.black : palette.white }}
                                        style={{ color: isMobile ? isEmpty(bgImgURL) ? data?.TITLE?.color : palette.white : palette.white }} // in mobile if bg Image is exists then title color will be white
                                    >
                                        {/* {data.TITLE.content} */}
                                        <div dangerouslySetInnerHTML={{ __html: data?.TITLE?.content }}></div>
                                    </div>
                                    <div
                                        className={`${classes.videoBody} ${classes.hidden} ${cardType}_CARD_ITEMS  ql-editor`} // ql-editor is used for Quill Editor
                                        style={{ color: isMobile ? isEmpty(bgImgURL) ? data?.BODY?.color : palette.white : palette.white }} // in mobile if bg Image is exists then body color will be white
                                    // style={{ color: isMobile ? palette.black : palette.white }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: data?.BODY?.content }}></div>
                                        {/* {data.BODY.content} */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                )
            }
            {/* Body Title Video */}

            {
                isFileViewerOpen && (
                    <DocumentModal />
                )
            }
        </div >

    )
}

export default DynamicLaCard;