import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import constants from "../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    spotlightResultRoot: {
        // overflow: 'hidden',
        background: '#000',
        fontSize: '1em',
        width: '100%',
        height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        overflowY: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
        }
    },
    SpotlightResultBox: {
        aspectRatio: '9/16',
        borderRadius: '8px',
        margin: 'auto',
        padding: '1.2em 0.8em 1.2em 1em',
        backgroundColor: palette.spotlightGreyBg,
        overflow: 'auto',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            aspectRatio: 'auto',
            borderRadius: '0'
        }

    },
    spotlightResult: {
        height: '100%',
        userSelect: 'none',
        position: 'relative',
    },
    SpotlightResultContainer: {
        height: 'inherit',
        position: 'relative'
    },
    videoBox: {
        // height: 'max-content',
        // maxHeight: '40%',
        // height: '40% !important',
        maxHeight: 'fit-content',
        width: 'max-content',
        margin: 'auto',
        maxWidth: '100%',
        '& video': {
            borderRadius: '8px',
        }
    },
    cardContainer: {
        display: 'grid',
        gap: '1em',
        padding: '1.5em 0'
    },
    card: {
        backgroundColor: '#FFF',
        borderRadius: '6px',
        color: palette.greyText1,
        padding: '1em',
        boxShadow: '0px 0px 20px -11px rgb(0 0 0 / 75%)',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
    },
    cardHeader: {
        // display: 'grid',
        // gridTemplateColumns: 'repeat(3, 1fr)',
        // alignItems: 'flex-end',
        // alignItems: 'flex-start',

        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '0.5em',
        // marginBottom: '1.5em',
    },
    cardHeaderInner: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem'
    },
    cardName: {
        textTransform: 'uppercase',
        fontSize: '1.2em',
        fontWeight: 700,
        color: palette.greyText2,
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5em',
        }
        // textAlign: 'right',
        // width: 'min-content',
    },
    valueText: {
        fontWeight: 900,
        color: palette.orange,
        fontSize: '1.2em',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5em',
        }
    },
    valueSubText: {
        color: palette.orange,
        fontSize: '0.8em',
        textTransform: 'uppercase',
        fontWeight: 700,
        maxWidth: '5em'
    },
    valueBox: {
        display: 'flex',
        gap: '0.2em',
        alignItems: 'flex-start'
    },
    timeText: {
        fontWeight: 700,
    },
    valueString: {
        fontSize: '1.8em',
        lineHeight: '1'
    },
    cardDetailBox: {
        marginBottom: '1em'
        // margin: '1em 0 0.5em 0'
    },
    labelDetailBox: {
        display: 'grid',
        gap: '0.2em',
        // gridTemplateColumns: '50% 50%',
    },
    labelBox: {
        justifyContent: 'flex-start',
        gap: '0.6em'
    },
    labelName: {
        // color: palette.greyText1,
        fontSize: '0.8em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    labelColor: {
        width: '1em',
        height: '1em',
        borderRadius: '4px'
    },
    transcriptCircle: {
        borderRadius: '50%'
    },
    keywordTabContainer: {
        // display: 'grid',
        // gridTemplateColumns: '33.33% 33.33% 33.33%',
        overflow: 'hidden',
        transition: 'height 0.2s ease',
        '& > *': {
            padding: '0.2em',
            display: 'inline-block'
        }
    },
    keywordTab: {
        borderRadius: '2px',
        backgroundColor: 'green',
        width: '100%',
        height: '100%',
        color: '#FFF',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.6em',
        textAlign: 'center',
        fontSize: '1em',
        // wordBreak: 'break-all',
        [theme.breakpoints.up("sm")]: {
            // desktop
            fontSize: '0.7em',
        },
    },
    showMore: {
        fontWeight: 'bold',
        fontSize: '0.85em',
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '1em'
    },
    scoreBox: {
        display: 'grid',
        justifyItems: 'center',
        gap: '1em',
        paddingBottom: '4em',
        '& .dv-star-rating': {
            '& label': {
                padding: '0 0.2em'
            }
        }
    },
    scoreText: {
        color: palette.greyText2,
        fontSize: '0.9em',
        fontWeight: 700,
        letterSpacing: '0.3em'
    },
    formattedHtml: {
        fontSize: '1.2em',
        lineHeight: '1.5',
        padding: '0 0.5em'
    },



    // recommended videos kundan
    videoCardDetailBox: {
        margin: '1em 0 0.5em 0',
        display: 'flex',
        gap: '1rem'
    },
    cardHeaderVideos: {
        fontSize: '1.3em',
        fontWeight: 900
    },
    recommendedVideoBox: {
        overflow: 'hidden',
        width: '60%',
        position: 'relative',
        flex: '0 0 auto',
        cursor: 'pointer'
    },
    videoTag: {
        borderRadius: '0.6rem',
        width: '100%'
    },
    videoOverlay: {
        position: 'absolute',
        inset: '0 0 0 0',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
        borderRadius: '0 0 0.6rem 0.6rem'
    },
    videoOverlayText: {
        position: 'absolute',
        bottom: '0',
        padding: '0.5rem'
    },
    learnerName: {
        // fontSize: '1rem',
        color: '#fff',
        fontWeight: '700'
    },


    durationBox: {
        height: '2em',
        marginBottom: '1em'
    },
    maxDuration: {
        height: 'inherit',
        backgroundColor: '#D0B3D6',
        borderRadius: '1em',
        position: 'relative'
    },
    recommendedDuration: {
        width: '65%',
        height: 'inherit',
        borderRadius: '1em',
        backgroundColor: palette.voilet3,
        // fontSize: '0.5em',
        // color: '#FFF',
        // fontWeight: 500,
    },
    actualDuration: {
        height: '150%',
        width: '0.6em',
        borderRadius: '1em',
        backgroundColor: '#fff',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.5)',
        // transitionDuration: '1s',
        // transitionDelay: '1s'
    },
    yourDurationText: {
        position: 'absolute',
        fontSize: '0.65em',
        fontWeight: 700,
        top: 'calc(100% + 0.2em)',
        whiteSpace: 'nowrap',
        left: '50%',
        transform: 'translateX(-50%)',

    },
    recommendedDurationText: {
        height: 'inherit',
        position: 'absolute',
        fontSize: '1em',
        color: '#FFFFFF7A',
        fontWeight: 500,
        top: '0',
        padding: '0 0.8em'
    },
    // duration style

    graphTextOverlay: {
        width: '35%',
        height: '1rem',
        background: '#FFF',
        position: 'absolute',
        bottom: '0',
        right: '0',
        zIndex: '99'
    },

    backBtnBox: {
        position: 'absolute',
        left: '2rem',
        top: '2rem',
        height: '2rem',
        border: '2px solid #FFF',
        aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    recommendedVideoScore: {
        color: '#fff',
        fontSize: '0.8em',
        // fontWeight: '700'
    },
    modalDiv: {
        aspectRatio: (isMobile) => {
            return isMobile ? 'auto' : '9/16'
        },
        margin: 'auto',
        position: 'fixed',
        zIndex: 10,
        inset: '0 0 0 0',
        background: '#00000096',
        backdropFilter: 'blur(6px)',
        padding: '2rem',
        top: '4rem',
        [theme.breakpoints.up('sm')]: {
            top: 0
        }
    },

    // additional css classes for transcript match box
    transcriptMatchCard: {
        padding: '0em',
    },
    transcriptMatchCardHeader: {
        padding: '1em'
    },
    transcriptLableDetailBox: {
        padding: '0 1em',
    },
    transcriptCardDetailBoxWrapper: {
        paddingBottom: '1em',
    },
    transcriptCardDetailBox: {
        padding: '1em',
        maxHeight: '20em',
        // overflowY: 'auto'
        overflow: 'hidden',
        margin: 0
    },
    lotieCard: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '20px 20px 49px #00000029',
        borderRadius: '12px',
        padding: '2rem',
        margin: '0',
        position: 'relative'
    },
    // lottie title
    cardTitle: {
        position: 'absolute',
        top: '2.5rem',
        left: '3rem',
        background: 'white',
        color: '#4e4e4e',
        fontSize: '0.95em',
        zIndex: 2,
        filter: 'blur(0.5px)'
    },

    scoreCard: {
        minHeight: '5em',
        padding: '1em',
        margin: '1em 0',

    },
    RolePlayTextWrapper: {
        paddingBottom: '1em',
    },
    RolePlayText: {
        color: theme.palette.orange,
        fontWeight: 900,
        fontSize: '2em',

    },

    // graph design css starts
    graphContainer: {
        width: '100%',
        height: '1.2em',
        position: 'relative',
    },
    graphBarContainer: {
        width: '100%',
        height: '100%',
        borderRadius: '2em',
        display: 'grid',
        overflow: 'hidden',
    },
    graphBar: {
        height: '100%',
        width: '100%',
    },

    scoreLablesContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.4em'
    },
    scoreLabel: {
        fontSize: '1em',
        color: palette.greyText2,
        fontWeight: 700,
    },
    scorePointer: {
        height: '150%',
        width: '0.6em',
        borderRadius: '1em',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.5)',
    },
    scorePointerValue: {
        fontSize: '0.8em',
        color: palette.greyText2,
        fontWeight: 700,
        position: 'absolute',
        top: 'calc(100% + 0.2em)',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },

    sectoinHeading: {
        fontSize: '1em',
        fontWeight: 700,
        color: theme.palette.greyText2,
        padding: '3em 0em 1em',
        textAlign: 'center'
    },
    dictaionValuesContainer: {
        margin: '1em 0 0 0',
        width: '100%',
        overflow: 'hidden',
        transition: 'height 0.2s ease',
        // display: '-webkit-box', // /* Use flexbox to simulate two lines */
        // "-webkit-line-clamp": 2, // /* Limit to 2 lines */
        // "-webkit-box-orient": "vertical", // /* Set orientation to vertical */
        // overflow: "hidden", // /* Hide overflow text */
        // textOverflow: 'none', // /* Add ellipsis for truncated text */
        // whiteSpace: 'normal', // /* Allow text to wrap */
    },
    dictationValue: {
        color: theme.palette.orange,
        fontWeight: 700,
        fontSize: '1.5em',
        marginRight: '0.25em',
        textDecoration: 'underline',
        display: 'inline-block'
    },

    // videoConterolsBox
    videoConterolsBox: {
        width: '100%',
        height: '2em',
        margin: '1em 0 0',
        display: 'grid',
        gridTemplateColumns: '10% 90%',
    },
    videoDurationBarWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    videoDurationBar: {
        width: '100%',
        height: '5px',
        borderRadius: '1em',
        backgroundColor: palette.greyText2,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    videoDurationPointer: {
        height: '1em',
        aspectRatio: '1/1',
        borderRadius: '50%',
        backgroundColor: palette.orange,
        position: 'absolute',
        top: '50%',
        left: '0',
        transform: 'translateY(-50%)',
    },

    cardLeftSection: {
        padding: '0rem 0.5rem 0.3rem 0rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    customCard: {
        backgroundColor: 'white',
        display: 'grid',
        gridTemplateColumns: '70% 30%',
        alignItems: 'center',
        padding: '1em 1em 2em 0',
        borderRadius: '6px'
        // placeItems: 'center'
    },
    cardGridSplit: {
        display: 'grid',
        gridTemplateColumns: '65% 35%',
        alignItems: 'flex-start',
    },

    // score card 
    scoreCardRoot: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.5em 0',
        borderRadius: '4px',
        color: theme.palette.white,
        overflow: 'hidden'
    },
    scoreCardHeading: {
        fontSize: '1em',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    scoreCardScoreText: {
        fontSize: '2.2em',
        textAlign: 'center'
    },
    expandblBtnCont: {
        paddingTop: '0.5em',
        backgroundColor: theme.palette.white
    }

}))

export default useStyles