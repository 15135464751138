import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material ui :
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import Slide from '@mui/material/Slide';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Button } from '@mui/material';

// components
import SideDrawer from '../side-drawer';
import ShareDialogBox from '../../share-dialog-Box';

// redux
import {
    openToast,
    setHeaderSearchText,
    setMicroskillCategories,
    setSelectedLanguage,
} from '../../../redux/common/common-slice';
import { getAllModulesAsync } from '../../../redux/microskill/microskill.api';
import { setIsDarkMode } from '../../../redux/common-persist/common-persist-slice';

// utils
import commonUtil, { getHeaderHeight } from '../../../utils/commonUtil';
import { isNativeApp } from '../../../utils/commonUtil';
import isEmpty from '../../../utils/isEmpty';

// theme
import palette from '../../../theme/palette';

// constants
import constants from '../../../constants';

// Conext
import CommonContext from '../../../context/CommonContext';

// i18-next
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './index.styles';

import { useContext } from 'react';
import { KeyboardBackspace } from '@mui/icons-material';

// lottie
import LanguagesListUi from '../languages-list-ui';
import StatusSearch from '../search/status-search';
import CategorySearch from '../search/category-search';

const drawerWidth = 240;

const SearchBoxWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '5% 90% 5%',
    [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(1),
        width: '80%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 2),
}));

const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '600',
    fontSize: '1.2rem',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}));

const LightDarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transform: 'translateX(5px) scale(0.8)',
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {

                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#F4511E',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const displayDrawerStyleMobile = {
    sm: 'block',
    md: 'none'
};

const displayDrawerStyleDesktop = {
    xs: 'none',
    sm: 'none',
    md: 'block'
};

function removeDuplicateEntry(arr, field) {
    // array of data, field on which you need to filter
    let filteredArr = [...arr]?.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t[field] === value[field]
        )) && !isEmpty(value)
    );
    return filteredArr;
}

const Header = (props) => {

    const { showSearchHeader } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const url = window.location.href;
    const userDetail = useSelector(state => state?.auth?.user);
    const { isMobile,
        isTablet,
        isDocViewing,
        selectedLanguage,
        microkillCategories,
        selectedMicrokillCategories } = useSelector(state => state?.common);
    const { viewChristmasGiftAnimation, isDarkMode } = useSelector(state => state?.commonPersist);

    // states: 
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [isTranslateVisible, setIsTranslateVisible] = useState(false);
    const [moduleDataList, setModuleDataList] = useState([]);
    const [pastSearches, setPastSearches] = useState([]);
    const [recentlyLaunched, setRecentlyLaunched] = useState([]);
    const [recentlyLearned, setRecentlyLearned] = useState([]);
    const [microskillDataList, setMicroskillDataList] = useState([]);
    const [learningJourneyDataList, setLearningJourneyDataList] = useState([]);
    const [formsDataList, setFormsDataList] = useState([]);
    const [isCategorySearchVisible, setIsCategorySearchVisible] = useState(false);
    const [microkillCategoriesList, setMicroskillCategoriesList] = useState([])
    const [toggleCategories, setToggleCategories] = useState(null)
    const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
    const [isStatusSearchLoading, setIsStatusSearchLoading] = useState(true);
    const [isCategorySearchLoading, setIsCategorySearchLoading] = useState(true);

    // refs : 
    const containerRef = useRef(null);
    const lottieRef = useRef(null);
    const debouncingTimerRef = useRef(null);
    const languageContainerRef = useRef(null);
    const filterBtnContainerRef = useRef();

    const classes = useStyles({ isDarkMode });
    const logos = userDetail?.companyTheme?.logo;
    const slideDirection = isTranslateVisible ? 'right' : 'left';


    let texts = [
        'Search for “Microskills”',
        'Search for “Learning Journeys”',
        'Search for “Forms”',
    ];

    const {
        mobileOpen, setMobileOpen,
        LanguageChanging, setLanguageChanging,
        anchorElUser, setAnchorElUser,
        anchorLangElUser, setAnchorLangElUser
    } = useContext(CommonContext);

    const { t, i18n } = useTranslation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [showShareDialogBox, setShowShareDialogBox] = useState(false);

    const handleCloseShareDialogBox = () => {
        setShowShareDialogBox(false);
    }

    useEffect(() => {
        if (isMobile || isTablet) return;

        if (!isEmpty(selectedLanguage)) {
            handleModuleDataAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isTablet, selectedLanguage])

    useEffect(() => {
        if (!isEmpty(microkillCategories)) {
            const seqMicrokillCategories = [...microkillCategories]?.sort((a, b) => (a.sequence - b.sequence))
            setMicroskillCategoriesList(seqMicrokillCategories)
        }
    }, [microkillCategories, selectedMicrokillCategories])

    useEffect(() => {
        if (!url.includes("/learn")) {
            setOpenCategoryFilter(false)
        }
    }, [url])

    // this useEffect will decide, to open show more button at filters or not
    useEffect(() => {
        if (!isEmpty(filterBtnContainerRef.current)) {
            let childrenWidth = 0
            let children = filterBtnContainerRef.current.childNodes
            children.forEach(child => {
                let childWidth = child.getBoundingClientRect().width
                childrenWidth += childWidth
            })
            let containerWidth = filterBtnContainerRef.current.getBoundingClientRect().width;
            if (childrenWidth > containerWidth) {
                filterBtnContainerRef.current.style.maxHeight = "3rem";
                setToggleCategories(false)
            } else {
                setToggleCategories(null)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchVisible, filterBtnContainerRef.current, microkillCategoriesList, openCategoryFilter])

    useEffect(() => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    const handleModuleDataAsync = async () => {
        let queryParam = `?startIndex=0&limit=200&sort=createdOn&sortDirection=desc`;
        queryParam += selectedLanguage !== "Default" ? `&languages=${selectedLanguage}` : ''
        const moduleData = await getAllModulesAsync(queryParam);
        if (moduleData.status === 200 || moduleData.status === 201) {

            // setModuleDataList(moduleData);
            let microskillCategories = moduleData.data.data?.microskills?.map(e => {
                if (!isEmpty(e) && !isEmpty(e.Microskill.Category)) return e.Microskill.Category
                else return {};
            }) ?? []

            dispatch(setMicroskillCategories(removeDuplicateEntry(microskillCategories, 'id')))
        } else if (moduleData.status === 500) {
            dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }))
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setLanguageChanging(false)
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleMenuClick = async (data) => {
        setAnchorElUser(null);
        data.func();
        handleCloseUserMenu();
    };
    const handleSearchBtnClick = () => {
        setIsSearchVisible(true)
    };

    const handleCloseSearchClick = () => {
        dispatch(setHeaderSearchText(''));
        setSearchInputValue('');
        setIsSearchVisible(false);
        setToggleCategories(false);
    };

    const CloseNbackIconComp = () => {
        let styling = { color: palette.orange, fontSize: 30, fontWeight: '600' }
        return (
            <ListItem
                className={classes.listBox}

                style={{
                    paddingLeft: 0,
                    paddingTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null,
                    display: LanguageChanging ? "" : 'flex',
                    justifyContent: LanguageChanging ? "" : "space-between",
                    filter: viewChristmasGiftAnimation ? 'blur(5px)' : ""
                }}
            >
                <Button
                    variant='text'
                    onClick={handleDrawerToggle}
                >
                    {
                        LanguageChanging ? <KeyboardBackspace sx={styling} /> : <CloseIcon sx={styling} />
                    }
                </Button>
            </ListItem>
        )
    }

    const handleTranslateBtnClick = (event) => {
        if (isTranslateVisible) {
            setLanguageChanging(false);
            setIsTranslateVisible(false);
            setAnchorLangElUser(null);
            return;
        }

        setIsTranslateVisible(true);
        setAnchorLangElUser(event.currentTarget);
    };

    const handleTranslateBackBtnClick = () => {

        // const slide = document.getElementById('slide');

        // slide.direction = 'right';
        setIsTranslateVisible(false);
        setAnchorLangElUser(null);
    };

    const handleSearchedModuleDataAsync = async (search) => {

        setIsStatusSearchLoading(true);
        setIsCategorySearchLoading(true);

        let queryParam = `?startIndex=0&limit=200&sort=createdOn&sortDirection=desc`;
        queryParam += selectedLanguage !== "Default" ? `&languages=${selectedLanguage}` : '';
        queryParam += !isEmpty(selectedMicrokillCategories) ? `&categoryIds=${selectedMicrokillCategories.map(elem => elem.id).join(",")}` : '';
        queryParam += !isEmpty(search) ? `&search=${search}` : '';

        try {
            setIsStatusSearchLoading(true);
            setIsCategorySearchLoading(true);

            const moduleData = await getAllModulesAsync(queryParam);

            if (moduleData.status === 200 || moduleData.status === 201) {

                setIsStatusSearchLoading(true);
                setIsCategorySearchLoading(true);

                setModuleDataList(moduleData);
                setMicroskillDataList(moduleData?.data?.data?.microskills);
                setLearningJourneyDataList(moduleData?.data?.data?.learnerJourney);
                setFormsDataList(moduleData?.data?.data?.forms);
                setPastSearches(moduleData?.data?.data?.microskills);
                setRecentlyLaunched(moduleData?.data?.data?.learnerJourney);
                setRecentlyLearned(moduleData?.data?.data?.recentlyLearned);

                setIsStatusSearchLoading(false);
                setIsCategorySearchLoading(false);
            } else if (moduleData.status === 500) {
                dispatch(openToast({ isToastOpen: true, toastMessage: 'Internal server error', toastSeverity: 'error' }));
                setIsStatusSearchLoading(false);
                setIsCategorySearchLoading(false);
            }
        } catch (error) {
            console.error('Error fetching search module data:', error);
            setIsStatusSearchLoading(false);
            setIsCategorySearchLoading(false);
        }
    };

    const handleSearchBarText = (e) => {

        setSearchInputValue(e?.target?.value);
        clearTimeout(debouncingTimerRef.current);

        debouncingTimerRef.current = setTimeout(() => {

            handleSearchedModuleDataAsync(e?.target?.value);
            dispatch(setHeaderSearchText(e.target.value));
        }, 500);
    }

    const handleSearchBarClick = () => {
        setIsCategorySearchVisible(true);
    }

    useEffect(() => {
        if (!openCategoryFilter) return;
        handleSearchedModuleDataAsync('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCategoryFilter]);

    const searchPlaceholderTextArray = ["Search Microskills", "Search Learning Journeys", "Search Forms"];
    let k = 0;

    // Flag to prevent multiple updates at once
    let isUpdating = false;


    const changeSearchPlaceholderValue = () => {
        const inputElement = document.getElementById('search');

        if (inputElement && !isUpdating) {
            isUpdating = true;

            // Check bounds for the array
            if (k < searchPlaceholderTextArray.length) {
                const newPlaceholder = searchPlaceholderTextArray[k];
                const currentWordArray = newPlaceholder.split("");

                // Clear the placeholder first
                inputElement.placeholder = "";

                // Update the placeholder one character at a time
                const updatePlaceholder = (index) => {
                    if (index < currentWordArray.length) {
                        setTimeout(() => {
                            inputElement.placeholder = currentWordArray.slice(0, index + 1).join('');
                            updatePlaceholder(index + 1); // Call for the next character
                        }, 100); // Adjust this delay to make it slower
                    } else {
                        // Once the word is complete, wait for 1 second : 
                        setTimeout(() => {
                            k++; // Now we can increment t to move to the next word
                            isUpdating = false; // Reset the flag
                            changeSearchPlaceholderValue(); // Start the next placeholder
                        }, 1000); // Wait before starting the next placeholder
                    }
                };

                updatePlaceholder(0); // Start updating from the first character
            } else {
                // Reset t to loop through the placeholders again
                k = 0;
                isUpdating = false; // Reset the flag
                changeSearchPlaceholderValue(); // Start over with the first placeholder
            }
        }
    };


    useEffect(() => {
        changeSearchPlaceholderValue(); // Start the placeholder changing on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleIconClick = () => {
        setOpenCategoryFilter((prev) => !prev);
        document.querySelector('#searchBarInput').focus();
    }

    return (
        <>
            <Box className={classes.headerBox}
                style={{
                    height: getHeaderHeight(),
                    position: 'relative',
                }}
            >
                <AppBar component="nav" className={classes.appBarRoot}>

                    <Toolbar className={classes.toolbar} ref={containerRef}
                        sx={isMobile || isTablet ? {} : {
                            gridTemplateColumns: "15% 50% 15% 20% !important",
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            {(url.includes('gba') || url.includes('scorm') || url.includes("forms")) && (<img src={`/images/icons/mastero_full_logo${isDarkMode ? "" : "_dark"}.svg`} style={{ width: '40%', padding: 'auto' }} alt="Master-O Logo" />)}

                            {(isMobile || isTablet) && (
                                <Box sx={{ mr: 3, display: displayDrawerStyleMobile }}
                                    className={`disableSelection`}
                                >
                                    {
                                        <img src="/images/icons/hamburger.png"
                                            alt="master-o loading icon"
                                            width={25}
                                            height={25}
                                            onClick={handleDrawerToggle}
                                            className={`disableSelection`}
                                        />
                                    }
                                </Box>
                            )}
                        </div>

                        {
                            (isMobile || isTablet) && (
                                <div className={`${classes.companyLogoBox} ${classes.centerFlex}`}>
                                    <>
                                        {isDarkMode && !isEmpty(logos?.darkHeaderLogo) && (
                                            <img src={logos.darkHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                        {!isDarkMode && !isEmpty(logos?.lightHeaderLogo) && (
                                            <img src={logos.lightHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                    </>
                                </div>
                            )
                        }

                        {
                            !(isMobile || isTablet) ? (
                                <SearchBoxWrapper >
                                    <Search
                                        className={`search ${classes.search}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            visibility: showSearchHeader && !url.includes('/challenge-list') && !url.includes('/performance') && !url.includes('/privacy-policy') && !isDocViewing ? 'visible' : 'hidden',
                                            gridTemplateColumns: openCategoryFilter ? "92% 8%" : "92% 8%",
                                            borderRadius: openCategoryFilter ? `4px 4px 0 0` : "4px",
                                        }}>

                                        <div className={`${classes.searchInputBox} ${classes.centerFlex}`}>
                                            <StyledInputBase
                                                id='searchBarInput'
                                                placeholder={t('')}
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={(e) => { handleSearchBarText(e) }}
                                                value={searchInputValue}
                                            />

                                            {(!openCategoryFilter && window.location.pathname === "/learn") && <label htmlFor="searchBarInput" onClick={() => { handleIconClick() }}>
                                                <div className={classes.rolllerBox}>
                                                    {texts.map((item, index) => (
                                                        <div key={index} className={classes.rollerItem} style={{ animationDelay: `${(10 / 3) * index}s` }}>
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            </label>}
                                        </div>

                                        {!openCategoryFilter ? <label htmlFor="searchBarInput" onClick={() => { handleIconClick() }}>
                                            <SearchIconWrapper className={classes.centerFlex} style={{ cursor: 'pointer' }}>
                                                <SearchIcon color={isDarkMode ? palette.white : palette.orange} style={{ height: '50px', width: '25px' }} />
                                            </SearchIconWrapper>
                                        </label>
                                            :
                                            <div className={`${classes.closeIconWrapper} ${classes.centerFlex}`}  >
                                                {
                                                    openCategoryFilter && <CloseIconWrapper className={classes.centerFlex} style={{ cursor: 'pointer' }}>
                                                        <CloseIcon onClick={() => {
                                                            setOpenCategoryFilter(false)
                                                            setSearchInputValue("")
                                                            dispatch(setHeaderSearchText(""));
                                                        }} />
                                                    </CloseIconWrapper>
                                                }

                                            </div>}


                                    </Search>
                                    {
                                        !(isMobile || isTablet) && openCategoryFilter && url.includes("/learn") &&
                                        <CategoryFilterComp
                                            openCategoryFilter={openCategoryFilter}
                                            containerRef={containerRef}
                                            toggleCategories={toggleCategories}
                                            setToggleCategories={setToggleCategories}
                                            filterBtnContainerRef={filterBtnContainerRef}
                                            microkillCategoriesList={microkillCategoriesList}
                                            selectedMicrokillCategories={selectedMicrokillCategories}
                                            dispatch={dispatch}
                                            pastSearches={moduleDataList?.data?.data?.pastSearches}
                                            recentlyLaunched={moduleDataList?.data?.data?.recentlyLaunched}
                                            recentlyLearned={moduleDataList?.data?.data?.recentlyLearned}
                                            moduleDataList={moduleDataList}
                                            handleSearchBarClick={handleSearchBarClick}
                                            microskillDataList={microskillDataList}
                                            learningJourneyDataList={learningJourneyDataList}
                                            formsDataList={formsDataList}
                                            searchInputValue={searchInputValue}
                                            setOpenCategoryFilter={setOpenCategoryFilter}
                                            isStatusSearchLoading={isStatusSearchLoading}
                                            isCategorySearchLoading={isCategorySearchLoading}
                                        />
                                    }
                                </SearchBoxWrapper>
                            ) : (
                                <>
                                    {
                                        showSearchHeader && (
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}> <img src="/images/icons/translate_language.svg"
                                                alt="search"
                                                width={30}
                                                height={30}
                                                onClick={(e) => { handleTranslateBtnClick(e) }}
                                                className={`disableSelection`}
                                                />

                                            </Box>
                                        )
                                    }

                                    <Drawer
                                        className={classes.mobileDrawer}
                                        variant="temporary"
                                        anchor={"right"}
                                        open={isTranslateVisible}
                                        onClose={handleTranslateBackBtnClick}
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}
                                        sx={theme => ({
                                            display: displayDrawerStyleMobile,
                                            '& .MuiDrawer-paper': {
                                                backgroundColor: !isMobile ? `${theme.palette.headerBg} !important` : "",
                                                boxSizing: 'border-box',
                                                width: drawerWidth
                                            },
                                        })}
                                    >

                                        <Box component="nav"
                                            ref={languageContainerRef}
                                            className={classes.languageContainer}
                                        >
                                            <LanguagesListUi
                                                handleTranslateBackBtnClick={handleTranslateBackBtnClick}
                                                setSelectedLanguage={setSelectedLanguage}
                                                setLanguageChanging={setLanguageChanging}
                                                selectedLanguage={selectedLanguage}
                                            />
                                        </Box>
                                    </Drawer>
                                </>
                            )
                        }


                        {
                            !(isMobile || isTablet) && (
                                <Box sx={{
                                    display: { xs: 'none', sm: 'none', md: 'flex', flexGrow: 0 },
                                    width: !isEmpty(logos?.darkHeaderLogo) || !isEmpty(logos?.lightHeaderLogo) ? '100% !important' : '70%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                }}
                                    className={classes.accountDetailBox}
                                >
                                    <>
                                        {isDarkMode && !isEmpty(logos?.darkHeaderLogo) && (
                                            <img src={logos.darkHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                        {!isDarkMode && !isEmpty(logos?.lightHeaderLogo) && (
                                            <img src={logos.lightHeaderLogo}
                                                alt="logo"
                                                className={classes.companyLogo}
                                            />
                                        )}
                                    </>
                                </Box>
                            )
                        }


                        {
                            !(isMobile || isTablet) && (
                                <Box sx={{
                                    display: { xs: 'none', sm: 'none', md: 'flex', flexGrow: 0 },
                                    width: !isEmpty(logos?.darkHeaderLogo) || !isEmpty(logos?.lightHeaderLogo) ? '100% !important' : '70%',
                                    height: '100%',
                                    overflow: 'hidden',
                                }}
                                    className={classes.accountDetailBox}
                                >
                                    <div >

                                        {
                                            showSearchHeader && (
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}> <img src="/images/icons/translate_language.svg"
                                                    alt="search"
                                                    width={30}
                                                    height={30}
                                                    onClick={(e) => { handleTranslateBtnClick(e) }}
                                                    className={`disableSelection`}
                                                    />
                                                </Box>
                                            )
                                        }

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            className={classes.languageMenuDropdown}
                                            id="menu-appbar-1"
                                            anchorEl={anchorLangElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorLangElUser)}
                                            onClose={handleTranslateBackBtnClick}
                                        >
                                            <LanguagesListUi
                                                handleTranslateBackBtnClick={handleTranslateBackBtnClick}
                                                setSelectedLanguage={setSelectedLanguage}
                                                setLanguageChanging={setLanguageChanging}
                                                selectedLanguage={selectedLanguage}
                                            />
                                        </Menu>
                                    </div>
                                    <LightDarkModeSwitch
                                        checked={!isDarkMode}
                                        onChange={() => {
                                            dispatch(setIsDarkMode(!isDarkMode))
                                        }}
                                    />
                                    <div>
                                        <div className={classes.centerFlex} style={{ flexDirection: 'row', gap: '0.5rem' }}>

                                            <div
                                                className={`${classes.avatarBox} ${classes.centerFlex}`}
                                                onClick={handleOpenUserMenu} id="userAvatar">
                                                {
                                                    isEmpty(commonUtil.getUserName()) ? (
                                                        <PermIdentityIcon style={{ color: "#FFF" }} />
                                                    ) : (commonUtil.getUserName()?.charAt(0).toUpperCase())
                                                }
                                            </div>
                                        </div>

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            className={classes.menuDropdown}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {
                                                !LanguageChanging && (
                                                    constants.HEADER_DROPDOWN.map((item) => {
                                                        return (
                                                            item.key !== 'logout' && item.key !== 'settings' && (

                                                                <MenuItem key={item.id} onClick={() => {

                                                                    if (item.key === 'privacy_policy') {
                                                                        navigate({
                                                                            pathname: "/privacy-policy",
                                                                        });
                                                                        setAnchorElUser(null);
                                                                        handleCloseUserMenu();
                                                                        return;
                                                                    }
                                                                    if (item.key === 'share_mastero') {
                                                                        setShowShareDialogBox(true);
                                                                        handleCloseUserMenu()
                                                                        return;
                                                                    }
                                                                    handleMenuClick(item)
                                                                }}>
                                                                    <img src={item.icon_orange}
                                                                        alt="icon"
                                                                        className={classes.dropDownIcon}
                                                                        width={20} />

                                                                    <Typography
                                                                        textAlign="center"
                                                                        className={classes.menuText}>
                                                                        {`${t(item.name)}${item.key === "app_language" ? ":" : ''}`}
                                                                    </Typography>
                                                                    {
                                                                        item.key === "app_language" && <Typography textAlign="left" className={classes.menuText}>{selectedLanguage}</Typography>
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )
                                                    })
                                                )
                                            }
                                        </Menu>
                                    </div>
                                </Box>
                            )
                        }

                        {
                            (isMobile || isTablet) && isSearchVisible && url.includes("/learn") &&
                            <CategoryFilterComp
                                openCategoryFilter={isSearchVisible}
                                containerRef={containerRef}
                                toggleCategories={toggleCategories}
                                setToggleCategories={setToggleCategories}
                                filterBtnContainerRef={filterBtnContainerRef}
                                microkillCategoriesList={microkillCategoriesList}
                                selectedMicrokillCategories={selectedMicrokillCategories}
                                dispatch={dispatch}
                            />
                        }
                    </Toolbar>
                </AppBar>

                <Box component="nav">
                    <Drawer
                        className={classes.mobileDrawer}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={theme => ({
                            display: displayDrawerStyleMobile,
                            '& .MuiDrawer-paper': {
                                backgroundColor: !isMobile ? `${theme.palette.headerBg} !important` : "",
                                boxSizing: 'border-box',
                                width: drawerWidth
                            },
                        })}
                    >
                        <CloseNbackIconComp />
                        <SideDrawer
                            handleDrawerToggle={handleDrawerToggle}
                            setIsLanguageChanging={setLanguageChanging}
                            isLanguageChanging={LanguageChanging}
                            mobileOpen={mobileOpen} />
                    </Drawer>
                </Box>
            </Box>
            {showShareDialogBox && (
                <ShareDialogBox
                    isOpen={showShareDialogBox}
                    handleClose={handleCloseShareDialogBox}
                />
            )
            }
        </>
    );
}

export default Header;

const CategoryFilterComp = (props) => {

    const { openCategoryFilter,
        containerRef,
        filterBtnContainerRef,
        pastSearches,
        recentlyLaunched,
        recentlyLearned,
        moduleDataList,
        handleSearchBarClick,
        microskillDataList,
        learningJourneyDataList,
        formsDataList,
        searchInputValue,
        setOpenCategoryFilter,
        isStatusSearchLoading,
        isCategorySearchLoading,
    } = props
    const classes = useStyles();

    return (
        <Slide
            direction="down"
            in={openCategoryFilter}
            container={containerRef.current}
        >
            <div className={`filter-btn-container ${classes.filterContainer} `}>
                {
                    <div className={classes.filterBtnContainer}
                        ref={filterBtnContainerRef}
                    >
                        {!(searchInputValue?.trim() !== '') ?
                            (<StatusSearch
                                isStatusSearchLoading={isStatusSearchLoading}
                                pastSearches={pastSearches}
                                recentlyLaunched={recentlyLaunched}
                                recentlyLearned={recentlyLearned}
                                moduleDataList={moduleDataList}
                                handleSearchBarClick={handleSearchBarClick}
                                setOpenCategoryFilter={setOpenCategoryFilter}
                            />) : (
                                <CategorySearch
                                    isCategorySearchLoading={isCategorySearchLoading}
                                    microskillDataList={microskillDataList}
                                    learningJourneyDataList={learningJourneyDataList}
                                    formsDataList={formsDataList}
                                    handleSearchBarClick={handleSearchBarClick}
                                    searchInputValue={searchInputValue}
                                    setOpenCategoryFilter={setOpenCategoryFilter}
                                />
                            )
                        }
                    </div>
                }
            </div>
        </Slide>
    )
}