import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    root: {
        width: '100%',
        height: '100%'
    },

    loadingForm: {
        height: '100%',
        width: '100%'
    },

    paperProps: {

    },

    formContainer: {
        height: '100%',
        display: 'grid',
        [theme.breakpoints.down('md')]: {
            // gridTemplateRows: '1fr 7fr 1fr'
            gridTemplateRows: '0.5fr 7fr 1fr'
        },
    },


    formSubmitContainer: {
        padding: '0rem 1rem',
        // boxShadow: '0 0 10px grey'
        [theme.breakpoints.up('md')]: {
            padding: '0.5rem 1rem'
        }
    },

    formSubmitButton: {
        width: '100%',
        fontSize: '1.3rem !important',
        fontWeight: 'bold !important',
        color: 'white !important'
    },

    scrollingContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px',
            background: "#cdcdcd",
            borderRadius: '5px',

            [theme.breakpoints.down('sm')]: {
                width: '5px',
            }
            // display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '5px'
        }
    },

    // timer 
    iconContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        // padding: '1rem'
    },

    closeIcon: {
        height: '2rem',
        width: '2rem',
        float: 'right',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            height: '1.5rem',
            width: '1.5rem',
        }
    },

    alarmIcon: {
        color: '#F4511E',
        margin: 'auto'
    },

    timerContainer: {
        columnGap: '0.2rem'
    },

    timerDiv: {
        textAlign: 'left',
        width: '80%',
        minWidth: '3.5rem',
        color: "#F4511E"
    },

}))

export default useStyles