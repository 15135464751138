import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

export const SEQ_ILA_GRID_INITIAL_MOBILE = '7% 20% 65% 8%'
export const SEQ_ILA_GRID_INITIAL = '15% 15% 62% 8%'

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        overflow: 'hidden',
        // gridTemplateRows: constants.ILA_GRID_INITIAL,
        gridTemplateRows: SEQ_ILA_GRID_INITIAL,
        transition: '500ms',
        [theme.breakpoints.down("md")]: {
            // gridTemplateRows: '7% 15% 70% 8%',
            gridTemplateRows: SEQ_ILA_GRID_INITIAL_MOBILE,
            // gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
        }
    },
    questionBox: {
        display: 'grid',
        gridTemplateColumns: '20% 60%'
    },
    question: {
        fontSize: '1.7em',
        '@media (max-width: 1300px)': {
            fontSize: '1.5em',
            // height: "33px"
            // paddingLeft: (theme) => theme.spacing(2)
        },
    },
    optionsContainer: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateRows: '4fr 2fr',
        position: 'relative',
        height: '100%',
        width: '100%',
        padding: '0.5em 1em',
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: '0 auto',
        touchAction: 'manipulation',
        [theme.breakpoints.up('md')]: {
            width: '60%'
        }
    },
    optionContainerOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: '10',
        background: 'transparent'
    },
    optionsBox: {
        width: '100%',
        maxHeight: '100%',
        margin: 'auto',
        position: 'relative',
        display: 'grid',
        alignContent: 'center',
        alignItems: 'center',
        gap: '0.9rem',
        // [theme.breakpoints.down("md")]: {
        //     width: '90%',
        // }
    },
    option: {
        // height: '45px',
        // maxHeight: '45px',
        padding: '0.3rem 1rem',
        paddingRight: '0.5rem !important',
        paddingLeft: '0.5rem !important',
        '@media (max-width: 1300px)': {
            padding: '0rem 1rem',
        },
        borderRadius: '0.3rem',
        outline: 'none',
        border: '3px solid white',
        // cursor: 'grab',
        position: 'relative',
        transition: 'position 0.7s',
        '-webkit-tap-highlight-color': 'transparent',
        tapHighlightColor: 'transparent',
        [theme.breakpoints.down("sm")]: {
            padding: '0.3rem 1rem',
        }

    },
    optionBtn: {
        height: '100% !important',
        padding: '0 !important',
        color: '#FFF !important',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'grab'
    },
    indexBtn: {
        backgroundColor: 'transparent',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        height: '100%',
        aspectRatio: '1/1',
        overflow: 'hidden',
        cursor: 'grab',
    },
    answerBtn: {
        backgroundColor: palette.background,
        cursor: 'grab',
        fontSize: '0.9rem',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.1rem',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontFamily: 'open sans',

        // [theme.breakpoints.down("sm")]: {
        //     fontSize: '1rem',
        // }
    },

    // draggable item css
    draggbleItem: {
        userSelect: "none",
        color: 'white',
        backgroundColor: palette.ilaBg,
        transition: 'background 0.1s linear',
        '& > div': {
            transition: 'background 200ms linear',
        }
    },

    submitBtnContainer: {
        textAlign: 'center',
        paddingTop: '0.5rem'
    },
    submitBtn: {
        width: '30%',
        margin: '0 auto',
        padding: '0.5rem 1rem',
        fontWeight: 'bold !important',
        fontFamily: 'Montserrat !important',
        borderRadius: '3px !important',
        [theme.breakpoints.down("md")]: {
            width: '45%',
        }
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-2rem'
    },
    dragImg: {
        touchAction: 'manipulation'
    }
}))

export default useStyles