import React, { useEffect, useRef, useState } from "react";

// mui
import { Pause, PlayArrow } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
// Components

// utils

//styles
import useStyle from "./index.styles";

const TIMEOUT_TIME = 20;

const CustomisedVideoPlayer = (props) => {
    const {
        videoPath,
        rootHeight= "100%",
        controlsPosition = 'BOTTOM',
        styling = {},
        showControls = true,
        showSeeker = true,
        autoPlay=false,
        muted=false,
        timeStamp=0
    } = props
    const classes = useStyle();

    // redux states

    // states
    const [loadingVideo, setLoadingVideo] = useState(true);
    // const [loadingVideoProgress, setLoadingVideoProgress] = useState(75);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [timeoutExceeded, setTimeoutExceeded] = useState(false);

    // refs
    const videoTagRef = useRef();
    const timeoutRef = useRef();

    //constants
    const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(253 252 255)), to(rgb(248 248 248)))`;
    const duration = videoTagRef.current?.duration;

    // functions
    const positionStyling = () => {
        if (controlsPosition === "TOP") {
            return {
                top: '0',
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 1%, transparent)',
            }
        }
    }

    const startTimeout = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setTimeoutExceeded(true);
            setIsBuffering(false); // Hide the loader if timeout occurs
            console.log("Buffering timeout exceeded.");
        }, TIMEOUT_TIME * 1000); // 5 seconds timeout
    };

    const hanldePlaypause = () => {
        if(isBuffering) return
        // setIsPlaying(prev => !prev);
        if (videoTagRef.current) {
            if (videoTagRef.current.paused) {
                videoTagRef.current.play();
                setIsPlaying(true);
                return
            }
            videoTagRef.current.pause();
            setIsPlaying(false);
        }
    }

    const handleTimeUpdate = (e) => {
        const timeElapsed = e.target.currentTime;
        setTrackProgress(timeElapsed)
    }

    const handleVideoEnded = (e) => {
        setIsPlaying(false);
    }

    const handleMediaLoaded = (e) => {
        setLoadingVideo(false);
        e.target.currentTime = 1
        setTrackProgress(1);
        clearTimeout(timeoutRef.current)
    }

    const onScrub = (value) => {
        // Clear any timers already running
        console.log(videoTagRef.current.currentTime, value)
        videoTagRef.current.currentTime = value;
        setTrackProgress(value);
    };

    const handleSeeking = (type) => {
        setIsBuffering(true);
        setTimeoutExceeded(false); // Reset timeout status
        startTimeout();
        console.log(`${type}...`);
    };

    const handleSeeked = (type) => {
        setIsBuffering(false);
        clearTimeout(timeoutRef.current); // clears the timer
        console.log(`${type}!`);
    };

    const handleReloadVideo = () => {
        console.log("video load true")
        videoTagRef.current.load();
        setLoadingVideo(true);
    }

    const handleVideoLoadTimeout = () => {
        setLoadingVideo(false);
        setTimeoutExceeded(true);
    }

    // effect hooks
    useEffect(() => {
        timeoutRef.current = setTimeout(handleVideoLoadTimeout, TIMEOUT_TIME * 1000);

        return () => clearTimeout(timeoutRef.current)
    }, []);

    useEffect(()=> {
        onScrub(timeStamp);
    }, [timeStamp])

    return (
        <div className={classes.root} style={{height: rootHeight}}>
            <div className={classes.wrapper}>
                {
                    loadingVideo && <div className={`${classes.loadingWrapper} ${classes.centerFlex}`}>
                        <CircularProgress sx={{ color: '#000' }} />
                    </div>
                }
                {
                    timeoutExceeded && <div className={`${classes.timeoutWapper} ${classes.centerFlex}`} onClick={handleReloadVideo}>
                        <ReplayIcon sx={{ color: 'white', fontSize: '2.5rem' }} />
                        <p style={{ textAlign: 'center' }}>Please reload. <br />Video loading timed out.</p>
                    </div>

                }
                <video
                    preload="metadata"
                    width="100%"
                    controls={false}
                    autoPlay={autoPlay}
                    muted={muted}
                    playsInline
                    disablePictureInPicture={true}
                    controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                    style={{ maxHeight: "100%" }}
                    ref={videoTagRef}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleMediaLoaded}
                    onSeeking={() => handleSeeking("SEEKING")}
                    onWaiting={() => handleSeeking("WAITING")}
                    onCanPlay={() => handleSeeked("CAN_PLAY")}
                    onSeeked={() => handleSeeked("SEEKED")}
                    onEnded={handleVideoEnded}
                >
                    <source src={videoPath} type="video/mp4" />
                    <source src={videoPath} type="video/ogg" />
                    <source src={videoPath} type="video/webm" />
                    Your browser does not support the video tag.
                </video>
                {
                    showControls &&
                    <div className={classes.controlsWrapper}
                        style={{ ...positionStyling(), ...styling }}
                    >
                        <div className={`${classes.playWrapper} ${classes.centerFlex}`}
                            onClick={hanldePlaypause}
                        >
                            {
                                isBuffering ? (
                                    <CircularProgress sx={{ color: '#fff' }} size={20} />
                                ) : (
                                    isPlaying ? (
                                        <Pause sx={{ color: 'white' }} />
                                    ) : (
                                        <PlayArrow sx={{ color: 'white' }} />
                                    )
                                )
                            }
                        </div>
                        {
                            showSeeker && <div className={`${classes.seekingWrapper} ${classes.centerFlex}`}>
                                <div className={`${classes.trackSlider} ${classes.centerFlex}`}>
                                    <input
                                        type="range"
                                        value={trackProgress}
                                        step="1"
                                        min="0"
                                        max={duration ? duration : `${duration}`}
                                        className={classes.slider}
                                        onChange={(e) => onScrub(e.target.value)}
                                        style={{ background: trackStyling }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default CustomisedVideoPlayer;