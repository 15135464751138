import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux';

//mui
import {
    ToggleButton,
    Button,
    Stack,
    ToggleButtonGroup,
    Menu,
    List,
    ListItemButton,
    ListItemText,
    CircularProgress,
    Typography,
    Box,
    styled
} from "@mui/material";
import MuiCheckbox from "@mui/material/Checkbox";
import { Close } from '@mui/icons-material';

// redux
import { getAllModulesAsync } from '../../../../redux/microskill/microskill.api';

//styles
import useStyles from "./index.styles";

//utils
import theme from '../../../../theme';
import isEmpty from '../../../../utils/isEmpty';

export const TOGGLE_DATA = {
    MICROSKILLS: "microskills",
};


const MicroskillFilter = ({
    microskills,
    selectedMicroskills = [],
    setSelectedMicroskills,
    t,
    pagination,
    setLimit,
    bottomLoader,
    isDarkMode,
}) => {

    const styles = useStyles();

    // states
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState([]);
    const [list, setList] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    // refs
    const debounceSearch = useRef();
    const observer = useRef();
    const firstRender = useRef(true);
    const bottomLoaderRef = useRef(null);

    useEffect(() => {
        if (open) {
            setList(microskills);
            setSelected(selectedMicroskills);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setList(microskills);
    }, [microskills]);

    useEffect(() => {
        if (bottomLoader) {
            scrollToBottom()
        }
    }, [bottomLoader])

    const scrollToBottom = () => {
        if (bottomLoaderRef.current) {
            bottomLoaderRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        return
    }

    const lastElementRef = useCallback((node) => {
        if (bottomLoader) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                let newLimit = pagination.limit + 50;
                const temp = parseInt(pagination.count) - pagination.limit;
                if (newLimit > parseInt(pagination.count) && temp < newLimit) newLimit = pagination.limit + temp;
                if (!searchValue.length && newLimit <= parseInt(pagination.count)) setLimit(newLimit)
            }
        });
        if (node) observer.current.observe(node);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bottomLoader]);

    const handleAllModuleAsync = async (searchText) => {
        let queryParam = '?startIndex=0&limit=100&sort=createdOn&sortDirection=asc'
        queryParam += !isEmpty(searchText) ? `&search=${searchText}` : '';

        const resp = await getAllModulesAsync(queryParam);
        if (resp.status == 200 || resp.status == 201) {
            let microskillList = resp.data?.data?.microskills || [];
            microskillList = microskillList.map((microskill) => {
                return {
                    id: microskill?.Microskill.id,
                    name: microskill?.Microskill.name
                }
            })
            setList(microskillList);
        }
    }

    const handleMicroskillSearch = (e) => {

        e.preventDefault();
        clearTimeout(debounceSearch.current);
        let value = e.target.value;
        setSearchValue(value);
        let delay = 1000;
        if (!value.length) delay = 0;
        debounceSearch.current = setTimeout(() => {
            handleAllModuleAsync(value);
            clearTimeout(debounceSearch.current);
        }, delay)
    }


    const selectMicroskill = (item) => {

        const id = item.id;

        if (selected.includes(id)) {
            const filtered = selected.filter(ele => ele !== id);
            setSelected(filtered)
        } else {
            setSelected([...selected, id]);
        }
    }


    const handleClose = () => {
        setOpen(false);
        // Clearing the selected checkboxes : 
        setSelected([]);
        if (searchValue.length) setSearchValue("");
    }

    const handleSave = () => {

        setOpen(false);
        setSelectedMicroskills(selected);

        if (searchValue.length) setSearchValue("");
    }


    const selectAllMicroskils = () => {
        let isSelectAll = list.length === selected.length;

        if (isSelectAll) {
            setSelected([]);
            return
        }
        let all = microskills.map(ele => ele.id);
        setSelected(all);
    }

    const getBgColor = (theme) => {
        if (isDarkMode) return theme.palette.darkVoilet;
        return '#f6f6f6';
    }

    const getBtnText = () => {
        if (microskills.length && selectedMicroskills.length === microskills.length) return "All Microskills Selected";
        if (selectedMicroskills.length) return `${selectedMicroskills.length} Microskills Selected`;
        return "Microskill Filter"
    }

    const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
        "&.Mui-selected": {
            backgroundColor: '#F4511E',
            border: '2px solid #F4511E',
            borderRadius: '2rem',
            fontWeight: '700',
            width: '100%',
            color: '#fff',
        },
        "&.Mui-selected:hover": {
            backgroundColor: '#F4511E',
        }
    }
    ));


    const SearchToggle = () => {

        const { isDarkMode } = useSelector(state => state?.commonPersist);
        const classes = useStyles({ isDarkMode });

        const toggleBtnStyling = {
            fontWeight: '700',
            minWidth: '50%',
            border: 'none',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
        };

        return (
            <Stack
                value={"languageGroups"}
                direction="row"
                spacing={1}
                className={classes.toggleContainer}
                style={{ backgroundColor: isDarkMode ? theme.palette.voilet : '#fff' }}
            >
                <ToggleButtonGroup
                    value={TOGGLE_DATA.MICROSKILLS}
                    exclusive
                    onChange={(e) => { }}
                    aria-label="searched-data-list"
                    style={{ width: '100%', border: 'none' }}
                >
                    {/* <StyledToggleButton
                        value={TOGGLE_DATA.MICROSKILLS}
                        aria-label={TOGGLE_DATA.MICROSKILLS}
                        style={toggleBtnStyling}
                    >
                        Microskills ({list?.length})
                    </StyledToggleButton> */}

                </ToggleButtonGroup>
            </Stack>
        );
    };

    const _handleClearList = () => {
        setSelected([]);
    }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                size="small"
                sx={{
                    margin: '1rem',
                    minWidth: '10rem',
                    width: 'fit-content',
                    borderRadius: '2px',
                    boxShadow: 'none',
                    fontSize: '0.69rem',
                    fontWeight: 700,
                    padding: '0.4rem',
                    backgroundColor: (theme) => isDarkMode ? theme.palette.darkVoilet : theme.palette.darkVoilet,
                    color: (theme) => theme.palette.whiteToOrange,
                    border: '1px solid #fff',
                    borderColor: (theme) => theme.palette.whiteToOrange,
                    display: 'flex',
                    gap: '0.5rem',
                    '&:hover': {
                        backgroundColor: (theme) => isDarkMode ? theme.palette.darkVoilet : theme.palette.darkVoilet,
                        color: (theme) => theme.palette.whiteToOrange,
                        borderColor: (theme) => theme.palette.whiteToOrange,
                    }
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}
            >
                {t(`${getBtnText()}`)}
                <img src='/images/icons/filter-icon.svg' alt='filter-icon' />
            </Button>

            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                sx={(theme) => ({
                    color: theme.palette.fontColor,
                    textAlign: 'left',
                    font: 'normal normal 800 1.2rem Montserrat',
                    letterSpacing: '1px',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '0px',
                    '& > .MuiBackdrop-root': {
                        backdropFilter: 'blur(4px)',
                    },
                    '& > .MuiPaper-root': {
                        backgroundColor: isDarkMode ? theme.palette.darkVoilet : '#f6f6f6',
                        left: '0 !important',
                        top: '2rem !important',
                        minWidth: '100vw !important',
                        maxHeight: 'unset',
                        '& > .MuiList-root': {
                            backgroundColor: isDarkMode ? theme.palette.darkVoilet : '#f6f6f6',
                            padding: '0.3rem 0 !important',
                        }
                    }
                })}
                open={open}
                onClose={handleClose}>
                <Typography
                    variant="body2"
                    sx={(theme) => ({
                        color: theme.palette.black,
                        textAlign: 'left',
                        font: 'normal normal 800 1.2rem Montserrat',
                        letterSpacing: '1px',
                        opacity: '1',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.7rem',
                        position: 'relative'
                    })}>
                    {t('All Filters')}
                    <Close sx={(theme => ({
                        color: theme.palette.orange,
                        position: 'absolute',
                        right: '1rem',
                        fontSize: '1.8rem'
                    }))}
                        onClick={handleClose} />
                </Typography>
                <Box className={styles.searchInputBox}>
                    <input placeholder="Search" type="text" value={searchValue} onChange={handleMicroskillSearch} />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </Box>

                {/* ToggleButtons */}
                <SearchToggle />
                {/* ToggleButtons */}

                <List className={styles.scrollBar} sx={{ maxHeight: '64vh', overflow: 'auto', minHeight: '64vh' }}>
                    {
                        list?.length
                            && !searchValue.length ?
                            <ListItemButton
                                sx={theme => ({
                                    backgroundColor: getBgColor(theme),
                                    color: theme.palette.fontColor,
                                    padding: '0.3rem 1rem',
                                    marginBottom: 0,
                                    gap: '0.5rem',
                                    '&:hover': {
                                        backgroundColor: getBgColor(theme),
                                        color: theme.palette.fontColor
                                    }
                                })}
                                onClick={selectAllMicroskils}
                            >
                                <MuiCheckbox
                                    checked={list.length === selected.length}
                                    sx={(theme) => ({
                                        padding: '0.3rem',
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "1.5rem",
                                            color: false ? theme.palette.checkBox.checked : theme.palette.orange,
                                            padding: "0",
                                            margin: "0"
                                        }
                                    })}
                                />
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            variant="body2"
                                            sx={(theme) => ({
                                                color: theme.palette.orange,
                                                textAlign: 'left',
                                                font: 'normal normal 700 0.9rem Montserrat',
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: '0px',
                                            })}>
                                            {t(`Select All`)}
                                        </Typography>
                                    }
                                />
                                {/* {expanded !== item.id ? <ExpandLess style={{ color: 'transparent' }} /> : <ExpandMore style={{ color: 'transparent' }} />} */}
                            </ListItemButton>
                            : null
                    }
                    {

                        list.length ?
                            list?.map((item, i) => (
                                <React.Fragment key={i}>
                                    <ListItemButton
                                        ref={i == microskills.length - 1 ? lastElementRef : null}
                                        sx={theme => ({
                                            backgroundColor: getBgColor(theme),
                                            color: theme.palette.fontColor,
                                            padding: '0.3rem 1rem',
                                            marginBottom: 0,
                                            gap: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: getBgColor(theme),
                                                color: theme.palette.fontColor
                                            }
                                        })}
                                        onClick={() => selectMicroskill(item)}
                                    >
                                        <MuiCheckbox
                                            checked={selected.includes(item.id)}

                                            sx={(theme) => ({
                                                padding: '0.3rem',
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: "1.5rem",
                                                    color: selected.includes(item.id) ? theme.palette.checkBox.checked : theme.palette.greyText4,
                                                    padding: "0",
                                                    margin: "0"
                                                }
                                            })}
                                        />
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    variant="body2"
                                                    sx={(theme) => ({
                                                        // color: theme.palette.fontColor,
                                                        color: theme.palette.greyText4,
                                                        textAlign: 'left',
                                                        font: 'normal normal 700 0.9rem Montserrat',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '0px',
                                                    })}>
                                                    {t(`${item.name}`)}
                                                    {/* {t(`${commonUtil.truncateString(item.name)}`)} */}
                                                </Typography>
                                            }
                                        />
                                        {/* {expanded !== item.id ? <ExpandLess style={{ color: 'transparent' }} /> : <ExpandMore style={{ color: 'transparent' }} />} */}
                                    </ListItemButton>

                                </React.Fragment>
                            )) : <Typography
                                variant="body2"
                                sx={(theme) => ({
                                    color: theme.palette.fontColor,
                                    textAlign: 'center',
                                    font: 'normal normal 600 1rem Montserrat',
                                    letterSpacing: '0.05px',
                                    padding: '1rem'

                                })}>
                                No Microskills Available
                            </Typography>

                    }

                    {bottomLoader &&
                        <div ref={bottomLoaderRef} style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
                            <CircularProgress sx={{ height: '25px !important', width: '25px !important' }} />
                        </div>
                    }


                </List>


                <div className={styles.btnBox}>

                    <Button variant='contained' onClick={handleSave}
                        style={{
                            color: '#fff',
                        }}
                    >
                        SAVE FILTERS
                    </Button>

                    <Button variant='contained'
                        style={{
                            backgroundColor: '#fff',
                            color: '#f4511e',
                            border: `1px solid #F4511E`
                        }}
                        onClick={_handleClearList}>
                        CLEAR
                    </Button>

                </div>
            </Menu>
            }
        </>
    )

}

export default MicroskillFilter;