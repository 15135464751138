import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

const useStyles = makeStyles((theme) => ({
 typographyContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  marginTop: '-1.5rem !important',
 },
 dialogTitle: {
  fontSize: '1.5rem !important',
  fontWeight: '600 !important',
  textAlign: 'center !important',
  padding: '0rem !important',
  margin: '0 !important',
 },
 alertBtn: {
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
 },
 confirmBtn: {
  color: `${palette.white} !important`,
  backgroundColor: `${palette.orange} !important`,
  textAlign: 'center !important',
  fontWeight: '600 !important',
  borderRadius: '3px !important',
  letterSpacing: '1px !important',
 },
 annoucementContainer: {
  // display: 'grid',
  // gridTemplateRows: '15% 85%',
  height: '100%',
  position: 'relative',
 },
 contentTextWrapper: {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '0.5em',
  marginTop: '2rem',
  [theme.breakpoints.up("md")]: {
   paddingLeft: '1.5rem',
   marginTop: '0',
   alignItems: 'center',
   justifyContent: 'center',
  }
 },
 title: {
  [theme.breakpoints.up("md")]: {
   display: 'flex',
   justifyContent: 'flex-start',
   alignItems: 'center',
  }
 },
 description: {
  fontSize: '1.2rem',
  fontWeight: '400',
  whiteSpace: 'normal',
  marginTop: '0.5rem',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  [theme.breakpoints.up("md")]: {
   margin: 'auto 0',
  },
 },
 closeContainer: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100% !important',
  padding: '0.5em',
  height: '3rem',
 },
 mediaContainer: {
  height: '100%',
  width: '100%',
 },
 contentContainer: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  overflowY: 'scroll',
  height: `calc(100% - 3rem)`,
  "&::-webkit-scrollbar-thumb": {
   backgroundColor: theme.palette.orange,
  }
 },

}));

export default useStyles;