import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
    },

    mainAnswerContainer: {
        margin: '0',
        width: '100%',
    },

    centerFlexLaptop: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start'
        }
    },

    formControlLabel: {
        // alignItems: 'flex-start !important',
        marginLeft: '-0.3rem  !important',
        marginBottom: '0.5rem !important',
        marginRight: '0rem !important',
        width: '100% !important',
        '.MuiFormControlLabel-label': {
            width: '100% !important',
            padding: '0.3rem 0.5rem !important'
        }
    },

    radioCheckBox: { 
        padding: '0.3rem !important',
        [theme.breakpoints.down('md')]: {
            "&:hover": {
                backgroundColor: 'transparent !important',
            }
        }
    },

    openEnded: {
        padding: '0.7rem 0.5rem',
        fontSize: '1rem',
        width: '100%',
        minWidth: '100%',
        minHeight: '3rem',
        border: '1px solid rgba(0,0,0,0.2)',
        outline: 'none',
        fontFamily: "'Montserrat', sans-serif",
    },

    sliderComponent: {
        // marginBottom: '1rem',
        height: '4rem',
        marginLeft: "0.5rem",
        marginBottom: '-1rem',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            marginLeft: '0rem'
        }
    },

    numberRating: {
        width: 'calc(100% - 0.5rem) !important',
        ".MuiSlider-markLabel": {
            width: '40% !important',
            textAlign: 'center !important',
            whiteSpace: 'wrap !important',
            textOverflow: 'wrap !important',
        },
        [theme.breakpoints.up('md')]: {
            ".MuiSlider-markLabel": {
                width: '40 !important%',
            },
        }
    },

    datePicker: {
        padding: '0.5rem !important',
        marginLeft: '0rem !important',
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
            '.MuiInputBase-root > input': {
                padding: '0.5rem',
                width: '100%'
            }
        },
    },

    starRating: {
        fontSize: '2.5rem !important',
        marginLeft: '0rem !important',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0rem !important'
        }
    },

    uploadFileButton: {
        marginLeft: '2rem !important'
    },
    starIcon: {
        borderRadius: '5px !important',
        marginRight: '0.3rem !important',
    }
}));

export default useStyles;