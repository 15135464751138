import React, { useCallback, useEffect, useState } from "react";

// material UI : 
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

// styles : 
import useStyles from "./index.styles.js";

// Components :
import VideoContent from "../content/video-content/index.js";
import LinkContent from "../content/link-content/index.js";
import AudioContent from "../content/audio-content/index.js";

// utils : 
import isEmpty from "../../../utils/isEmpty.js";

// APIs : 
import { postAnnouncementViewed, postBulletinEndAttempt } from "../../../redux/microskill/microskill.api.js";

const modalStyle = (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    height: '90%',
    zIndex: '1',
    [theme.breakpoints.up("sm")]: {
        width: '80vw',
    }
});

const Announcement = React.memo(({ isOpen, bulletinData, newsBulletinData, setIsAnnouncementVisible }) => {

    const classes = useStyles();

    const [cardType, setCardType] = useState();
    const [cardContents, setCardContents] = useState();

    const _handleAnnouncementDateCheck = () => {
        // Compare the dates directly and return the appropriate data :
        return new Date(bulletinData?.launchesOn) > new Date(newsBulletinData?.launchesOn)
            ? bulletinData
            : newsBulletinData;
    }

    useEffect(() => {

        let announcementData;
        (!isEmpty(bulletinData) && !isEmpty(newsBulletinData)) ? (announcementData = _handleAnnouncementDateCheck()) : (announcementData = bulletinData || newsBulletinData);

        if (announcementData?.announcement) {
            const content = announcementData?.content;
            setCardType(content?.cardType);
            setCardContents(content?.contents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulletinData, newsBulletinData]);

    const handleClose = useCallback(() => {

        if (newsBulletinData.announcement) {
            postBulletinEndAttempt({ bulletinId: newsBulletinData?.id });
            postAnnouncementViewed({ bulletinId: newsBulletinData?.id });
            setIsAnnouncementVisible(false);
        }

        if (bulletinData.announcement) {
            postBulletinEndAttempt({ bulletinId: bulletinData?.id });
            postAnnouncementViewed({ bulletinId: bulletinData?.id });
            setIsAnnouncementVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsAnnouncementVisible]);


    const renderMediaContent = () => {
        switch (cardType) {
            case 'ITB':
                return cardContents &&
                    <img src={cardContents[0]?.content} alt='image-content'
                        style={{ height: '100%', width: '100%' }}
                    />
            case 'VTB':
                if (cardContents[0]?.name === 'YOUTUBE') {
                    return <VideoContent videoUrl={cardContents[0]?.content} type="YOUTUBE" height="100%" />;
                }
                return <VideoContent videoUrl={cardContents[0]?.content} type="VIDEO" height="100%" />;
            case 'LTB':
                return newsBulletinData?.content?.cardType === 'LTB' && <LinkContent url={cardContents[0]?.content} />;
            case 'ATB':
                return cardContents && <AudioContent audioFileUrl={cardContents[0]?.content} />;
            default:
                return null;
        }
    };

    const renderTextContent = () => {
        const title = (!isEmpty(cardContents?.[1]?.content)) ? cardContents?.[1]?.content : '';
        const description = (!isEmpty(cardContents?.[2]?.content)) ? cardContents?.[2]?.content : '';

        return (
            <>
                <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
                <p className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
            </>
        );
    };

    return (
        <Modal
            open={isOpen}
            onClose={(e, reason) => reason === 'backdropClick' && e.stopPropagation()} // Prevent closing when clicking outside
            BackdropComponent="div" // Disable backdrop click event propagation
        >
            <Box sx={modalStyle} className={`${classes.annoucementContainer}`}>

                <div className={classes.closeContainer} onClick={handleClose}>
                    <CloseIcon htmlColor="#f4511e" fontSize="large" style={{ cursor: 'pointer' }} />
                </div>

                <div className={`${classes.contentContainer} scrollbar`}>
                    <div className={classes.mediaContainer}>
                        {renderMediaContent()}
                    </div>

                    <div className={classes.contentTextWrapper}>
                        {renderTextContent()}
                    </div>
                </div>
            </Box>
        </Modal>
    );
});

export default Announcement;
