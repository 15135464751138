import React from "react";
import { useTranslation } from "react-i18next";

// mui
import { Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ExpandableButton = ({ clickhandler, toggleState }) => {
    const { t } = useTranslation();

    const styling = (theme) => ({
        borderRadius: '50px',
        color: `${theme.palette.white}`,
        backgroundColor: `${theme.palette.orange}`,
        padding: '0.25rem 0.25rem 0.25rem 0.75rem',
        fontSize: '0.8rem',
        boxShadow: 'none',
        fontWeight: '600'
    })

    const iconColor = (theme) => ({
        color: `${theme.palette.white}`,
        fontSize: '1.5rem',
        fontWeight: '600'
    })

    return (
        <Button
            onClick={clickhandler}
            variant="contained"
            sx={styling}
        >
            {t(toggleState ? t("Show Less") : t("Show More"))}
            {toggleState ? <ExpandLessIcon sx={iconColor} /> : <ExpandMoreIcon sx={iconColor} />}
        </Button >
    )
};

export default ExpandableButton;
