import React, { useEffect, useState } from 'react';
import { Router } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { useSelector, useDispatch } from "react-redux";
import { useDeviceSelectors } from 'react-device-detect';
import moment from "moment/moment";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, elements, } from 'chart.js';

// mui
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// views
import Layout from './components/layout';
import LoginPage from './views/login';
import OtpLoginPage from './views/otp-login';
import Learn from './views/learn';
import ChallengeList from './views/challenge-list';
import LearningAid from './views/learning-aid';
import Documents from './views/content-repository';
import Spotlight from './views/spotlight';
import PerformanceAndManagerDashboard from "./views/performance-and-manager-dashboard"
// import Certificate from './views/certificate';
import Certificate from './views/certificate/index_prev';
// import SpotlightResult from './views/spotlight-result';
import GameBasedAssessments from './views/game-based-assessments';
import FormsChallenge from './views/form-challenge';
import AdityaBirlaCapital from './company-integrations/aditya-birla-capital';
import CompanyIntegartionMondelaz from './company-integrations/mondelaz';
import CompanyIntegartionMsal from './company-integrations/msal';
import Product from './views/product';

import Toast from './components/toast';
import LoadingMicroskill from './components/loading-microskill';
import DialogAlert from './components/dialog-alert';
import ErrorPage from './views/404';
import RootPage from './views/root-page';
import GooglePage from './views/google-page';
import OktaPage from './views/okta-page';
import ScormContent from './views/scorm-content';
import MsalPage from './views/msal-page';
import PrivacyPolicy from './views/privacy-policy'; //
// import SpotlightResult2 from './views/spotlight-result-2';
import SpotlightResultRevamped from './views/spotlight-result-revamped';
import LearningJourneyPage from './views/learning-journey-page';

// webview bridge for flutter
import webViewJavaScriptBridge from 'webview-javascript-bridge';


// generic document viewer
import GenericDocViewer from './views/generic-doc-viewer';

// context
import { LearningAidProvider } from './context/learningAidContext';
import { CommonContextProvider } from './context/CommonContext';


// redux
import { checkFirebaseTokenInNative, saveDeviceToken } from './redux/auth/auth.api';
import { openToast, openDialogue, setIsMobile, setIsNativeApp, setSelectedLanguage, setIsTablet } from './redux/common/common-slice';
import { setDeviceDetails } from './redux/common-persist/common-persist-slice';
import { setUser, setUserProfileData } from './redux/auth/auth-slice';
import { setPrivacyPolicyDetails } from "./redux/common-persist/common-persist-slice";


// utils
import isEmpty from './utils/isEmpty';
import commonUtil from './utils/commonUtil';
import './utils/i18n' // initialises i18n

// integrations
import { thirdPartyAppIntegrationWithMastero } from './company-integrations';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';
import { LoginCallback } from "@okta/okta-react"
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// configs for integrations
import config from './company-integrations/okta/index';
import MsalConfig from './company-integrations/msal/msalConfig';

// styles
import theme from "./theme";
import './App.css';

// API
import { getUserProfile } from './redux/user-specific/userSpecific.api';
import { useTranslation } from 'react-i18next';
import constants from './constants';
// import palette from './theme/palette';

// theme files
import palette, { lightPalette } from './theme/palette';
import typography from './theme/typography';
import overrides from './theme/overrides'
import components from "./theme/components";
import localStorage from 'redux-persist/es/storage';
import Bulletins from './views/bulletins';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var bridge = require("webview-javascript-bridge-promised");

const oktaAuth = new OktaAuth(config.oidc);

const history = createBrowserHistory();

// AZURE MSAL BROWSER
const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: MsalConfig.clientId,
    redirectUri: MsalConfig.redirectUri,
    authority: MsalConfig.authority
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
})


const App = (props) => {
  // const theme = useTheme();
  const dispatch = useDispatch();

  const [selectors, deviceData] = useDeviceSelectors(window.navigator.userAgent);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const commonState = useSelector(state => state.common);

  const isThirdPartyIntegration = useSelector(state => state.auth.isThirdPartyIntegration);
  const deviceDetails = useSelector(state => state?.commonPersist?.deviceDetails);
  const userDetail = useSelector(state => state?.auth?.user); // user details
  const privacyPolicy = useSelector(state => state?.commonPersist?.privacyPolicyDetails);
  const [performancePageSettings, setPerformancePageSettings] = useState({ bottomNav: true });
  const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);

  const [showUpdateAppDialog, setShowUpdateAppDialog] = useState(false);
  const [showVpnDialog, setShowVpnDialog] = useState(false);

  const { i18n } = useTranslation();

  //to hide bottom nav incase if usertype is MANAGER
  useEffect(() => {
    if (window.location.pathname === '/performance') {
      (async () => {
        const userType = await localStorage.getItem('userType') ?? constants.USER_TYPES.USER;
        const isOnlyManager = userType === constants.USER_TYPES.MANAGER;
        if (isOnlyManager) setPerformancePageSettings({ bottomNav: false })
      })()
    }
  }, [window.location.pathname])


  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 5;
  const RETRY_INTERVAL = 1000;

  // this code is for fixing blank screen issue for android 10,11,12
  // Polyfill for Android
  if (!window.crypto || !window.crypto.randomUUID) {
    const cryptoPolyfill = {
      randomUUID: function () {
        // Implement your UUID generation logic here as a fallback
        // or use an alternative library
      },
      getRandomValues: function (array) {
        // Implement your own random values generation logic here
        // Ensure that the array is filled with random values
        for (let i = 0; i < array.length; i++) {
          array[i] = Math.floor(Math.random() * 256);
        }
        return array;
      },
    };
    // Assign the polyfill to window.crypto
    Object.defineProperty(window, 'crypto', {
      value: cryptoPolyfill,
      writable: false, // Set to true if necessary
      enumerable: true,
      configurable: true,
    });
  }
  // this code is for fixing blank screen issue for android 10,11,12

  // const checkDetails = () => {
  //   let deviceDetails = window.deviceDetails || document.deviceDetails;
  //   console.log("Device details: ", deviceDetails);

  //   if (!isEmpty(deviceDetails)) {
  //     // deviceDetails is string convert it to object
  //     deviceDetails = commonUtil.parseJSONFromFlutter(deviceDetails)
  //     console.log("JSON Device details: ", deviceDetails);
  //     dispatch(setDeviceDetails(deviceDetails));
  //     dispatch(setIsNativeApp(true));
  //   }
  // };

  useEffect(() => {
    webViewJavaScriptBridge.registerMessageHandler("deviceDetails", (data) => {
      dispatch(setDeviceDetails(data));
      dispatch(setIsNativeApp(true));
      localStorage.setItem("isNative", true);
    });

    webViewJavaScriptBridge.registerMessageHandler("isNative", (data) => {
      dispatch(setIsNativeApp(true));
    });

    // webViewJavaScriptBridge.registerMessageHandler("rotateFlag", (data) => {
    //   console.log("rotateFlag", data);
    //   localStorage.setItem("rotateFlag", data);
    // })

    webViewJavaScriptBridge.registerMessageHandler("is_app_started_from_notification", (data) => {
      localStorage.setItem("is_app_started_from_notification", data);
      window.is_app_started_from_notification = data;
    });

    webViewJavaScriptBridge.registerMessageHandler("app_notification_data", (data) => {
      localStorage.setItem("app_notification_data", data);
      window.app_notification_data = data;
      if (!isEmpty(data)) {
        webViewJavaScriptBridge.sendMessage({ action: 'NotificationDataReceiveChannel', params: true }, function (response) {
        });
      }
    });

    webViewJavaScriptBridge.registerMessageHandler("notification_messageId", (data) => {
      localStorage.setItem("notification_messageId", data);
      window.notification_messageId = data;
    });

    webViewJavaScriptBridge.registerMessageHandler("messageForAlkemUserNativeCompanyCode", (data) => {
      // console.log("messageForAlkemUserNativeCompanyCode: ", data);
      window.messageForAlkemUserNativeCompanyCode = data;
    });

    webViewJavaScriptBridge.registerMessageHandler("messageForAlkemUserNativeEncryptedText", data => {
      // console.log("messageForAlkemUserNativeEncryptedText", data);
      window.messageForAlkemUserNativeEncryptedText = data;
    })

    webViewJavaScriptBridge.registerMessageHandler("pausedApp", (data) => {
      // console.log("pausedApp: ", data);
      window.pausdApp = data === "true" ? true : false;
    });

    webViewJavaScriptBridge.registerMessageHandler("killedApp", (data) => {
      window.killedApp = data === "true" ? true : false;
    });

    webViewJavaScriptBridge.registerMessageHandler("vpnConnected", (data) => {
      console.log("vpnConnected: ", data);
      if (data === "true" || data === true) {
        // uncomment this line to show dialog for VPN
        // setShowVpnDialog(true);
      }
    });

    // window.onbeforeunload = function () {
    //   console.log("beforeunload event");
    //   webViewJavaScriptBridge.unregisterMessageHandler("deviceDetails");
    //   webViewJavaScriptBridge.unregisterMessageHandler("rotateFlag");
    //   webViewJavaScriptBridge.unregisterMessageHandler("isNative");
    //   webViewJavaScriptBridge.unregisterMessageHandler("is_app_started_from_notification");
    //   webViewJavaScriptBridge.unregisterMessageHandler("app_notification_data");
    //   webViewJavaScriptBridge.unregisterMessageHandler("notification_messageId");
    //   webViewJavaScriptBridge.unregisterMessageHandler("messageForAlkemUserNativeCompanyCode");
    //   webViewJavaScriptBridge.unregisterMessageHandler("messageForAlkemUserNativeEncryptedText");
    //   webViewJavaScriptBridge.registerMessageHandler("pausedApp");
    //   webViewJavaScriptBridge.registerMessageHandler("killedApp");
    // };

    // return () => {
    //   console.log("returened from app.js")
    //   webViewJavaScriptBridge.unregisterMessageHandler("deviceDetails");
    //   webViewJavaScriptBridge.unregisterMessageHandler("rotateFlag");
    //   webViewJavaScriptBridge.unregisterMessageHandler("isNative");
    //   webViewJavaScriptBridge.unregisterMessageHandler("is_app_started_from_notification");
    //   webViewJavaScriptBridge.unregisterMessageHandler("app_notification_data");
    //   webViewJavaScriptBridge.unregisterMessageHandler("notification_messageId");
    //   webViewJavaScriptBridge.unregisterMessageHandler("messageForAlkemUserNativeCompanyCode");
    //   webViewJavaScriptBridge.unregisterMessageHandler("messageForAlkemUserNativeEncryptedText");
    //   webViewJavaScriptBridge.registerMessageHandler("pausedApp");
    //   webViewJavaScriptBridge.registerMessageHandler("killedApp");
    // }
  }, []);

  useEffect(() => {
    // checkFirebaseTokenInNative();
    dispatch(setIsMobile(isMobile));
    dispatch(setIsTablet(isTablet));
  }, [isMobile, isTablet]);

  useEffect(() => {
    (async function () {
      let token = await localStorage.getItem("accessToken");
      // console.log("device details set 1", deviceDetails);
      if (isEmpty(token)) return;
    })();

    const handleDeviceDetails = async () => {
      saveDeviceToken();
    };

    const retryCheckDetails = () => {
      setRetryCount(retryCount + 1);
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          handleDeviceDetails();
        }, RETRY_INTERVAL);
      } else {
        console.log('Max retries reached');
      }
    };

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      handleDeviceDetails();
    }

    if (!window.deviceDetails && !document.deviceDetails) {
      retryCheckDetails();
    }

  }, [dispatch])

  const fetchUserProfileDetails = async () => {

    let token = await localStorage.getItem("accessToken");
    let preferredLang;

    if (!isEmpty(token)) {
      const apiResp = await getUserProfile()
      let userProfileResp = apiResp

      if (apiResp.status === 200 || apiResp.status === 201) {
        let userProfileData = userProfileResp?.data?.data
        const privacyPolicyObj = userProfileData?.privacyPolicy;

        if (!isEmpty(userProfileData)) {
          dispatch(setUserProfileData(userProfileData));
          dispatch(setPrivacyPolicyDetails(privacyPolicyObj));

          if (userProfileData?.languagePref) { // if preference is exist, set UI language as preferred language

            preferredLang = userProfileData?.languagePref;
            dispatch(setSelectedLanguage(preferredLang));


            // set company logo and theme for pre logged in users
            if (!isEmpty(userDetail) && (isEmpty(userDetail?.companyLogo) || isEmpty(userDetail?.companyTheme))) {
              let companyLogo = userProfileData?.companyData?.logo
              let companyTheme = userProfileData?.companyData?.theme
              let enableScreenShot = userProfileData?.companyData.enableScreenShot
              dispatch(setUser({ ...userDetail, companyLogo, companyTheme, enableScreenShot }))
            }
          } else { // if preference is null set Default language as UI language

            // For Bajaj ICV :
            if (userProfileData?.companyId === process.env.REACT_APP_BAJAJ_ICV_COMPANY_ID) {
              preferredLang = 'English';
              dispatch(setSelectedLanguage(preferredLang))
            } else {
              preferredLang = 'Default';
              dispatch(setSelectedLanguage((preferredLang)));
            }
          }
          let langCode = constants.LANGUAGES_LIST?.filter(e => e.name === preferredLang)[0]
          i18n.changeLanguage(langCode?.key);
        }
      } else { // if any error occured, set Default language as UI language
        dispatch(setSelectedLanguage('Default'))
      }
    }
  }

  useEffect(() => {
    (async function () {
      if (!navigator.onLine) {
        dispatch(openDialogue({
          isDialogueOpen: true,
          dialogueTitle: 'Network Error',
          dialogueContent: 'You seem to be offline, please check your internet and try again.'
        }));
        return
      }
      thirdPartyAppIntegrationWithMastero(dispatch, useSelector, openDialogue);
      fetchUserProfileDetails();

    })();
  }, []);

  // PRIVACY POLICY
  // useEffect(() => {

  //   // For Date Format:
  //   const timeLimit = privacyPolicy?.timeLimit;
  //   const formattedResult = moment(privacyPolicy?.policyAcceptedOn).format('DD-MM-YYYY hh:mm:ss A');

  //   const expirationDate = moment(formattedResult, 'DD-MM-YYYY hh:mm:ss A').add(timeLimit, 'days').format('DD-MM-YYYY hh:mm:ss A');
  //   setPolicyExpirationDate(expirationDate);
  // }, []);
  // PRIVACY POLICY

  const handleToastClose = () => {
    dispatch(openToast(false));
  };

  const handleConfirmBtnClick = () => {
    dispatch(openDialogue(false));
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(!isEmpty(originalUri) ? originalUri : "/"));
    window.location.href = "/okta";
  }

  useEffect(() => {
    if (!isEmpty(isDarkMode)) {
      let body = document.querySelector("body")
      body.style = `
        background-color: ${isDarkMode ? palette.voilet : palette.white};
        color: ${isDarkMode ? palette.white : lightPalette.black}
      `
    }
  }, [isDarkMode])

  const customTheme = createTheme({
    palette: isDarkMode ? palette : lightPalette,
    // overrides,
    typography,
    components
  });


  const getDataFromLocalStorage = async (key) => await localStorage.getItem(key);
  useEffect(() => {
    if (!isMobile) return;
    if (window?.innerWidth > 768) return;
    (async function () {
      const updateAppDialogViewed = await getDataFromLocalStorage("updateAppDialogViewed");

      if (isEmpty(updateAppDialogViewed) && !showUpdateAppDialog) {
        setTimeout(() => {
          setShowUpdateAppDialog(false);
          // setShowUpdateAppDialog(true);
        }, 5000) // 5 second delay for alkepedia
      }
    })()
  }, [isMobile, showUpdateAppDialog])

  return (
    <div className="App">
      {/* <div className="App" dir={commonState.selectedLanguage === 'ur' ? "rtl" : "ltr"}> */}
      <ThemeProvider theme={customTheme}>
        <MsalProvider instance={publicClientApplication}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <CommonContextProvider>
              <BrowserRouter>
                <Routes history={history}>
                  <Route path='/'
                    element={
                      <Layout
                        Component={RootPage}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }>
                  </Route>

                  <Route path='/okta'
                    element={
                      <Layout
                        Component={OktaPage}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }>
                  </Route>

                  <Route path='/login/callback/okta-auth' element={<LoginCallback loadingElement={<LoadingMicroskill />} />} />

                  <Route path='/login/msal'
                    element={
                      <Layout
                        Component={MsalPage}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }
                  ></Route>

                  <Route path='/msal'
                    element={
                      <Layout
                        Component={CompanyIntegartionMsal}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }
                  ></Route>

                  <Route path='/google-oauth' element={
                    <Layout
                      Component={GooglePage}
                      withHeader={false}
                      withDrawer={false}
                      withBottomNav={false}
                      isProtected={false}
                    />
                  }>
                  </Route>

                  <Route path='/loading'
                    element={
                      <Layout
                        Component={LoadingMicroskill}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                        isOpen={true}
                        loadingText={false}
                      />
                    }>
                  </Route>

                  <Route path='/login'
                    element={
                      <Layout
                        Component={LoginPage}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }>
                  </Route>

                  <Route path='/otp-login'
                    element={
                      <Layout
                        Component={OtpLoginPage}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }>
                  </Route>

                  <Route path='/learn'
                    element={
                      <Layout
                        Component={Learn}
                        withHeader={true}
                        withDrawer={true}
                        withBottomNav={true}
                        isProtected={true}
                      />
                    }>
                  </Route>

                  <Route path="/performance"
                    element={
                      <Layout
                        Component={PerformanceAndManagerDashboard}
                        withHeader={true}
                        withDrawer={true}
                        showSearchHeader={false}
                        withBottomNav={performancePageSettings.bottomNav}//hiding bottom nav incase of only manager
                        isProtected={true}
                      />
                    }></Route>

                  <Route path='/challenge-list/:microskillId'
                    element={
                      <Layout
                        Component={ChallengeList}
                        withHeader={isMobile || isTablet ? false : true}
                        withHeader2={false}
                        withDrawer={true}
                        withBottomNav={true}
                        isProtected={true}
                      />
                    }>
                  </Route>

                  <Route path='/challenge-list/:microskillId/certificate'
                    element={
                      <Layout
                        Component={Certificate}
                        withHeader={!isMobile}
                        withHeader2={isMobile}
                        withDrawer={!isMobile}
                        withBottomNav={true}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/learning-journey/:learnerJourneyId/certificate'
                    element={
                      <Layout
                        Component={Certificate}
                        withHeader={!isMobile}
                        withHeader2={isMobile}
                        withDrawer={!isMobile}
                        withBottomNav={true}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/challenge-list/:microskillId/:challengeId/spotlight'
                    element={
                      <Layout
                        Component={Spotlight}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? true : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/spotlight-result/:microskillId/:challengeId/:id/:jobId'
                    element={
                      <Layout
                        Component={SpotlightResultRevamped}
                        // Component={SpotlightResult2}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? true : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/challenge-list/:microskillId/:challengeId/gba'
                    element={
                      <Layout
                        Component={GameBasedAssessments}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? false : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  {/* to open form as a challenge */}
                  <Route path='/challenge-list/:microskillId/:challengeId/:formId'
                    element={
                      <Layout
                        Component={FormsChallenge}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? false : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/challenge-list/:microskillId/:challengeId/learning-aid'
                    element={
                      <LearningAidProvider>
                        <Layout
                          Component={LearningAid}
                          withHeader={isMobile || isTablet ? false : true}
                          withHeader2={isMobile ? false : false}
                          withDrawer={true}
                          withBottomNav={false}
                          isProtected={true}
                          showSearchHeader={false}
                          showHeaderBackButton={true}
                        />
                      </LearningAidProvider>
                    }>
                  </Route>

                  <Route path='/challenge-list/:microskillId/:challengeId/scorm/:folderName'
                    element={
                      <Layout
                        Component={ScormContent}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? true : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                        showSearchHeader={false}
                        showHeaderBackButton={true}
                      />
                    }>
                  </Route>

                  <Route path='/content-repository'
                    element={
                      <Layout
                        Component={Documents}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? true : false}
                        withDrawer={true}
                        withBottomNav={true}
                        isProtected={true}
                      />
                    }>
                  </Route>

                  {/* sales enablement route */}
                  <Route path='/product/:productId'
                    element={
                      <LearningAidProvider>
                        <Layout
                          Component={Product}
                          withHeader={isMobile ? false : true}
                          withHeader2={false}
                          withDrawer={true}
                          withBottomNav={true}
                          isProtected={true}
                          showSearchHeader={false}
                          showHeaderBackButton={true}
                        />
                      </LearningAidProvider>

                    }>
                  </Route>

                  <Route path='/learning-journey/:learningJourneyId'
                    element={
                      <LearningAidProvider>
                        <Layout
                          Component={LearningJourneyPage}
                          withHeader={isMobile || isTablet ? false : true}
                          withHeader2={false}
                          withDrawer={true}
                          withBottomNav={true}
                          isProtected={true}
                          showSearchHeader={false}
                          showHeaderBackButton={true}
                        />
                      </LearningAidProvider>
                    }>
                  </Route>


                  <Route path='/privacy-policy'
                    element={
                      <Layout
                        Component={PrivacyPolicy}
                        withHeader={isMobile ? false : true}
                        withHeader2={isMobile ? true : false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={true}
                      />
                    }>
                  </Route>

                  {/* company inegration route  */}
                  {/* ADITYA BIRLA CAPTIAL */}
                  <Route path='/abcroute'
                    element={
                      <Layout
                        Component={AdityaBirlaCapital}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                        isOpen={true}
                        loadingText={false}
                      />
                    }>
                  </Route>
                  {/* ADITYA BIRLA CAPTIAL */}

                  {/* Mondelaz */}
                  <Route path='/integration'
                    element={
                      <Layout
                        Component={CompanyIntegartionMondelaz}
                        withHeader={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                        isOpen={true}
                        loadingText={false}
                      />
                    }>
                  </Route>
                  {/* Mondelaz */}
                  {/* company integration route */}

                  {/* GENERIC DOCUMENT VIEWER */}
                  <Route path='/generic-doc-viewer'
                    element={
                      <Layout
                        Component={GenericDocViewer}
                        withHeader={false}
                        withHeader2={false}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                      />
                    }
                  />
                  {/* GENERIC DOCUMENT VIEWER */}

                  <Route path='/bulletins/:bulletinId'
                    element={
                      <LearningAidProvider>
                        <Layout
                          Component={Bulletins}
                          withHeader={isMobile || isTablet ? false : true}
                          withHeader2={(isMobile || isTablet) ? false : false}
                          withDrawer={true}
                          withBottomNav={false}
                          isProtected={true}
                          showSearchHeader={false}
                          showHeaderBackButton={true}
                        />
                      </LearningAidProvider>
                    }>
                  </Route>

                  <Route path='*'
                    element={
                      <Layout
                        Component={ErrorPage}
                        withHeader={false}
                        withHeader2={true}
                        withDrawer={false}
                        withBottomNav={false}
                        isProtected={false}
                        showSearchHeader={false}
                      />
                    }>
                  </Route>

                </Routes>











                {
                  commonState?.isToastOpen && (
                    <Toast
                      isOpen={commonState.isToastOpen}
                      toastDuration={commonState.toastDuration}
                      toastSeverity={commonState.toastSeverity}
                      toastMessage={commonState.toastMessage}
                      handleToastClose={handleToastClose}
                    />
                  )
                }

                {
                  showUpdateAppDialog && (
                    <DialogAlert
                      isOpen={true}
                      title={'Update available'}
                      content={'Please update your app for a better experience.'}
                      handleConfirmBtnClick={() => {
                        localStorage.setItem("updateAppDialogViewed", true);
                        setShowUpdateAppDialog(false);
                        setTimeout(() => {
                          window.location.href = 'http://onelink.to/mzra9m';
                        }, 100); // 100ms delay
                      }}
                      handleDiscardBtnClick={() => {
                        localStorage.setItem("updateAppDialogViewed", true)
                        setShowUpdateAppDialog(false);
                      }}
                      confirmBtnName={'Update Now'}
                      discardBtnName={'Cancel'}

                    />
                  )
                }
                {
                  showVpnDialog && (
                    <DialogAlert
                      isOpen={true}
                      title={'VPN Detected'}
                      content={'You are using VPN'}
                      handleConfirmBtnClick={() => {
                        setShowVpnDialog(false);

                      }}
                      confirmBtnName={'Exit Now'}
                    />
                  )
                }

                {
                  commonState.isDialogueOpen && (
                    <DialogAlert
                      isOpen={commonState.isDialogueOpen}
                      title={commonState.dialogueTitle}
                      content={commonState.dialogueContent}
                      handleConfirmBtnClick={handleConfirmBtnClick}
                      confirmBtnName={'Okay'}
                    />
                  )
                }

                {
                  isThirdPartyIntegration && (
                    <LoadingMicroskill
                      isOpen={isThirdPartyIntegration}
                      loadingText={false}
                    />
                  )
                }

                {
                  (!privacyPolicy?.policyAccepted) && userDetail?.Type !== constants.USER_TYPES.MANAGER && (
                    <PrivacyPolicy viewInModel={true} />
                  )
                }

              </BrowserRouter>
            </CommonContextProvider>

          </Security >
        </MsalProvider>
      </ThemeProvider>
    </div >

  );
}
export default App;