import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";
import Lottie from "react-lottie-player";

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/scq-how-to-play";
import GbaWinScreen from "../common-screens/win-screen";
import GbaGameOverScreen from "../common-screens/game-over-screen";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// style
import useStyle from "./index.styles";

// lottie old assets
import monkeyScared from '../../../lottie-assets/tiki-monkey/monkey-scared.json';
// import monkeyWalk2 from '../../../lottie-assets/tiki-monkey/monkey-walk-2.json';
import monkeyWalk2 from '../../../lottie-assets/tiki-monkey/walk-18second.json';
import monkeyLaugh from '../../../lottie-assets/tiki-monkey/monkey-laugh.json';

// lottie new assets
import monkeyScaredNew from '../../../lottie-assets/tiki-monkey/monkey-scared-new.json';
import monkeyLaughNew from '../../../lottie-assets/tiki-monkey/monkey-laugh-final';
// import monkeyLaughNew from '../../../lottie-assets/tiki-monkey/monkey-laugh-new.json';
import monkeyWalk2New from '../../../lottie-assets/tiki-monkey/walk-18second.json';
// import monkeyWalk2New from '../../../lottie-assets/tiki-monkey/monkey-walk-2-new.json';
import wrongAnswer from '../../../lottie-assets/common/Wrong-Answer-light.json';
import correctAnswer from '../../../lottie-assets/common/Correct-Answer-light.json';
import timeoutLottie from "../../../lottie-assets/common/Timeout-light.json";

export const GAME_ASSETS = [
    {
        monkeyScared: monkeyScared,
        monkeyWalk2: monkeyWalk2,
        monkeyLaugh: monkeyLaugh,
        // gameBgImage: "url(/images/gba/tiki-monkey/tiki_monkey_bg.jpg)",
        gameBgImage: "url(/images/gba/tiki-monkey/tiki_monkey_bg.jpg)",
        bananasImg: "/images/gba/tiki-monkey/bananas.svg",
        pathBgImg: 'url(/images/gba/tiki-monkey/path.png)'

    },
    {
        monkeyScared: monkeyScaredNew,
        monkeyWalk2: monkeyWalk2New,
        monkeyLaugh: monkeyLaughNew,
        // gameBgImage: "url(/images/gba/tiki-monkey/tiki_monkey_bg-new.jpg)",
        gameBgImage: "url(/images/gba/tiki-monkey/tiki_monkey_bg.jpg)",
        bananasImg: "/images/gba/tiki-monkey/bananas-new.svg",
        pathBgImg: 'url(/images/gba/tiki-monkey/path-new.png)'
    },
];
export const randomAsset = 1;
// export const randomAsset = commonUtil.randomNo(0, 1, []);

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const TikiMonkey = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, setAddPause } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const gameName = gameData?.name;
    const questionData = gameData.questions;
    const { microskillId, challengeId } = useParams();

    const timePerQuestion = 18;
    const widthMonkey = 120;
    const widthBanana = 30;
    // const widthBanana = 40;
    const initialDelay = 1000;
    const animationTime = 1000;

    let maxLifeGba = 5;
    // let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let questionsInGba = questionData.length;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });
    let mxmScore = 120;
    let marksPerQuestion = mxmScore / questionData.length;

    let animateMonkey = [];
    let textLottie = null;
    let headerBg = "#4f0060";
    let bgGradientDefault = defaultGradient;
    let incorectBgGradient = "linear-gradient(#800e31b5, #f63b34b5)";
    let correctOptionGradient = "linear-gradient(#45a836a1, #45a836a1)";
    let inCorrectOptionGradient = "linear-gradient(#cb2f2fde, #cb2f2fde)";
    let correctOptionShadow = "0px 0px 7px 0px #fff";
    let timerValue = 0;

    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [activeindex, setActiveindex] = useState(0);
    const [gameEndType, setGameEndType] = useState(null);
    const [attemptData, setAttemptData] = useState('');
    const [componentKey, setComponentKey] = useState(new Date().getTime());
    const [isAudioPlaying, setIsAudioPlaying] = useState(true);
    const [incorrectData, setIncorrectData] = useState();

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const intervalRef = useRef();
    const totalScoreRef = useRef();
    const animationRef2 = useRef();
    const progressBarRef = useRef();
    const questionRef = useRef([]);
    const qstnBoxRef = useRef(null);
    const lifeTextRef = useRef(null);
    const stopWatchRef = useRef();
    const divLifeRef = useRef();
    const divTimerRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const timerRef = useRef();
    const totalTimeSpentRef = useRef(0);
    const gameContainerRef = useRef();
    const incorrectGradientOverlayRef = useRef();
    const animateMonkeyRef = useRef([]);
    const animationRunningRef = useRef(true);
    const isAnswerSelectedRef = useRef(true);
    const isGamePausedRef = useRef(false);
    const monkeyLottiesRef = useRef([]);
    const firstUpdate = useRef(true);
    const questionNoRef = useRef();
    const finalChallengeDataRef = useRef();
    const [fSize, setFSize] = useState(1);
    const gameStartTimeRef = useRef(Date.now());
    const natureAudioRef = useRef();
    const timeoutLottieBoxRef = useRef();
    const timeoutLottieRef = useRef();

    const totalTimeIntervalRef = useRef();
    const totalGameTimeRef = useRef(0);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        setFSize(window.innerHeight / 961);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    useEffect(() => {
        if (isPauseOverlay) {
            if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.pause();
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.play();
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    useEffect(() => {
        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE.GAME);
            totalScoreRef.current = 0;

            setTimeout(() => {
                startMonkeyTranslation();
            }, 1000);

            if (activeindex === 0) {
                if (!isEmpty(questionRef.current)) questionRef.current.innerHTML = questionData[activeindex].question;
            }
        }
    }, [isGamePage]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const startTimer = (time) => {
        clearInterval(intervalRef.current);

        // let timer = Date.now();
        totalTimeSpentRef.current = Date.now();
        // totalTimeSpentRef.current = timer.getTime() / 1000;
        //start timer
        intervalRef.current = setInterval(() => {
            timerValue += 1000;
            if (timerValue % 1000 !== 0 || isEmpty(timerRef.current)) return;
            timerRef.current.innerHTML = time--;
            if (time <= 8) {
                if (!isEmpty(divTimerRef.current)) divTimerRef.current.style.color = '#FF5757';
                if (!isEmpty(stopWatchRef.current)) stopWatchRef.current.src = "/images/icons/stopwatch-red.png";
                // stopWatchRef.current.style.width = "17px";
                // stopWatchRef.current.style.height = "16px";
            }
            if (time === 1) {
                if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.src = "/audio/tiki-monkey-success-scream.mp3";
            }
            if (time <= -1) {
                // Time zero
                pauseMonkeyTranslation();
                if (!isEmpty(timeoutLottieBoxRef.current)) timeoutLottieBoxRef.current.style.opacity = 1;
                if (!isEmpty(timeoutLottieRef.current)) timeoutLottieRef.current.play();
                checkPlayState();
                clearInterval(intervalRef.current);
            }
        }, 1000);
    }

    /*
        handles all the asset animation
    */
    const startMonkeyTranslation = async () => {
        if (isGamePausedRef.current) return;
        isAnswerSelectedRef.current = true;
        destroyLottieAnimation(monkeyLottiesRef.current);
        monkeyLottiesRef.current = [];
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

        let pathAnimation = animationTime * 1.5;
        let delay = initialDelay + pathAnimation;

        await sleep(1000);
        questionData[activeindex].answers.forEach((item, index) => {
            // animate path
            let pathId = `option-path-box-${index}-${item.id}`;
            let pathEl = document.getElementById(pathId);
            if (!isEmpty(pathEl)) {
                // pathEl.style.animationDuration = pathAnimation + 'ms';
                // if (index % 2 === 0) {
                //     pathEl.classList.add(classes.pathBoxAnimation1);
                // } else {
                //     pathEl.classList.add(classes.pathBoxAnimation2);
                // }

                pathEl.style.transition = `transform ${pathAnimation}ms ease-in-out`;
                pathEl.style.transform = `translateX(0%)`;
            }

            // animate monkey and banana (fade in)
            let movingAssestId = `moving-asset-box-${index}-${item.id}`;
            let movingAssestEl = document.getElementById(movingAssestId);
            if (!isEmpty(movingAssestEl)) {

                movingAssestEl.style.transition = 'opacity 1s 3s ease';
                movingAssestEl.style.opacity = '1';
                // movingAssestEl.classList.add(classes.movingAssetBoxAnimation);
            }
        });

        addMonkeyLottie(GAME_ASSETS[randomAsset].monkeyWalk2, false, true, true);
        setTimeout(() => {
            if (activeindex < questionData.length - 1 && activeindex !== 0) {
                translateBackroundImage();
            }
        }, animationTime);

        await sleep(delay);
        if (activeindex > 0 && !isEmpty(questionRef.current)) {
            questionRef.current.innerHTML = questionData[activeindex].question;
        }

        // wait until the assets appear
        await sleep(delay);
        let containerWidth = gameContainerRef?.current?.offsetWidth;

        // start the timer
        startTimer(timePerQuestion);
        await sleep(animationTime);

        // translate the monkies
        questionData[activeindex].answers.forEach((item, index) => {
            // let fillMode = item.id.toString() === questionData[activeindex].answers.toString() ? 'backwards' : 'forwards';
            let monkeyId = `monkey-${index}-${item.id}`;
            if (isEmpty(monkeyId)) return;
            let el = document.getElementById(monkeyId);
            if (isEmpty(el)) return
            animateMonkey.push(el.animate([
                { transform: 'translateX(0px)' },
                // { transform: `translateX(${containerWidth - (widthMonkey + widthBanana)}px)` }
            ], {
                duration: (timePerQuestion) * 1000,
                iterations: 1,
                fill: 'forwards',
                // endDelay: 1000
                // fill: item.id.toString() === questionData[activeindex].answers.toString() ? 'backwards' : '',
            }));

            animateMonkeyRef.current = animateMonkey;
            monkeyLottiesRef.current[index].play();
        });
        if (isGamePausedRef.current) {
            handleBackButtonClick();
        }
        animationRunningRef.current = false;
        isAnswerSelectedRef.current = false;
    }

    const checkPlayState = () => {
        if (isGamePausedRef.current) return
        let lifeCount = parseInt(lifeRef.current.innerHTML);
        animateMonkeyRef.current.every(data => {
            if (data.playState === 'paused') {
                isAnswerSelectedRef.current = true;
                if (lifeCount - 1 === 0) {
                    // GAME OVER
                    pauseLottieAnimation(monkeyLottiesRef.current);
                    addLaughingMonkeyAnimation();
                    setTimeout(() => {
                        handleGbaState(GBA_SCREEN_STATE.LOOSE);
                        submitEndChallengeAttempt({
                            ...finalChallengeDataRef.current,
                            attemptId: attemptData.id,
                            scored: Math.ceil(totalScoreRef.current),
                            completion: false,
                            status: constants.GBA_ILA_STATUS.lifeLose,
                            timespent: finalChallengeDataRef.current.timespent + (timePerQuestion - Number(timerRef.current.innerHTML))
                        })
                        setGameEndType('loose');
                        setIsGameComplete(true);
                    }, 3000);
                }
                else {
                    handleLifeLoss();
                    let tempReq = {
                        qId: questionData[activeindex].id,
                        aId: null,
                        isCorrect: false,
                        t: timePerQuestion - parseInt(timerRef.current.innerHTML),
                        skillId: questionData[activeindex].skillId,
                        contextId: questionData[activeindex].contextId,
                    };
                    handleChallengeAttemptData(tempReq);
                    pauseLottieAnimation(monkeyLottiesRef.current);
                    setResetQstnBoxShadow(true);
                    // addLaughingMonkeyAnimation();
                    handleProgressBar();
                    setTimeout(() => {
                        resetAllAnimation();
                    }, 1500);
                }
            }
        });
    }

    const handleLifeLoss = () => {
        let lifeCount = parseInt(lifeRef.current.innerHTML);

        if (lifeCount - 1 === 1) {
            // do last life transition
            incorrectGradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            heartRef.current.src = "/images/icons/heart-red.png";
            divLifeRef.current.style.color = "#FF5757";
            lifeTextRef.current.classList.add(classes.incorrectGradientOverlayAnimationInfinite);
            setResetQstnBoxShadow(true);
        }
        if (lifeCount - 1 === 0) {
            // GAME OVER
            setTimeout(async () => {
                handleGbaState(GBA_SCREEN_STATE.LOOSE);
                const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    attemptId: attemptData.id,
                    scored: Math.ceil(totalScoreRef.current),
                    completion: false, status: "LIFELOSE",
                    timespent: finalChallengeDataRef.current.timespent
                    // timespent: finalChallengeDataRef.current.timespent + (timePerQuestion - Number(timerRef.current.innerHTML))
                    // timespent: totalGameTimeRef.current
                });
                setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
                setGameEndType('loose');
                setIsGameComplete(true);
            }, 4000);
        } else {
            lifeRef.current.innerHTML = lifeCount - 1;
        }
    }

    const setResetQstnBoxShadow = (set = true) => {
        if (isEmpty(qstnBoxRef.current)) return;
        if (set) {
            qstnBoxRef.current.style.boxShadow = '0px 0px 7px 4px #c51414bd';
        } else {
            qstnBoxRef.current.style.boxShadow = '0px 10px 20px #0000004D';
        }
    }

    useEffect(() => {
        if (activeindex > 0) {
            setTimeout(() => {
                if (!isEmpty(questionRef.current)) questionRef.current.style.scale = '1';
            }, [1000])
        }
    }, [activeindex])

    const handleOptionClick = (index, id) => {
        isAnswerSelectedRef.current = true;
        if (animationRunningRef.current || parseInt(timerRef.current.innerHTML) === 0) return;
        handleProgressBar();
        handleTimerPause();
        let time = timePerQuestion - parseInt(timerRef.current.innerHTML);
        let answerObj = questionData[activeindex].answers.find(d => d.isCorrect);
        let tempReq = {
            qId: questionData[activeindex].id,
            aId: answerObj.id,
            isCorrect: id.toString() === answerObj.id,
            t: timePerQuestion - parseInt(timerRef.current.innerHTML),
            skillId: questionData[activeindex].skillId,
            contextId: questionData[activeindex].contextId,
        };

        if (id.toString() === answerObj.id) {
            handleChallengeAttemptData(tempReq);
            correctAnswerAnimation(index, id);
            totalScoreRef.current += marksPerQuestion;
        } else {
            handleChallengeAttemptData(tempReq);
            wrongAnswerAnimation(index, id);
            handleLifeLoss();
        }
        animationRunningRef.current = true;
    }

    const correctAnswerAnimation = async (index, id) => {

        if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.src = "/audio/tiki-monkey-sad-whimper-full.mp3";
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        let optionPathId = `option-path-box-${index}-${id}`;
        let optionPathEl = document.getElementById(optionPathId);
        let backgroundImageProperty = optionPathEl.style.backgroundImage;
        optionPathEl.style.backgroundImage = `${correctOptionGradient} , ${backgroundImageProperty}`;
        optionPathEl.style.boxShadow = `${correctOptionShadow}`;

        // add scared monkey lottie
        // destroyLottieAnimation(monkeyLottiesRef.current);
        // monkeyLottiesRef.current = [];

        // addMonkeyLottie(GAME_ASSETS[randomAsset].monkeyScared, true, true, false);

        // revers running monkey
        animateMonkeyRef.current.forEach(data => {
            data.playbackRate = 5;
            data.reverse();
        });
        pauseLottieAnimation(monkeyLottiesRef.current);

        // await sleep(animationTime);
        // wrong lottie
        addCorrectIncorrectTextLottie(index, id, correctAnswer);

        setTimeout(() => {
            let elId = `option-set-box-${index}-${id}`;
            let el = document.getElementById(elId);
            el.style.transition = '3s';
            el.style.transform = 'scale(0.2) rotateZ(720deg)';
            el.style.opacity = '0';
        }, 10);

        // moving to next question
        await sleep(3000);
        resetAllAnimation();
    }

    const wrongAnswerAnimation = async (index, id) => {
        if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.src = "/audio/tiki-monkey-success-scream.mp3";
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        setResetQstnBoxShadow(true);
        let optionPathId = `option-path-box-${index}-${id}`;
        let optionPathEl = document.getElementById(optionPathId);
        let backgroundImageProperty = optionPathEl.style.backgroundImage;
        optionPathEl.style.backgroundImage = `${inCorrectOptionGradient}, ${backgroundImageProperty}`;
        optionPathEl.style.boxShadow = `${correctOptionShadow}`;
        incorrectGradientOverlayRef.current.classList.add(classes.incorrectGradientOverlayAnimation);

        // just checking with overriding some refs
        addLaughingMonkeyAnimation();
        // monkeyLottiesRef.current = [];
        animateMonkeyRef.current = [];
        let containerWidth = gameContainerRef?.current?.offsetWidth;

        questionData[activeindex].answers.forEach((item, index) => {
            // let fillMode = item.id.toString() === questionData[activeindex].answers.toString() ? 'backwards' : 'forwards';
            let monkeyId = `monkey-${index}-${item.id}`;
            if (isEmpty(monkeyId)) return;
            let el = document.getElementById(monkeyId);
            el.style.height = '55px';
            if (isEmpty(el)) return
            animateMonkey.push(el.animate([
                { transform: `translateX(${containerWidth / timePerQuestion * (timePerQuestion - parseInt(timerRef.current.innerHTML))}px)` },
                { transform: `translateX(${containerWidth - (3 * (widthMonkey / 4) + widthBanana)}px)` }
                // { transform: `translateX(${containerWidth - (widthMonkey + widthBanana)}px)` }
            ], {
                duration: (timePerQuestion) * 1000,
                iterations: 1,
                fill: 'forwards',
                // animationData: GAME_ASSETS[randomAsset].monkeyLaugh,
                // endDelay: 1000
                // fill: item.id.toString() === questionData[activeindex].answers.toString() ? 'backwards' : '',
            }));

            animateMonkeyRef.current = animateMonkey;
            monkeyLottiesRef.current[index].play();
        });


        // running monkey
        animateMonkeyRef.current.map(data => {
            data.playbackRate = 10;
            return data
        });

        await sleep(animationTime);
        // wrong lottie
        addCorrectIncorrectTextLottie(index, id, wrongAnswer);

        let clearIntervalFlag = false;
        let delay = timePerQuestion * 1000;
        animationRef2.current = setInterval(() => {
            animateMonkeyRef.current.forEach(data => {
                if (data.playState === 'finished') {
                    pauseLottieAnimation(monkeyLottiesRef.current);
                    // addLaughingMonkeyAnimation();
                    clearIntervalFlag = true;
                }
            });
            if (clearIntervalFlag) {
                clearInterval(animationRef2.current);
            }
        }, 200);

        // moving to next question
        await sleep(3000);
        resetAllAnimation();
    }

    const resetAllAnimation = async () => {
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

        if (!isEmpty(timerRef.current)) {
            timerRef.current.innerHTML = timePerQuestion;
            let lifeCount = parseInt(lifeRef.current.innerHTML);
            if (lifeCount === 1) {
                // do last life transition
                setResetQstnBoxShadow(true);
            } else {
                setResetQstnBoxShadow(false);
            }
        }

        if (!isEmpty(timeoutLottieBoxRef.current)) timeoutLottieBoxRef.current.style.opacity = 0;
        if (!isEmpty(timeoutLottieRef.current)) timeoutLottieRef.current.pause();

        if (!isEmpty(divTimerRef.current)) divTimerRef.current.style.color = '#fff';
        if (!isEmpty(stopWatchRef.current)) stopWatchRef.current.src = "/images/icons/stopwatch.png";

        if (!isEmpty(incorrectGradientOverlayRef.current)) incorrectGradientOverlayRef.current.classList.remove(classes.incorrectGradientOverlayAnimation);
        let pathAnimation = animationTime * 1.5;

        // reset audio src after wrong/correct answer
        if (!isEmpty(natureAudioRef.current)) natureAudioRef.current.src = "/audio/TIKI_MONKEY_ATMO_FOREST_LOOP.mp3";

        let delay = initialDelay + pathAnimation;
        questionData[activeindex].answers.forEach((item, index) => {

            // animate path
            let pathId = `option-path-box-${index}-${item.id}`;
            let pathEl = document.getElementById(pathId);

            if (!isEmpty(pathEl)) {

                pathEl.style.transition = `none`;
                if (index % 2 === 0) {
                    pathEl.style.transform = 'translateX(100%)'
                    // pathEl.classList.remove(classes.pathBoxAnimation1);
                } else {
                    pathEl.style.transform = 'translateX(-100%)'
                    // pathEl.classList.remove(classes.pathBoxAnimation2);
                }
            }

            // animate monkey and banana (fade in)
            let movingAssestId = `moving-asset-box-${index}-${item.id}`;
            let movingAssestEl = document.getElementById(movingAssestId);
            if (!isEmpty(movingAssestEl)) {
                movingAssestEl.style.transition = 'none';
                movingAssestEl.style.opacity = '0';
                // movingAssestEl.classList.remove(classes.movingAssetBoxAnimation);
                // movingAssestEl.style.transition = `all 0s 0s`;
                // movingAssestEl.style.opacity = '0';
            }

            // rotation
            let elId = `option-set-box-${index}-${item.id}`;
            let el = document.getElementById(elId);
            if (!isEmpty(el)) {
                el.style.transition = '0s';
                el.style.transform = 'scale(1) rotateZ(0deg)';
                el.style.opacity = '1';
            }

            // shadow
            let optionPathId = `option-path-box-${index}-${item.id}`;
            let optionPathEl = document.getElementById(optionPathId);
            if (!isEmpty(optionPathEl)) {
                optionPathEl.style.backgroundImage = `url(/images/gba/tiki-monkey/path-new.png)`;
                optionPathEl.style.boxShadow = `none`;
            }
        });
        if (!isEmpty(questionRef.current)) questionRef.current.style.scale = '0';

        // reset monkey position
        animateMonkeyRef.current.forEach(item => {
            item.cancel();
        });

        // reset text lottie
        if (!isEmpty(textLottie)) textLottie.destroy();
        await sleep(2000);
        if (gameEndType === 'loose') return
        // let lifeCount = parseInt(lifeRef.current.innerHTML);
        if (activeindex + 1 === questionData.length) {
            if (!isEmpty(lifeRef.current) && parseInt(lifeRef.current.innerHTML) !== 0) {
                gameCompleteAction()
            }
        } else {
            setActiveindex(activeindex + 1);
        }
    }

    const handleProgressBar = () => {
        //
        let progressBarWidth = ((activeindex + 1) / questionData.length) * 100;
        if (!isEmpty(progressBarRef.current)) {
            progressBarRef.current.style.width = progressBarWidth + "%";
        }
    }

    const translateBackroundImage = () => {
        if (isEmpty(gameContainerRef.current)) return;
        let styleValue = gameContainerRef.current.style.backgroundPosition;
        if (isEmpty(styleValue)) styleValue = '0% center';
        let perValue = parseFloat(styleValue.split('%')[0]);
        let translationPercentage = perValue + (450 / questionData.length);
        gameContainerRef.current.style.backgroundPosition = translationPercentage + "%";
    }

    const addCorrectIncorrectTextLottie = (index, id, lottieFile) => {
        let lottieAnswerId = `answer-lottie-box-${index}-${id}`;
        let lottieAnswerEL = document.getElementById(lottieAnswerId);
        textLottie = lottie.loadAnimation({
            container: lottieAnswerEL,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: lottieFile,
            speed: 1
        });
    }

    const addMonkeyLottie = (lottieFile, autoplay = true, loop = true, isStarting = true) => {
        questionData[activeindex].answers.forEach((item, index) => {
            let monkeyId = `monkey-${index}-${item.id}`;
            let monkeyEl = document.getElementById(monkeyId);
            if (!isStarting) {
                monkeyEl.style.width = widthMonkey + 'px';
            }
            monkeyLottiesRef.current.push(lottie.loadAnimation({
                container: monkeyEl,
                renderer: "svg",
                loop: loop,
                autoplay: autoplay,
                animationData: lottieFile,
                speed: 1,
            }));
        });
    }

    const pauseMonkeyTranslation = () => {
        // if (isGamePausedRef.current) return

        animateMonkeyRef.current.forEach(item => {
            item.pause();
        })

    }

    const resumeMonkeyTranslation = () => {
        if (isGamePausedRef.current) return
        animateMonkeyRef.current.forEach(item => {
            item.play();
        })

    }

    const pauseLottieAnimation = (lottieArray, index) => {
        if (isEmpty(lottieArray)) return
        if (!isEmpty(index)) {
            lottieArray[index].pause();
            return
        }
        lottieArray.forEach((item, index) => {
            // destroy lottie
            item.pause();
        });
    }

    const resumeLottieAnimation = (lottieArray, index) => {
        if (isEmpty(lottieArray)) return
        if (!isEmpty(index)) {
            lottieArray[index].play();
            return
        }
        lottieArray.forEach((item, index) => {
            item.play();
        });
    }

    const addLaughingMonkeyAnimation = () => {
        destroyLottieAnimation(monkeyLottiesRef.current);
        monkeyLottiesRef.current = [];
        questionData[activeindex].answers.forEach((item, index) => {
            // animate laughing monkey lottie
            let monkeyId = `monkey-${index}-${item.id}`;
            let monkeyEl = document.getElementById(monkeyId);
            monkeyEl.style.width = widthMonkey + 'px';
            monkeyLottiesRef.current.push(lottie.loadAnimation({
                container: monkeyEl,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: GAME_ASSETS[randomAsset].monkeyLaugh,
                speed: 10
                // speed: 1
            }));
        });
    }

    const destroyLottieAnimation = (lottieArray, index) => {
        if (isEmpty(lottieArray)) return
        if (!isEmpty(index)) {
            lottieArray[index].destroy();
            return
        }
        lottieArray.forEach((item, index) => {
            // destroy lottie
            item.destroy();
        });
    }

    useEffect(() => {
        if (activeindex > 0 && gameEndType !== 'loose') {
            startMonkeyTranslation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeindex]);

    const resumeAllAnimation = () => {
        isGamePausedRef.current = false;
        resumeMonkeyTranslation();
        resumeLottieAnimation(monkeyLottiesRef.current);
        let pathAnimation = animationTime * 1.5;
        questionData[activeindex].answers.forEach((item, index) => {
            // animate path
            let pathId = `option-path-box-${index}-${item.id}`;
            let pathEl = document.getElementById(pathId);
            if (!isEmpty(pathEl)) {
                pathEl.style.transition = `transform ${pathAnimation}ms ease-in-out`;
                pathEl.style.transform = 'translateX(0%)';

                // if (index % 2 === 0) {
                //     pathEl.classList.add(classes.pathBoxAnimation1);
                // } else {
                //     pathEl.classList.add(classes.pathBoxAnimation2);
                // }
            }

            // animate monkey and banana (fade in)
            let movingAssestId = `moving-asset-box-${index}-${item.id}`;
            let movingAssestEl = document.getElementById(movingAssestId);
            if (!isEmpty(movingAssestEl)) {
                // movingAssestEl.classList.add(classes.movingAssetBoxAnimation);
                movingAssestEl.style.transition = 'opacity 1s 3s ease';
                movingAssestEl.style.opacity = '1';
            }
        });
    }

    const handleTimerPause = () => {
        if (isEmpty(timerRef.current)) return
        clearInterval(intervalRef.current);
    }

    const handleTimerResume = () => {
        if (isEmpty(timerRef.current)) return
        totalTimeIntervalRef.current = setInterval(() => {
            totalGameTimeRef.current += 1;
            setAddPause(true);
        }, 1000);
        startTimer(parseInt(timerRef.current.innerHTML) - 1);
    }


    const handleBackButtonClick = () => {
        isGamePausedRef.current = true;
        const currentTime = new Date();
        // const time = (totalTimeSpentRef.current === null) ? 0 : Math.floor((currentTime - totalTimeSpentRef.current) / 1000);
        let t = Math.round((currentTime - gameStartTimeRef.current) / 1000);
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            timespent: t
        }));
        handleTimerPause();
        pauseAllAnimation();
        // if (!isEmpty(gbaPauseOverlayRef.current)) gbaPauseOverlayRef.current.style.display = 'flex';
    }

    const pauseAllAnimation = () => {
        isGamePausedRef.current = true;
        pauseMonkeyTranslation();
        pauseLottieAnimation(monkeyLottiesRef.current);
    }

    const handleResumeClick = () => {
        handleTimerResume();
        resumeAllAnimation();

    }

    const restartGame = () => {
        setIsGameComplete(true);
        setIsGamePage(false);
        clearInterval(intervalRef.current);
        setGameEndType();
        setActiveindex(0);
        totalScoreRef.current = 0
        setComponentKey(new Date().getTime());
        getChallengeDataAsync();
        gameStartTimeRef.current = Date.now();
        finalChallengeDataRef.current = null;
        // resetValues();
        setTimeout(() => {
            setIsGameComplete(false);
            setIsGamePage(true);
            clearInterval(intervalRef.current);
        }, 50)
    }

    const gameCompleteAction = () => {
        handleGbaState(GBA_SCREEN_STATE.WIN);
        setGameEndType('win');
        setIsGameComplete(true);
        submitEndChallengeAttempt({
            ...finalChallengeDataRef.current,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.completed,
            timespent: finalChallengeDataRef.current.timespent + (timePerQuestion - Number(timerRef.current.innerHTML))
        })
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        // Create a new object based on the existing questionObj with added skillId and contextId
        const tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        // Calculate total time spent
        const previousTimeSpent = isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent;
        totalTimeSpentRef.current = previousTimeSpent + tempQuestionObj.timespent;

        if (isEmpty(finalChallengeDataRef.current)) {
            // Initialize finalChallengeDataRef.current with a new object
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpentRef.current,
                scored: 0,
                fallbackSave: true,
            };
        } else {
            // Create a new questions array with the additional question
            finalChallengeDataRef.current = {
                ...finalChallengeDataRef.current,
                questions: [...finalChallengeDataRef.current.questions, tempQuestionObj],
                timespent: totalTimeSpentRef.current,
            };
        }
    };

    const handleAudioLoad = (e) => {
        natureAudioRef.current.play();
    }

    const handleAudioMutelick = () => {
        // natureAudioRef.current.volume = 0;
        // natureAudioRef.current.muted = true;
        setIsAudioPlaying(prev => {
            if (prev && !isEmpty(natureAudioRef.current)) natureAudioRef.current.muted = true;
            else natureAudioRef.current.muted = false;
            return !prev
        })

    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={classes.tikiMonkeyRoot} key={componentKey}>
            {
                !isGamePage ? (
                    !isHowToPlayScreen ? (
                        <>
                            <StartScreen
                                setIsGamePage={setIsGamePage}
                                setIsHowToPlayScreen={setIsHowToPlayScreen}
                                // gradientBg={bgGradientDefault}
                                // bg={"/images/gba/tiki-monkey/tiki_monkey_start_screen_bg-new.svg"}
                                // bg={"/images/gba/tiki-monkey/dual_bg.jpg"}
                                bg={"/images/gba/tiki-monkey/Tiki_Monkeys_startscreen.jpg"}

                                logoWidth={'80%'}
                                gameLogo={"/images/gba/tiki-monkey/tiki_monkey_logo-new.svg"}
                                handleGbaState={handleGbaState}

                                startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                startScreenGameBgLoaded={startScreenGameBgLoaded}
                                setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                            />
                        </>
                    ) : (
                        <HowToPlay
                            title={gameName}
                            headerBg={headerBg}
                            qstnBoxBg={'#000000'}
                            asset1Img={'/spaceRocks/rock.png'}
                            asset2Img={'/spaceRocks/rock.png'}
                            headerColor='#a34735'
                            setIsGamePage={setIsGamePage}
                            gameType='spaceRocks'
                        />
                    )
                ) : (
                    !isGameComplete && isEmpty(gameEndType) ? (
                        <>
                            <div className={classes.gameContainer} ref={gameContainerRef} id="tiki-monkey-game-container">

                                <div className={classes.incorrectGradientOverlay} ref={incorrectGradientOverlayRef} />

                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} style={{ width: 0 }} />
                                </div>
                                {/* QUESTION BOX */}
                                <div className={classes.qstnContainer}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeTextRef}>
                                                <div className={classes.life} ref={divLifeRef}><span ref={lifeRef}>{maxLife}</span> x </div>
                                                <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} ref={heartRef} alt="heart" />
                                            </div>
                                            <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeindex + 1}</span>/{questionData.length}</div>
                                            <div className={classes.timerText}>
                                                <img src='/images/icons/stopwatch.png' ref={stopWatchRef} className={`disableSelection disablePointerEvents ${classes.timerIcon}`} alt="timer" />
                                                <div ref={divTimerRef} className={classes.time}><b><span ref={timerRef}>{timePerQuestion}</span>s</b></div>
                                            </div>
                                        </div>
                                        <div className={classes.qstn} ref={questionRef}>
                                            {questionData[activeindex].question}
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.gamePlayWrapper}>
                                    <audio
                                        ref={natureAudioRef}
                                        src="/audio/TIKI_MONKEY_ATMO_FOREST_LOOP.mp3"
                                        preload="metadata"
                                        onLoadedMetadata={handleAudioLoad}
                                        onEnded={(e) => {
                                            e.target.play();
                                        }}
                                    ></audio>

                                    <div className={`disableSelection ${classes.muteSwitch}`} onClick={handleAudioMutelick}>
                                        {isAudioPlaying ? (
                                            <img src="/images/gba/learno_casino_sound.png" alt="icon" width={30} className={`disableSelection`} />
                                        ) : (
                                            <img src="/images/gba/learno_casino_mute.png" alt="icon" width={30} className={`disableSelection`} />
                                        )}
                                    </div>

                                    <div className={classes.timeoutLottieBox} ref={timeoutLottieBoxRef}>
                                        <Lottie
                                            ref={timeoutLottieRef}
                                            play={false}
                                            loop={false}
                                            animationData={timeoutLottie}
                                            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                                        />
                                    </div>

                                    <div className={classes.gameplayContainer}>
                                        {
                                            questionData[activeindex].answers.map((item, index) => (
                                                <div style={{ position: 'relative' }} key={index}
                                                    className="tikiMonkeyOptionSetBox">
                                                    <div className={`${classes.optionSetBox}`}
                                                        key={item.id} id={`option-set-box-${index}-${item.id}`}>

                                                        <div className={`${classes.movingAssetBox} tikiMonkeyMovingAssetBox`}
                                                            id={`moving-asset-box-${index}-${item.id}`}>

                                                            <div className={`${classes.monkeyAssetBox}`}
                                                                style={{ width: `100%` }}
                                                                // style={{ width: `${widthMonkey}px` }}
                                                                id={`monkey-${index}-${item.id}`}>
                                                            </div>
                                                            <div className={classes.bananaImgWrapper} style={{
                                                                width: `${widthBanana}px`,
                                                            }} id={`banana-${index}-${item.id}`}>
                                                                <img src={GAME_ASSETS[randomAsset].bananasImg} alt="path" className={`disableSelection ${classes.bananaImg}`} />
                                                            </div>
                                                        </div>
                                                        <div className={`${classes.pathBox} tikiMonkeyPathtBox`}
                                                            onClick={() => handleOptionClick(index, item.id)}
                                                            id={`option-path-box-${index}-${item.id}`}
                                                            style={{
                                                                backgroundImage: GAME_ASSETS[randomAsset].pathBgImg,

                                                                transform: index % 2 === 0 ? 'translateX(100%)' : 'translateX(-100%)'
                                                            }}
                                                        >
                                                            <div className={classes.option}>{item.answer}</div>
                                                        </div>
                                                    </div>

                                                    <div className={`${classes.answerLottieBox}`}
                                                        id={`answer-lottie-box-${index}-${item.id}`}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                gameEndType === 'win' && (
                                    <GbaWinScreen
                                        maxScore={mxmScore}
                                        bg={""}
                                        obtainedScore={Math.ceil(totalScoreRef.current)} />
                                )
                            }
                            {
                                gameEndType === 'loose' && (
                                    <GbaGameOverScreen
                                        type={"gameover"}
                                        gameType={gameData?.gameType}
                                        gameData={gameData}
                                        incorrectAttempt={incorrectData}
                                        bg={""}
                                        bgGradient={incorectBgGradient}
                                        restartGame={restartGame} />
                                )
                            }
                        </>
                    )
                )
            }
        </div>
    )
}

export default TikiMonkey;