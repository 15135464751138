import React, { useEffect, useRef, useState } from 'react';

// lotties : 
import Lottie from 'react-lottie-player';
import BOTTOM_NAVIGATION from '../../../lottie-assets/ques-container/bottom-navigation.json';

// mui
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// utils
import isEmpty from '../../../utils/isEmpty';

// constants
import constants from '../../../constants';

// styles
import useStyle from './index.styles';
import { Button } from '@mui/material';

const QuestionContainer = (props) => {
    const {
        questionRef,
        contextRef,
        lifeTextRef,
        lifeRef,
        life,
        heartRef,
        questionNoRef,
        activeIndex,
        totalQuestionLength,
        audioClicked,
        isAudioPlaying,
        gameData,
        questionContainerRef,
        isTime = false,
        stopWatchRef,
        divTimerRef,
        timerRef,
        timePerQuestion,
        handlePause,
        handleResume,
        isPauseOverlay,
        isQuestionChanging,
    } = props;

    const classes = useStyle();

    // State to track the expanded state of the container :
    const [isExpanded, setIsExpanded] = useState(false);
    const [isBottomNavigationVisible, setIsBottomNavigationVisible] = useState(false);

    // refs : 
    const bottomNavigationRef = useRef(null);
    const qstnWrapperRef = useRef(null);

    const _handleBottomNavigationClick = (action) => {
        const isExpanding = action === 'expand';

        isExpanding ? handlePause() : handleResume();
        setIsExpanded(isExpanding);
    }


    useEffect(() => {
        if (isExpanded && isPauseOverlay) {
            setIsExpanded(false);
        }
    }, [isPauseOverlay, isExpanded])

    useEffect(() => {

        const contextCharLength = contextRef.current?.innerHTML.length;
        const quesCharLength = questionRef.current?.innerHTML.length;

        if (contextCharLength > 90 || quesCharLength > 90) {
            setIsBottomNavigationVisible(true);
        } else {
            setIsBottomNavigationVisible(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, contextRef.current, questionRef.current, isQuestionChanging]);

    return (
        <div
            ref={questionContainerRef}
            style={{
                height: isExpanded ? `calc(${window.innerHeight}px - ${constants.HEADER_HEIGHT_MOBILE})` : '100%', zIndex: '6'
            }}
            className={classes.qstnContainer}
        >
            <div className={classes.qstnBox}
                style={{
                    gridTemplateRows: isExpanded ? '6% 84% 10%' : '25% 50% 25%',
                    background: isExpanded ? 'linear-gradient(rgba(0, 0, 0, 0.8) 65%, rgba(0, 0, 0, 1))' : '',
                    opacity: !isExpanded ? '0.9' : '',
                }}
            >
                <div className={classes.assetBox}>
                    <div className={classes.lifeText} ref={lifeTextRef}>
                        <div className={classes.life} ref={lifeRef}>
                            {life}
                        </div>
                        &nbsp;<b>x</b>
                        <img
                            src="/images/icons/heart.png"
                            className={`disableSelection disablePointerEvents ${classes.icon}`}
                            ref={heartRef}
                            alt="heart"
                        />
                    </div>

                    <div className={classes.questionNo}>
                        Q
                        <span ref={questionNoRef}>
                            {activeIndex >= totalQuestionLength
                                ? totalQuestionLength
                                : activeIndex + 1}
                        </span>
                        /{totalQuestionLength}
                    </div>

                    {isTime && (
                        <div className={classes.timerText}>
                            <img
                                src="/images/icons/stopwatch.png"
                                ref={stopWatchRef}
                                className={`disableSelection disablePointerEvents ${classes.timerIcon}`}
                                alt="timer"
                            />
                            <div ref={divTimerRef} className={classes.time}>
                                <b>
                                    <span ref={timerRef}>{timePerQuestion}</span>s
                                </b>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={!isExpanded ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    } :
                        {
                            margin: '3rem 0 1.2rem 0',
                            overflowY: 'scroll',
                        }}
                    className={`${isExpanded ? "scrollbar" : ''}`}
                    ref={qstnWrapperRef}
                >
                    {/* Context Element */}

                    <div className={classes.contextWrapper}>
                        <div
                            className={`${classes.context} ${!isExpanded ? classes.lineClampingContext : ''}`}
                            ref={contextRef}
                            style={{ marginBottom: isExpanded ? '1em' : '' }}
                        >
                        </div>

                    </div>

                    {/* Question Element */}

                    <div className={classes.questContainer}>
                        <div
                            className={`${classes.qstn} ${!isExpanded ? classes.lineClampingQuestion : ''}`}
                            ref={questionRef}
                            id="contextBox"
                            style={
                                isEmpty(contextRef.current?.innerHTML)
                                    ? {
                                        '-webkit-line-clamp': '4 !important',
                                        'line-clamp': '4 !important',
                                    }
                                    : {}
                            }
                        >
                        </div>
                    </div>

                </div>

                {isBottomNavigationVisible && <div className={`${classes.navigation} ${classes.centerFlex}`}>
                    {isExpanded ? (
                        <Button
                            className={classes.hideBtn}
                            onClick={() => { _handleBottomNavigationClick('hide') }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                backgroundColor: '#fff',
                                color: '#000',
                                width: 'fit-content',
                                padding: '0.25rem 1rem',
                                margin: '0 0 3rem 0',
                            }}
                        >
                            <span className={classes.hideBtnText}>Hide</span>
                            <ExpandLessIcon sx={(theme) => ({
                                color: `${theme.palette.black}`,
                                fontSize: '1.5rem',
                                fontWeight: '600'
                            })} />
                        </Button>
                    ) : (
                        <div
                            className={classes.bottomNavigationBtn}
                            onClick={() => { _handleBottomNavigationClick('expand') }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                cursor: 'pointer',
                            }}
                        >
                            <Lottie
                                loop={true}
                                animationData={BOTTOM_NAVIGATION}
                                play={true}
                                ref={bottomNavigationRef}
                                style={{
                                    height: '90%',
                                    objectFit: 'contain',
                                    aspectRatio: '1/1',
                                }}
                            />

                            <Lottie
                                loop={true}
                                animationData={BOTTOM_NAVIGATION}
                                play={true}
                                ref={bottomNavigationRef}
                                style={{
                                    height: '90%',
                                    objectFit: 'contain',
                                    aspectRatio: '1/1',
                                }}
                            />
                        </div>
                    )}
                </div>}

                <div
                    className={classes.muteSwitch}
                    onClick={audioClicked}
                    style={{
                        display: isAudioPlaying ? 'none' : 'flex',
                        top: isExpanded ? constants.HEADER_HEIGHT_MOBILE : '100%',
                    }}
                >
                    {!isAudioPlaying && !isEmpty(gameData?.questions[activeIndex]?.speechUrl) && (
                        <button className={classes.playButton}>
                            <div className={classes.playIcon}></div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuestionContainer;
