import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import useStyles from "./index.styles";

const FormInfoContainer = forwardRef((props, ref) => {
    const { formName, formDescription, isAssessment, handleCloseForm, totalTime } = props;

    const { i18n } = useTranslation();
    const classes = useStyles(i18n);

    return (
        <div className={`${classes.mainContainer}`}>
            <div className={`${classes.centerFlex} ${classes.formNameDescriptionContainer}`}>
                <div className={classes.formName}>{formName}</div>

                <div className={classes.formDescription}>{formDescription}</div>
            </div>
        </div>
    );
});

export default FormInfoContainer;