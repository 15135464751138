import { makeStyles } from "@mui/styles";
import constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        position: 'relative',
        height: '200px',
        width: '95%',
        borderRadius: '0.8rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
        userSelect: 'none',
        margin: 'auto',
        borderRadius: '0.7rem',
        fontSize: (i18n) => {
            let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
            return langObj.font
        },
        [theme.breakpoints.down('sm')]: {
            height: '180px',
        },
    },
    cardGradient: {
        height: '100%',
        width: '100%',
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nameContainer: {
        height: "100%",
        width: '100%',
        fontFamily: 'Montserrat',
        color: '#fff',
        alignItems: "flex-start !important",
        flexDirection: "column"
    },
    headingConatiner: {
        height: '70%',
        width: '100%',
        flexDirection: "column",
        justifyContent: "center"
    },
    microSkillName: {
        fontFamily: 'Montserrat',
        fontSize: '1.3em',
        fontWeight: 700,
        width: '100%',
        marginBottom: "1rem",
        textOverflow: 'ellipsis',
        padding: '0 1.5rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em'
        }
    },
    aboutText: {
        padding: '0rem 1.5rem',
        fontSize: '1em',
        fontWeight: '600',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3, /* number of lines to show */
        'line-clamp': 3,
        width: '100%',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em'
        }
    },
    asessmentModel: {
        backgroundColor: "#00897B",
        background: "url(/images/forms/history.svg)",
        backgroundSize: "30%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    },
    buttonContainer: {
        height: "30%",
        width: "100%",
        paddingBottom: '1rem',
        paddingRight: '1rem',
        justifyContent: 'flex-end'
    }
}));

export default useStyles;