import React, { useState, useEffect, act } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { Document } from 'react-pdf/dist/entry.parcel';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// import Pdf, { usePdf } from "@mikecousins/react-pdf";

// Webview Javascript bridge for flutter
import webViewJavaScriptBridge from "webview-javascript-bridge";

// mui
import CircularProgress from '@mui/material/CircularProgress';
import ShareIcon from '@mui/icons-material/Share';
// import { Button } from '@mui/material';

// components
import DocViewer from "../doc-viewer";
import DocumentImageViewer from "../document-image-viewer";
import AudioPlayer from "../audio-player";
import PDFviewerSafari from "../pdf-viewer-safari";

// utils
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil";

// constants
import constants from "../../constants";

// styles
import useStyles, { HEADER2_HEIGHT_MOBILE } from "./index.styles";
import createSharingUrl from "../../utils/createSharingUrl";
import { markRepoDownloaded, markRepoShared, markRepoViewed } from "../../redux/content-repo/content-repo.api";
import useGlobalAbortController from "../../utils/abortController";
import AdobePDFViewer from "../adobe-pdf-viewer";

const IMAGE_EXTENSIONS = constants.IMAGE_EXTENSIONS
const VIDEO_EXTENSIONS = constants.VIDEO_EXTENSIONS;
const AUDIO_EXTENSIONS = constants.AUDIO_EXTENSIONS;
const PDF_EXTENSIONS = constants.PDF_EXTENSIONS;
const DOC_EXTENSIONS = constants.DOC_EXTENSIONS;
const { BOTTOM_NAV_HEIGHT } = constants

const FULL_HEIGHT_MOBILE_TEMP = {
    height: `calc(100dvh - ${HEADER2_HEIGHT_MOBILE} - ${BOTTOM_NAV_HEIGHT})`
}
const FileViewer = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        files,
        type,
        indexVal,
        from
    } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const [isShareButtonVisible, setIsShareButtonVisible] = useState(false);
    const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(false);
    const [shareBtnLoader, setShareBtnLoader] = useState(false);
    const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
    const isRotatedMobile = useSelector(state => state?.common.isRotatedMobile)
    const { setNewAbortSignal } = useGlobalAbortController();

    const isBajajICV = commonUtil.getCompanyId() === process.env.REACT_APP_BAJAJ_ICV_COMPANY_ID;
    useEffect(() => {
        if (isEmpty(files) && from !== 'ILA') {
            navigate(-1);
            return;
        }
        // markRepoViewed()
        // console.log(files, 'files')
    }, [files]);

    const handleShare = async (file) => {
        // console.log("handle share: ", file)

        if (from !== "SALES_ENABLEMENT") {
            const signal = setNewAbortSignal();
            markRepoShared(file.id, signal);
        }

        try {
            const createdUrl = createSharingUrl(file.path);


            const isNativeApp = localStorage.getItem('isNative');
            if (isNativeApp === 'true') {
                setShareBtnLoader(true);
                webViewJavaScriptBridge.sendMessage({ action: 'ShareChannel', params: `'${file.path}|${file.name}'` }, (response) => {
                    console.log('ShareChannel response:', response);
                });
            }

            // Check if the Web Share API is supported
            // console.log(navigator.share, "navigator.share")
            else if (navigator.share) {
                await navigator.share({
                    title: file.name,
                    text: file.description,
                    url: AUDIO_EXTENSIONS.includes(type) ? file.path : createdUrl, // URL to be shared
                    // url: file.path, // URL to be shared
                });
                // console.log('Successfully shared');
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    }

    const handleDownloadClick = (file) => {
        if (from === 'ILA') return

        if (from !== "GENERIC_DOC_VIEWER") {
            const signal = setNewAbortSignal();
            markRepoDownloaded(file.id, signal);
        }
        // console.log(file);
        const { path } = file;

        const isNativeApp = localStorage.getItem('isNative');
        if (isNativeApp === 'true') {
            setDownloadBtnLoader(true);
            const downloadContent = `'${path}|${file.name}'`;
            webViewJavaScriptBridge.sendMessage({ action: 'DownloadChannel', params: downloadContent }, (response) => {
                console.log('DownloadChannel response:', response);
            });
        } else {
            window.location.replace(path);
        }
    }

    const getPositionOfDownloadBtn = (from, isMobile) => {
        if (from === 'GENERIC_DOC_VIEWER') {
            return '1rem'
        }
        return isMobile ? `calc(${constants.BOTTOM_NAV_HEIGHT} + 2rem)` : `1rem`
    }

    const getPositionOfShareBtn = (from, isMobile) => {
        // isMobile ? from === 'SALES_ENABLEMENT' ? '3.8rem' : `calc(${constants.BOTTOM_NAV_HEIGHT} + 3.8rem)` : '3.8rem'
        return isMobile ? `calc(${constants.BOTTOM_NAV_HEIGHT} + 4.8rem)` : '3.8rem'
    }

    const getPositionOfShareDownloadButton = () => {
        if (PDF_EXTENSIONS.includes(type) && !isMobile) return '5rem';
        return '1.5rem';
    }

    useEffect(() => {
        let timeout;
        if (shareBtnLoader || downloadBtnLoader) {
            timeout = setTimeout(() => {
                setShareBtnLoader(false);
                setDownloadBtnLoader(false);
            }, 10 * 1000)
        }
    }, [shareBtnLoader, downloadBtnLoader])

    useEffect(() => { }, [])

    // useEffect(() => {

    //     webViewJavaScriptBridge.registerMessageHandler("pausedApp", (data) => {
    //         console.log('pausedApp', data);
    //         if (data) {
    //             setDownloadBtnLoader(false);
    //             setShareBtnLoader(false);
    //             webViewJavaScriptBridge.unregisterMessageHandler("pausedApp");
    //         }
    //     });
    // }, [shareBtnLoader, downloadBtnLoader])

    return (
        <div
            className={`${classes.fileViewerRoot} ${from === 'ILA' ? classes.documentStyle : ''}`}
            style={{
                ...(isMobile && from !== 'ILA' && from !== 'SALES_ENABLEMENT' ?
                    (from === "GENERIC_DOC_VIEWER" ? { height: `calc(100vh - ${constants.HEADER_HEIGHT_MOBILE})` } : FULL_HEIGHT_MOBILE_TEMP)
                    : { position: from === 'SALES_ENABLEMENT' ? 'relative' : '' }),
                ...(isRotatedMobile && {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                })
            }}
        >
            <>
                {
                    isEmpty(files) ? (
                        <div style={constants.FULL_HEIGHT_CENTER}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {
                                // (from === 'SALES_ENABLEMENT' || isBajajICV) && isMobile && isShareButtonVisible && (
                                from !== 'ILA' && from !== 'GENERIC_DOC_VIEWER' && isShareButtonVisible &&
                                (
                                    <div className={`${classes.downloadBox} ${classes.centerFlex}`} style={{
                                        bottom: !isDownloadButtonVisible ? getPositionOfDownloadBtn(from, isMobile) : getPositionOfShareBtn(from, isMobile),
                                        position: from === 'SALES_ENABLEMENT' ? 'absolute' : 'fixed',
                                        right: getPositionOfShareDownloadButton(),
                                    }}
                                        onClick={() => {
                                            if (shareBtnLoader) return;
                                            if (IMAGE_EXTENSIONS.includes(type) || AUDIO_EXTENSIONS.includes(type)) {
                                                // handleDownloadClick(files[0])
                                                const { Repository } = files[indexVal];
                                                handleShare(Repository)
                                            } else {
                                                handleShare(files[0])
                                            }
                                        }}
                                    >
                                        {
                                            shareBtnLoader ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <ShareIcon sx={theme => ({ fontSize: '1.5rem', color: theme.palette.orange })} />
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                // isBajajICV && isShareButtonVisible && (
                                from !== 'SALES_ENABLEMENT' && from !== 'ILA' && isDownloadButtonVisible && (
                                    // (isBajajICV || !isMobile) && from !== 'SALES_ENABLEMENT' && from !== 'ILA' && !DOC_EXTENSIONS.includes(type) && (
                                    <div className={`${classes.downloadBox} ${classes.centerFlex}`} style={{
                                        bottom: getPositionOfDownloadBtn(from, isMobile),
                                        right: getPositionOfShareDownloadButton(),
                                    }}
                                        onClick={() => {
                                            if (downloadBtnLoader) return;
                                            if (from === 'GENERIC_DOC_VIEWER') {
                                                handleDownloadClick(files[0])
                                                return
                                            }
                                            if (IMAGE_EXTENSIONS.includes(type) || AUDIO_EXTENSIONS.includes(type)) {
                                                // handleDownloadClick(files[0])
                                                const { Repository } = files[indexVal];
                                                handleDownloadClick(Repository)
                                            } else {
                                                handleDownloadClick(files[0])
                                            }
                                        }}
                                    >
                                        {
                                            downloadBtnLoader ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <img src={"/images/icons/download_orange.png"} alt="icon" />

                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                // (DOC_EXTENSIONS.includes(type) || PDF_EXTENSIONS.includes(type)) && (
                                (DOC_EXTENSIONS.includes(type)) && (
                                    <DocViewer
                                        docData={files[0]}
                                        isMobile={isMobile}
                                        from={from}
                                        setIsShareButtonVisible={setIsShareButtonVisible}
                                        setIsDownloadButtonVisible={setIsDownloadButtonVisible}
                                    />
                                )
                            }
                            {
                                PDF_EXTENSIONS.includes(type) && (
                                    <AdobePDFViewer
                                        docData={files[0]}
                                        from={from}
                                        setIsShareButtonVisible={setIsShareButtonVisible}
                                        setIsDownloadButtonVisible={setIsDownloadButtonVisible}
                                    />
                                )
                            }
                            {
                                IMAGE_EXTENSIONS.includes(type) && (
                                    <DocumentImageViewer
                                        images={files}
                                        index={indexVal}
                                        isMobile={isMobile}
                                        from={from}
                                        setIsShareButtonVisible={setIsShareButtonVisible}
                                        setIsDownloadButtonVisible={setIsDownloadButtonVisible}
                                    />
                                )
                            }
                            {
                                AUDIO_EXTENSIONS.includes(type) && (
                                    !isEmpty(files) && (
                                        <AudioPlayer
                                            tracks={files}
                                            index={indexVal}
                                            from={from}
                                            setIsShareButtonVisible={setIsShareButtonVisible}
                                            setIsDownloadButtonVisible={setIsDownloadButtonVisible}
                                        />
                                    )
                                )
                            }
                            {

                                VIDEO_EXTENSIONS.includes(type) && (
                                    // video controls are not visible in desktop
                                    <div
                                        className={`${classes.videoBox} ${classes.centerFlex}`}
                                        style={{
                                            ...(from !== 'ILA' && !isMobile && { ...constants.FULL_HEIGHT_CENTER_MOBILE }),
                                            ...(isRotatedMobile && {
                                                height: '100vh',
                                                width: '100vw',
                                                transform: 'rotate(90deg)',
                                            }),
                                        }}
                                    >
                                        {/* // video was playing in a little height only  */}
                                        <video
                                            preload="metadata"
                                            width="100%"
                                            style={{ height: "auto", maxHeight: '100%' }}
                                            controls
                                            playsInline
                                            controlsList="nodownload"
                                            onLoadedMetadata={() => {
                                                setIsShareButtonVisible(files[0].enableFileShare ?? true);
                                                setIsDownloadButtonVisible(files[0].enableFileDownload ?? true);

                                                if (from === 'SALES_ENABLEMENT' || from === 'GENERIC_DOC_VIEWER' || from === "ILA") return;
                                                const signal = setNewAbortSignal();
                                                markRepoViewed(files[0]?.id, signal)
                                            }}
                                        >
                                            <source
                                                src={`${files[0].path}#t=0.001`}
                                            // type={type}
                                            />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </>

        </div>
    )
}

export default FileViewer;