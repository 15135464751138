import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RecordRTC, { MediaStreamRecorder } from "recordrtc";
import Recorder from 'recorder-js';
import getBlobDuration from 'get-blob-duration';
import * as SDK from "microsoft-cognitiveservices-speech-sdk";

import { useTranslation } from "react-i18next";

// mui
import { Box, Button, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// components
import DialogAlert from "../../dialog-alert";

// redux
import { openDialogue } from "../../../redux/common/common-slice";
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight-slice";
import { getTranscriptMatch } from "../../../redux/spotlight/spotlight.api";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";
import speechToText from "../../../utils/speechToTextUtils";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";

// webview bridge
import webViewJavaScriptBridge from "webview-javascript-bridge";
import axiosInstance from "../../../utils/axios";

// socket
import io from 'socket.io-client';
// const AZURE_SPEECH_KEY = "98302c3796394541b21eb30476600ec1";
// const AZURE_SPEECH_KEY = "b17a8939c2e84dc7b7f44519fb3cd169";
// const AZURE_SPEECH_KEY = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY;

const AZURE_SPEECH_KEY = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY;

// const MAX_TIME = 120;
const WAITING_TIME = 3;
const minSoundVolumeReq = -20
// const minSoundVolumeReq = -27

const DIALOG_ALERT = {
    "MEDIA_PERMISSION": "Media Permission",
    "PERMISSION_DENIED": "No Camera Permission Granted"
}

const CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE = `Please grant camera permissions in your system settings and restart the app.`
const CAMERA_PERMISSION_ERROR_MESSAGE = 'You have denied camera permission. To access to spotlight feature please restart the application and give camera permission.';
const CAMERA_IN_USE_ERROR_MESSAGE = 'Another applicaiton might be using your camera. Please close the application and try again.';
const NO_SPEECH_ALERT_TIME = 5 * 1000

// socket connection
const SOCKET_URI = 'https://faceapi-uat.masteroapp.com';
// const SOCKET_URI = 'http://localhost:5000';
// const SOCKET_URI = 'https://ec2-15-206-100-60.ap-south-1.compute.amazonaws.com/ws';

// custom hook
const usePreviousAbortController = () => {
    // This ref will store the current abort controller for each API call
    const abortControllerRef = useRef(null);

    // A function that sets a new AbortController and cancels the previous one
    const setNewAbortController = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();  // Cancel the previous request
        }
        abortControllerRef.current = new AbortController();  // Create a new controller
        return abortControllerRef.current.signal;  // Return the new signal
    };

    return { setNewAbortController };
};

const RecordVideo2 = (props) => {
    const {
        screenStateList,
        setVideoFile,
        setVideoBlob,
        setAudioFile,
        setAudioBlob,
        setDuration,
        spotlightData,
        totalAttempts,
        setSpotlightAnalytics,
        setIsEmptySpeech,
        startChallengeAsync,
    } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const { microskillId } = useParams();
    const [isCameraPermission, setIsCameraPermission] = useState(false);
    const [isCameraFeed, setIsCameraFeed] = useState(false);
    const [isWaiting, setIsWaiting] = useState(true);
    const cameraStreamRef = useRef(null);
    const videoStreamRef = useRef(null);
    const audioStreamRef = useRef();
    const rectBound = useRef();
    const intervalRef = useRef();
    const timerRef = useRef();
    const waitingTimeRef = useRef();
    const progressBarRef = useRef();
    const wrongGradientBoxRef = useRef();
    const recognizerRef = useRef(null);
    const textRecognizingRef = useRef(false);
    const [sentencesSentimentAnalyses, setsentencesSentimentAnalyses] = useState([]);
    const [speechAnalysisData, setSpeechAnalysisData] = useState(null);
    const [transcriptTextMatch, setTranscriptTextMatch] = useState(null);
    const [dialogMessage, setDialogMessage] = useState(CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE);
    const [dialogTitle, setDialogTitle] = useState(DIALOG_ALERT.PERMISSION_DENIED);
    const firstRenderRef = useRef(false);

    const recognizedTextRef = useRef('');
    const [recognizedText, setRecognizedText] = useState('');
    const lastRecognizedTextRef = useRef('');

    const isNativeApp = localStorage.getItem('isNative');
    const [confirmBtnName, setConfirmBtnName] = useState(isNativeApp === 'true' ? 'Change Settings' : 'Okay');

    const recordingStoppedRef = useRef(false);
    const [isRecordingStopping, setIsRecordingStopping] = useState(false);
    const [recording, setRecording] = useState(false);
    const [facesCount, setFacesCount] = useState(null);
    const [faceErrorStamps, setFaceErrorStamps] = useState([]);

    const audioConfigRef = useRef(null);
    const speechConfigRef = useRef(null);
    const noSpeechDetectDebounceRef = useRef(null);
    const lastSoundTimeRef = useRef(Date.now())
    const audioSourceRef = useRef(null);
    const audioAnalyzerRef = useRef(null);
    const audioVolumeInDbRef = useRef(0);
    const navigatorRef = useRef(null);

    const canvasRef = useRef();
    const videoRef = useRef();
    const sendImgToSocketIntervalRef = useRef(null);
    const socketRef = useRef(null);

    const noSpeechErrorRef = useRef();
    const notificationBoxRef = useRef();

    const navigatorAudioRef = useRef(null);

    const { setNewAbortController } = usePreviousAbortController();

    const { t } = useTranslation();

    const MAX_TIME = spotlightData.timeLimit;
    const MAX_ATTEMPT = spotlightData.maxAttempts;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        if (isEmpty(isNativeApp)) {
            setDialogTitle(DIALOG_ALERT.MEDIA_PERMISSION);
            setDialogMessage(CAMERA_PERMISSION_ERROR_MESSAGE)
        }
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        // console.log("skljkdjfklsjf: '",{isCameraPermission, isCameraFeed})
        window.onbeforeunload = function () {
            // console.log({isCameraPermission, isCameraFeed})
            if(isEmpty(isCameraPermission) || !isCameraFeed) return
            return t('You have unsaved data. do you really want to reload?');
        }
        return () => {
            window.onbeforeunload = "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    }, [isCameraPermission, isCameraFeed]);

    useEffect(() => {
        // start challenge attempt on every recording starts
        startChallengeAsync();
        return () => {
            // handleStop();
            // console.log("component unmounted and calling stopAllProcesses");
            stopAllProcesses();
        }
    }, []);

    const stopAllProcesses = () => {
        // console.log("<=== stop all processes ===>")
        if (!isEmpty(intervalRef.current)) clearInterval(intervalRef.current)
        if (navigatorRef.current) {
            navigatorRef.current.getTracks().forEach(track => {
                track.stop()
            });
        }
        if (navigatorAudioRef.current) {
            navigatorAudioRef.current.getTracks().forEach(track => {
                track.stop()
            });
        }
        if (cameraStreamRef.current && cameraStreamRef.current[0]) {
            cameraStreamRef.current[0].stop();
        }
        if (cameraStreamRef.current && cameraStreamRef.current[1]) {
            cameraStreamRef.current[1].stop();
        }
        if (audioStreamRef.current && audioStreamRef.current.stream) {
            audioStreamRef.current.stream.stop();
        }

        navigatorRef.current = null;
        cameraStreamRef.current = null;
        audioStreamRef.current = null;

        if (!isEmpty(audioSourceRef.current)) audioSourceRef.current.disconnect();
        if (!isEmpty(audioAnalyzerRef.current)) audioAnalyzerRef.current.disconnect();
        if (!isEmpty(noSpeechDetectDebounceRef.current)) clearTimeout(noSpeechDetectDebounceRef.current)
    }

    const handlePermission = async () => {
        // video
        try {
            // console.log("handle permission called");
            // stopAllProcesses();

            setIsCameraFeed(false);
            rectBound.current = document.getElementById('recordVideoRootId').getBoundingClientRect();

            let tempCameraStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: {
                    channelCount: 1,
                    echoCancellation: true,
                    noiseSuppression: true
                }
            });
            navigatorRef.current = tempCameraStream;
            // console.log("tempCameraStream: ,,,,, camera permission granted, camera started ======>");

            // for video preview
            let tempVideo = document.querySelector("#camera_preview_video");
            tempVideo.srcObject = tempCameraStream;

        } catch ({ message }) {
            if (message.includes('in use')) {
                setDialogMessage(CAMERA_IN_USE_ERROR_MESSAGE);
                if (isNativeApp === 'true') {
                    setConfirmBtnName('Okay');
                }
            }
            setIsCameraPermission(true);
            return
        }
        // video


        // audio
        try {
            var AudioContext = window.AudioContext || window.webkitAudioContext;
            var audioContext = new AudioContext({
                sampleRate: 8000,
            });
            let tempAudioStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1,
                    echoCancellation: true,
                    noiseSuppression: true
                }
            });
            // let tempAudioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioConfigRef.current = SDK.AudioConfig.fromStreamInput(tempAudioStream);

            navigatorAudioRef.current = tempAudioStream;

            audioStreamRef.current = new Recorder(audioContext, { numChannels: 1 });
            audioStreamRef.current.init(tempAudioStream);
            setIsCameraFeed(true); // camera stream flag
            handleRecording();


            // // // to detect audio
            const source = audioContext.createMediaStreamSource(tempAudioStream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;

            audioSourceRef.current = source;
            audioAnalyzerRef.current = analyser;

            source.connect(analyser);

            const dataArray = new Uint8Array(analyser.fftSize);

            function detectVoice() {
                analyser.getByteTimeDomainData(dataArray);

                // Calculate the average volume
                let sum = 0;
                for (let i = 0; i < dataArray.length; i++) {
                    const amplitude = (dataArray[i] - 128) / 128; // Normalize to -1 to 1
                    sum += amplitude * amplitude;
                }
                const volume = Math.sqrt(sum / dataArray.length);
                const decibels = 20 * Math.log10(volume);
                audioVolumeInDbRef.current = decibels;

                // Detect voice activity
                if (decibels > minSoundVolumeReq) { // Adjust threshold based on your needs
                    lastSoundTimeRef.current = Date.now();

                    if (!isEmpty(noSpeechErrorRef.current)) {
                        noSpeechErrorRef.current.style.display = 'none';
                    }
                }

                requestAnimationFrame(detectVoice);
            }
            if (!isEmpty(dataArray)) {
                detectVoice();
                checkSoundTimeout();
            }
        } catch (error) {
            console.log("audio error:", error);
        }
        // audio
    };

    function checkSoundTimeout() {

        if (Date.now() - lastSoundTimeRef.current > NO_SPEECH_ALERT_TIME) {
            // if (!isEmpty(noSpeechErrorRef.current) && textRecognizingRef.current === false) {
            if (!isEmpty(noSpeechErrorRef.current) && !isRecordingStopping) {
                noSpeechErrorRef.current.style.display = 'block';
            }
        }
        noSpeechDetectDebounceRef.current = setTimeout(checkSoundTimeout, 1000);
    }

    const parseTime = (nano) => {
        let hour = Math.floor(nano / 36000000000);
        const temp = nano % 36000000000;
        let minute = Math.floor(temp / 600000000);
        const temp2 = temp % 600000000;
        let second = Math.floor(temp2 / 10000000);
        let mil = temp2 % 10000000;
        hour = hour.toString();
        minute = minute.toString();
        second = second.toString();
        mil = mil.toString().slice(0, 3); //cuts off insignificant digits
        return `${hour}:${minute}:${second}.${mil}`;
    };

    const startVideoRecording = () => {

        const rtcConfig = {
            type: 'video',
            recorderType: MediaStreamRecorder,
            frameRate: 30,
            mimeType: 'video/webm;codecs=vp9',
        };
        const camera_stream = navigatorRef.current;
        videoStreamRef.current = new RecordRTC(camera_stream, rtcConfig);
        cameraStreamRef.current = camera_stream.getTracks();

        videoStreamRef.current.startRecording();
    }

    const initiateRecognizer = async () => {
        const recognizer = new SDK.SpeechRecognizer(speechConfigRef.current, audioConfigRef.current);

        recognizerRef.current = recognizer
        recognizer.recognized = (s, e) => {
            const { result } = e;
            const { text, offset, duration, properties } = result;
            const resultConfidence = result.reason === SDK.ResultReason.RecognizedSpeech ? 1 : 0;

            if (typeof result.text !== 'undefined') {

                textRecognizingRef.current = true;
                if (result.text !== lastRecognizedTextRef.current) {

                    setRecognizedText(prevText => {
                        return `${prevText} ${result.text}`;
                    });
                    recognizedTextRef.current += ` ${result.text}`;
                    lastRecognizedTextRef.current = result.text;


                    // ////////////  // /////////////// // /////////////
                    const words = result.text.split(' ');
                    const wordConfidences = words.map(() => resultConfidence);

                    let wordsMap = [];
                    words.map((i, index) => ({ word: i, confidence: wordConfidences[index] }))

                    let temp = {
                        sessionId: e.sessionId,
                        text,
                        offset: parseTime(offset),
                        duration,
                        words
                    };
                    setsentencesSentimentAnalyses(prev => {
                        return [...prev, temp];
                    })
                }
            }

            // textRecognizingRef.current = false;
        };
        recognizer.startContinuousRecognitionAsync()
    }

    const initSpeechRecognition = async (language) => {
        // audioConfigRef.current = SDK.AudioConfig.fromDefaultMicrophoneInput(); 
        // audioConfigRef.current = SDK.AudioConfig.fromStreamInput(navigatorAudioRef.current);
        speechConfigRef.current = SDK.SpeechConfig.fromSubscription(AZURE_SPEECH_KEY, "centralindia");
        speechConfigRef.current.speechRecognitionLanguage = language || "en-US";
    };

    useEffect(() => {
        (async function () {
            if (!firstRenderRef.current) {
                firstRenderRef.current = true;
                return
            }
            const speechToTextResp = await speechToText(
                {
                    sentencesAnalyses: sentencesSentimentAnalyses,
                    transcript: recognizedText
                },
                {
                    ...spotlightData,
                    timespent: !isEmpty(timerRef?.current?.innerHTML) ? MAX_TIME - parseInt(timerRef?.current?.innerHTML) : 0
                }
            );
            setSpeechAnalysisData(speechToTextResp);
        })();
    }, [sentencesSentimentAnalyses]);


    // this useEffect is used to match the transcript with the proepr recognized (to resolve the issue of last line not capturing)
    useEffect(() => {
        (async function () {

            if (!isCameraFeed) return


            if (recordingStoppedRef.current && !textRecognizingRef.current) {
                if (!isEmpty(textRecognizingRef.current)) clearTimeout(noSpeechDetectDebounceRef.current);
                await sleep(1000)
                const isTranscriptMatched = await matchTranscript();
                await sleep(500);


                if (isTranscriptMatched) {
                    setIsRecordingStopping(false);
                    dispatch(handleSpotlightScreenState(screenStateList.PREVIEW));
                }
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recognizedText, textRecognizingRef.current, recordingStoppedRef.current]);

    useEffect(() => {
        if (isEmpty(speechAnalysisData)) return;
        setSpotlightAnalytics((prev) => {
            let returningObj = {
                transcript: transcriptTextMatch?.transcript,
            }

            if (isCriteriaContains(spotlightData.criteria, 'transcriptMatch')) {
                returningObj['transcriptMatch'] = transcriptTextMatch
            }
            if (isCriteriaContains(spotlightData.criteria, 'duration')) {
                returningObj['duration'] = speechAnalysisData?.duration
            }
            if (isCriteriaContains(spotlightData.criteria, 'speechRate')) {
                returningObj['speechRate'] = speechAnalysisData?.speechRate
            }
            if (isCriteriaContains(spotlightData.criteria, 'keyMatch')) {
                returningObj['keywordMatch'] = speechAnalysisData?.keywordMatch
            }

            return { ...prev, ...returningObj }
        })
    }, [spotlightData, speechAnalysisData, transcriptTextMatch]);

    const matchTranscript = async () => {
        try {
            const getLargerString = (str1, str2) => {
                if (isEmpty(str1) && isEmpty(str2)) return str1;
                return str1?.length > str2?.length ? str1 : str2;
            }

            const cleanUpString = (str) => {
                if (isEmpty(str)) return str;
                return str?.trim()?.replaceAll(" +", " ");
            }
            // Set a new AbortController and cancel the previous one
            const signal = setNewAbortController();

            const apiPayload = {
                creatorText: spotlightData.transcript,
                transcript: getLargerString(cleanUpString(recognizedText), cleanUpString(recognizedTextRef.current)),
                criteria: spotlightData.criteria,
                isContextual: spotlightData.isContextual || false // remove it for prod deployment if needed
            }
            const TranscriptMatchResp = await axiosInstance.post('/spotlight/transcriptCompare', apiPayload, { signal });

            if (TranscriptMatchResp?.status === 204) {
                setIsEmptySpeech(true)
                return true;
            }

            let transcriptMatch = TranscriptMatchResp?.data?.data?.transcriptMatch;
            if (isEmpty(transcriptMatch?.transcript)) {
                setIsEmptySpeech(true)
            }
            setTranscriptTextMatch(TranscriptMatchResp?.data?.data?.transcriptMatch);
            return true
        } catch (error) {
            if (error.response?.status === 400) {
                setIsEmptySpeech(true)
                return true
            }
            console.log("error: ", error);
            return false
        }
    }
    useEffect(() => {
        handlePermission();
    }, []);

    useEffect(() => {
        // handlePermission();
        if (isEmpty(spotlightData)) return;
        initSpeechRecognition(spotlightData?.language);
        // avatar video play
        // document.querySelector("#avatar_video")?.play()
    }, [spotlightData]);

    useEffect(() => {
        if (recordingStoppedRef.current) {
            stopAllProcesses();
        }
    }, [recordingStoppedRef.current]);

    const startCountDown = (time, ref, prefix, suffix, type) => {
        if (!isEmpty(intervalRef.current)) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (!isEmpty(ref?.current?.innerText)) {
                let currentTime = ref.current.innerText
                ref.current.innerText = ` ${parseInt(currentTime) - 1}`;

                if (type === 'RECORDING_TIME') {
                    let progressPercentage = (ref.current.innerHTML / time) * 100;
                    if (!isEmpty(progressBarRef.current)) {
                        progressBarRef.current.style.width = `${progressPercentage}%`;

                        // handle progressbar color
                        if (progressPercentage <= 50 && progressPercentage > 0) progressBarRef.current.style.backgroundColor = '#ffb300';
                        else if (progressPercentage <= 0) {
                            progressBarRef.current.style.backgroundColor = '#f63b34';
                            wrongGradientBoxRef.current.style.display = 'block';
                        }
                    }
                }
            }
        }, 1000);
    }

    const handleRecording = async () => {

        // Count down
        startCountDown(WAITING_TIME, waitingTimeRef, "", "", 'WAITING_TIME');
        await sleep(3000);
        startVideoRecording();
        initiateRecognizer();
        startRecordingSocket();
        lastSoundTimeRef.current = Date.now();

        setIsWaiting(false);
        clearInterval(intervalRef.current)
        await sleep(200);

        // start recording timer
        startCountDown(MAX_TIME, timerRef, "", "", 'RECORDING_TIME');

        // audio
        audioStreamRef.current.start()
    };

    const handleStopRecording = async () => {

        // handle stop recording
        setIsRecordingStopping(true);
        videoStreamRef.current.stopRecording(async function () {
            const blob = this.getBlob();
            if (isEmpty(blob) && isEmpty(blob.size)) {
                dispatch(openDialogue({
                    isDialogueOpen: true,
                    dialogueTitle: 'Video Issue',
                    dialogueContent: t('Encountered issue while recording the video. Though audio will be saved for analyzation.')
                }));
                return
            }
            const duration = await getBlobDuration(blob);
            setDuration(duration);
            let fileExtension = commonUtil.getFileTypeExtension(blob.type);
            const file = new File([blob], `spotlight-video.${fileExtension}`, { lastModified: new Date().getTime(), type: blob.type });
            // const file = new File([blob], "spotlight-video", { lastModified: new Date().getTime(), type: blob.type });
            setVideoFile(file);
            setVideoBlob(URL.createObjectURL(blob));
            videoStreamRef.current.destroy();

        });

        audioStreamRef.current.stop().then(async ({ blob, buffer }) => {
            const duration = await getBlobDuration(blob);

            setDuration(duration);
            let fileExtension = commonUtil.getFileTypeExtension(blob.type);
            const file = new File([blob], `spotlight-audio.${fileExtension}`, { lastModified: new Date().getTime(), type: blob.type });
            // const file = new File([blob], "spotlight-audio", { lastModified: new Date().getTime(), type: blob.type });
            setAudioBlob(URL.createObjectURL(blob));
            setAudioFile(file);

            if (recognizerRef.current) {
                recognizerRef.current.stopContinuousRecognitionAsync();
                recognizerRef.current.close();
                recognizerRef.current.sessionStopped = (s, e) => {
                    textRecognizingRef.current = false;
                };
                textRecognizingRef.current = false;

            }

            recordingStoppedRef.current = true;
        });
        stopRecordingSocket(); // emit stop recording in socket.io
        if (!isEmpty(audioSourceRef.current)) audioSourceRef.current.disconnect();
        if (!isEmpty(audioAnalyzerRef.current)) audioAnalyzerRef.current.disconnect();

        stopAllProcesses();
    };

    const handlePermissionDialogueClose = () => {
        if (isNativeApp === 'true' && CAMERA_PERMISSION_ERROR_MESSAGE_NATIVE === dialogMessage) {
            webViewJavaScriptBridge.sendMessage({ action: 'OpenSettingsChannel', params: true });
            window.location.replace(`/challenge-list/${microskillId}`);
        } else {
            window.location.replace(`/challenge-list/${microskillId}`);
        }
        setIsCameraPermission(false);

    };

    const isCriteriaContains = (criteria, key) => {
        if (isEmpty(criteria)) return false;
        let findObj = criteria.find((item) => (item.name?.toUpperCase() === key?.toUpperCase()));
        let isContains = !isEmpty(findObj) && (findObj.check == 1);
        return isContains
    }

    // socket io
    useEffect(() => {

        // console.log('recording started to start sockets in useEffect', recording, socket);
        // Listen for facial features from the server

        // if (!recordingStoppedRef.current) {
        //     console.log(`recording is false in useEffect recordingStoppedRef.current`, recordingStoppedRef.current);
        //     startRecordingSocket();
        // }
        try {
            socketRef.current = io(SOCKET_URI, {
                transports: ['websocket'],  // Force WebSocket transport
                secure: true  // Ensures HTTPS is used
            });

            const socket = socketRef.current;

            socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
                socket.disconnect();
            });

            console.log('socket connected check', socket?.connected);
            socket.on('facial_features', (data) => {
                let faces = data?.landmarks?.length;
                // console.log("faces count in effect: ", faces)
                setFacesCount(faces);
            });

            socket.on('error', (error) => {
                console.error('Error: ', error);
                setFaceErrorStamps((prev) => [...prev, error.timestamp]);
                if(isNativeApp === 'true'){
                    webViewJavaScriptBridge.sendMessage({ action: 'NativeHapticFeedbackChannel', params:  {active:true}})
                }
            });

            socket.on('recording_started', () => {
                // console.log('recording_started socket useEffect');
                setRecording(true);
            });

            socket.on('recording_stopped', (data) => {
                setRecording(false);
                // console.log('Recording stopped. Timestamps:', data.timestamps);
                // console.log(data)
                setSpotlightAnalytics(prev => ({ ...prev, faceAnalytics: data }));
            });
        } catch (error) {
            console.error('Error in socket connection: ', error);
            socketRef.current.disconnect();
        }

        return () => {
            const socket = socketRef.current;
            socket.off('facial_features');
            socket.off('error');
            socket.off('recording_started');
            socket.off('recording_stopped');
            stopRecordingSocket();
            setFaceErrorStamps([]);

            // console.log("sendImgToSocketIntervalRef.current 1: ", sendImgToSocketIntervalRef.current)
            if (!isEmpty(sendImgToSocketIntervalRef.current)) {
                clearInterval(sendImgToSocketIntervalRef.current);
            }
        };
    }, []);

    const captureAndSendImageToSocket = async () => {
        const socket = socketRef.current;
        const canvas = canvasRef.current;
        const video = videoRef.current;
        if (!video || !canvas) {
            console.error("Video or canvas element not found.");
            return;
        }
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result.split(',')[1];
                socket.emit('image_data', base64data);
            };
            reader.readAsDataURL(blob);
        }, 'image/png');
    };

    const startRecordingSocket = () => {
        const socket = socketRef.current;
        socket.emit('start_recording');
        // let recordingInSocket = true;
        setRecording(true);
        // console.log('socket recording started')
        if (isEmpty(sendImgToSocketIntervalRef.current)) {
            sendImgToSocketIntervalRef.current = setInterval(() => {
                captureAndSendImageToSocket();
            }, 1000); // Capture and send image every second
        }
        // else {
        //     clearInterval(sendImgToSocketIntervalRef.current);
        // }
    };

    const stopRecordingSocket = () => {
        const socket = socketRef.current;
        socket.emit('stop_recording');
        // console.log("sendImgToSocketIntervalRef.current 3: ", sendImgToSocketIntervalRef.current)
        if (!isEmpty(sendImgToSocketIntervalRef.current)) {
            // console.log("sendImgToSocketIntervalRef.current 4: ", sendImgToSocketIntervalRef.current)
            clearInterval(sendImgToSocketIntervalRef.current);
            sendImgToSocketIntervalRef.current = null;
        }
        // socket.on('recording_stopped', (data) => {
        //     setRecording(false);
        //     console.log(data)
        // });
    };

    return (
        <div className={classes.recordVideoRoot} id="recordVideoRootId">
            {
                !isCameraFeed && (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                )
            }

            <div className={classes.videoBox}>
                {
                    isRecordingStopping && (
                        <div className={`${classes.loaderWrapper} ${classes.centerFlex}`}>
                            <CircularProgress sx={{color: 'white'}} />
                        </div>
                    )
                }
                <video
                    style={{
                        display: isCameraFeed ? 'block' : 'none',
                        height: "100%",
                        width: "100%",
                    }}
                    width={480}
                    height={640}
                    muted
                    id="camera_preview_video"
                    className={classes.videoTag}
                    playsInline
                    autoPlay={true}
                    controls={false}
                    ref={videoRef}
                />
                <canvas
                    ref={canvasRef}
                    style={{
                        position: "absolute",
                        left: '0',
                        top: '0',
                        textAlign: "center",
                        visibility: 'hidden',
                        // backgroundColor: 'rgba(255, 0,0,0.5)',
                        zindex: 9,
                    }}
                />
                <RecordingOverlay
                    spotlightData={spotlightData}
                    classes={classes}
                    isCameraFeed={isCameraFeed}
                    isWaiting={isWaiting}
                    waitingTimeRef={waitingTimeRef}
                    WAITING_TIME={WAITING_TIME}
                    progressBarRef={progressBarRef}
                    MAX_ATTEMPT={MAX_ATTEMPT}
                    totalAttempts={totalAttempts}
                    t={t}
                    wrongGradientBoxRef={wrongGradientBoxRef}
                    MAX_TIME={MAX_TIME}
                    sleep={sleep}
                    timerRef={timerRef}
                    handleStopRecording={handleStopRecording}
                    isRecordingStopping={isRecordingStopping}
                    facesCount={facesCount}
                    faceErrorStamps={faceErrorStamps}
                    notificationBoxRef={notificationBoxRef}
                    noSpeechErrorRef={noSpeechErrorRef}
                />
            </div>

            {
                isCameraPermission && (
                    <DialogAlert
                        isOpen={isCameraPermission}
                        title={dialogTitle}
                        content={t(dialogMessage)}
                        confirmBtnName={confirmBtnName}
                        handleConfirmBtnClick={handlePermissionDialogueClose}
                    />
                )
            }
        </div>
    )
}

export default RecordVideo2;


const RecordingOverlay = (props) => {
    const {
        spotlightData,
        classes,
        isCameraFeed,
        isWaiting,
        waitingTimeRef,
        WAITING_TIME,
        progressBarRef,
        MAX_ATTEMPT,
        totalAttempts,
        t,
        wrongGradientBoxRef,
        MAX_TIME,
        sleep,
        timerRef,
        handleStopRecording,
        isRecordingStopping,
        facesCount,
        faceErrorStamps,
        notificationBoxRef,
        noSpeechErrorRef,
    } = props
    const [showAssistBtn, setShowAssistBtn] = useState(false);
    const [showTranscriptText, setShowTranscriptText] = useState(false);
    const [textAnimeLoading, setTextAnimeLoading] = useState(false);
    const [textAnimeComplete, setTextAnimeComplete] = useState(false);
    const [buttonsVisible, setButtonsVisible] = useState(true)

    // refs
    const noFaceErrorRef = useRef();
    const multiFaceErrorRef = useRef();
    const distractedErrorRef = useRef();

    async function typeWriter(id, txt, speed) {
        let i = 0, element = document.getElementById(id), parentElement = document.querySelector("#transcript_text_body");

        setTextAnimeLoading(true);
        if (!isEmpty(element)) {
            while (i < txt.length) {
                // if(isRecordingStopping) break;
                element.innerHTML += txt.charAt(i);
                if (i % 14 === 0 && !isEmpty(parentElement)) {
                    parentElement.scrollTo({
                        top: i,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
                i++;
                await sleep(speed);
            }
        }
        setTextAnimeComplete(true)
        setTextAnimeLoading(false)

        // scroll to bottom when typewriter is complete
        if (element.innerText.length >= txt.length) {
            parentElement.scrollTo({
                top: parentElement.scrollHeight,
                left: 0,
                behavior: 'smooth'

            })
        }
    }

    useEffect(() => {
        if (!isWaiting && spotlightData.isTranscript) {
            if (textAnimeComplete === true || textAnimeLoading === true) return;
            typeWriter("transcript_text", spotlightData.transcript, 50)
        }
    }, [spotlightData, isWaiting, textAnimeComplete, textAnimeLoading])

    useEffect(() => {
        if (isEmpty(faceErrorStamps)) return;
        if (isEmpty(distractedErrorRef.current)) return;
        distractedErrorRef.current.style.display = 'block';
        setTimeout(() => {
            if (!isEmpty(distractedErrorRef.current)) distractedErrorRef.current.style.display = 'none';
        }, 2500);
    }, [faceErrorStamps, distractedErrorRef.current])

    return (
        isCameraFeed && (
            isWaiting ? (
                <div className={`${classes.recordingOverlayRoot} ${classes.centerFlex}`}>
                    <span ref={waitingTimeRef} className={classes.waitingTimeText}>{WAITING_TIME}</span>
                </div>
            ) : (
                <div className={classes.recordingOverlayRoot}>
                    <div className={classes.progressBarBox}>
                        <div className={classes.progressBar} ref={progressBarRef}></div>
                    </div>
                    <div className={classes.overlayBox}>
                        <Box className={classes.errorBoxWrapper}>
                            <div className={classes.errorBox} ref={noSpeechErrorRef}>
                                <h3 className={classes.errorText}><ErrorOutlineIcon className={classes.errorIcon} /> No speech detected!</h3>
                            </div>
                            {
                                !isEmpty(facesCount) && facesCount <= 0 && (
                                    <div className={classes.errorBox} ref={noFaceErrorRef} style={{ display: 'block' }}>
                                        <h3 className={classes.errorText}><ErrorOutlineIcon className={classes.errorIcon} /> No face detected!</h3>
                                    </div>
                                )
                            }
                            {
                                facesCount > 1 && (
                                    <div className={classes.errorBox} ref={multiFaceErrorRef} style={{ display: 'block' }}>
                                        <h3 className={classes.errorText}><ErrorOutlineIcon className={classes.errorIcon} /> Multiple faces found!</h3>
                                    </div>
                                )
                            }

                            <div className={classes.errorBox} ref={distractedErrorRef}>
                                <h3 className={classes.errorText}><ErrorOutlineIcon className={classes.errorIcon} /> You are distracted, Please look at the screen</h3>
                            </div>

                            <div className={classes.notificationBox} ref={notificationBoxRef}>
                                <div className={`${classes.text}`}>
                                    <img src="/images/icons/heart.png" width={13} />
                                    <span> x </span>
                                    <span>{MAX_ATTEMPT - totalAttempts}</span>
                                </div>
                                <div className={`${classes.text}`}>
                                    <img src="/images/icons/stopwatch.png" width={13} />
                                    <span ref={timerRef}> {MAX_TIME}</span>
                                    <span>s</span>
                                </div>
                            </div>
                        </Box>
                        <div className={classes.assistBoxContainer}>
                            <div className={classes.assistBox}>

                                {
                                    buttonsVisible && spotlightData.isTranscript && spotlightData.transcript.length > 0 && (
                                        <div className={`${classes.transcriptBtn} ${classes.centerFlex}`}
                                            onClick={() => {
                                                setShowTranscriptText(true)
                                                setButtonsVisible(false)
                                            }}
                                        >
                                            <ArrowBackIosNewIcon sx={{ fontSize: '0.8rem' }} />
                                            <div className={classes.transcriptBtnText}>{t("Transcript")}</div>
                                        </div>
                                    )
                                }
                                {
                                    spotlightData.assistance && buttonsVisible && !showAssistBtn && spotlightData.keywords.length > 0 && (
                                        <div className={`${classes.assistBtn} ${classes.centerFlex}`}
                                            onClick={() => {
                                                setShowAssistBtn(true)
                                                setButtonsVisible(false)
                                            }}
                                        >
                                            <ArrowBackIosNewIcon sx={{ fontSize: '0.8rem' }} />
                                            <div className={classes.assistText}>{t("Assist")}</div>
                                        </div>
                                    )
                                }
                                {
                                    showAssistBtn && (
                                        <div className={classes.assistTextContainer}>
                                            <div className={classes.assistCloseIcon}>
                                                <CloseIcon sx={{ color: palette.orange }} onClick={() => {
                                                    setShowAssistBtn(false)
                                                    setButtonsVisible(true)
                                                }} />
                                            </div>
                                            <div className={classes.transcriptBtnContainer}>
                                            </div>
                                            <div className={`${classes.assistTextBtns} scrollbar`}>
                                                {
                                                    spotlightData.keywords.map(item => (
                                                        item.keywords.map((item2, index) => (
                                                            <div key={`${item2.id}-${index}`} className={`${classes.textBtn} ${classes.centerFlex}`}>
                                                                {item2.name}
                                                            </div>
                                                        ))
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }


                            </div>


                            {/* avatar box  ----- code to be used in future */}
                            {
                                false && <div className={classes.avatarContainer}>
                                    <video
                                        src='/sample-documents/Avatar_video_1.mp4'
                                        playsInline
                                        id="avatar_video"
                                        // autoPlay
                                        controls={false}
                                        muted
                                        loop
                                        width="100%"
                                        height="100%"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: '10px 0 0 10px'
                                        }}
                                        onLoadedMetadata={(event) => {
                                            event.target.play()
                                        }}
                                    />
                                </div>
                            }
                            {/* avatar box */}
                        </div>

                        {/* // transcript text */}
                        <div className={`${classes.transcriptTextContainer}`} style={{
                            visibility: showTranscriptText ? 'visible' : 'hidden'
                        }}>
                            <div className={classes.transcriptTextHeader}>
                                <h4>Transcript</h4>
                                <div><CloseIcon sx={{ color: palette.orange }} onClick={() => {
                                    setShowTranscriptText(false)
                                    setButtonsVisible(true)
                                }} /></div>
                            </div>
                            <div className={`${classes.transcriptTextBody} scrollbar`} id="transcript_text_body" >
                                <p className={classes.transcriptText} id="transcript_text"></p>
                            </div>
                        </div>
                        {/* // transcript text */}
                        <div className={classes.buttonBox}>
                            <Button variant="contained"
                                size="large"
                                className={`fixed_ratio_20_3 ${classes.stopBtn}`}
                                fullWidth
                                onClick={handleStopRecording}
                                disabled={isRecordingStopping}
                            >
                                {
                                    isRecordingStopping ? <CircularProgress size={24} /> : t('Stop Recording')
                                }
                            </Button>
                        </div>
                    </div>

                    <div className={classes.wrongGradientBox} ref={wrongGradientBoxRef} />
                </div>
            )
        )
    )
}