import axiosInstance from "../../utils/axios";

export const getAllModulesAsync = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/microskills${params}`);
        return respData;
    } catch (err) {
        console.log("getAllModulesAsync:", err);
        return err.response
    }
}

export const getChallengeLists = async (microskillId) => {
    try {
        const respData = await axiosInstance.get(`/${microskillId}/challenges`);
        return respData;
    } catch (err) {
        console.log("getChallengeLists:", err);
        return err;
    }
}

export const getChallengeData = async (microskillId, challengeId) => {
    try {
        const respData = await axiosInstance.get(`/${microskillId}/${challengeId}`);
        return respData;
    } catch (err) {
        console.log("getChallengeData:", err)
    }
}

export const startChallengeAttempt = async (payload) => {
    try {
        const respData = await axiosInstance.post(`/start-challenge-attempt`, payload);
        return respData;
    } catch (err) {
        console.log("startChallengeAttempt:", err)
    }
}

export const getCertificateData = async (params) => {
    try {
        const respData = await axiosInstance.get(`/certificate/get-certifcate-config?${params}`);
        return respData;
    } catch (err) {
        console.log("getCertificateData:", err)
    }
}

export const microskillCertificateUpload = async (payload) => {
    try {
        const respData = await axiosInstance.post('/upload-microskill-certificate', payload);
        return respData
    } catch (err) {
        return err.response;
    }
}

export const genericFileUpload = async (payload) => {
    try {
        const respData = await axiosInstance.post('/upload-media-file', payload);
        return respData
    } catch (err) {
        return err.response;
    }
}

export const getAllMicroskillFeedbackList = async () => {
    try {
        const respData = await axiosInstance.get('/microskill-feedback');
        return respData;
    }
    catch (err) {
        console.log("Error in fetching the list of Microskill Feedback");
    }
}

export const submitMicroskillFeedback = async (microskillId, payload) => {
    try {
        const respData = await axiosInstance.post(`/${microskillId}/feedback`, payload);
        return respData;
    } catch (err) {
        console.log("Error in submitting the Microskill Feedback");
    }
}

export const markMicroskillFeedbackDismiss = async (payload) => {
    try {
        const respData = await axiosInstance.post('/microskill-feedback-dismiss', payload);
        return respData;
    } catch (err) {
        console.log("Error in dismissing the Microskill Feedback");
    }
}

export const getLearningJourneyMicroskill = async (id) => {
    try {
        const respData = await axiosInstance.get(`/get-learner-journey-microskill?journeyId=${id}`);
        return respData;
    } catch (err) {
        console.log("getLearningJourneyMicroskill:", err);
        return err;
    }
}

export const postAllSearchedData = async (payload) => {
    try {
        const respData = await axiosInstance.post('/search-content', payload);
        return respData;
    } catch (err) {
        console.log("Error in posting the searched Data.");
    }
}

export const getAllBulletinsAsync = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/bulletins${params}`);
        return respData;
    } catch (err) {
        console.log("getAllBulletinsAsync", err);
        return err.response
    }
}

export const postBulletinStartAttempt = async ({ bulletinId }) => {
    try {
        const payload = {
            bulletInId: bulletinId
        }

        const respData = await axiosInstance.post('/bulletins/start-bulletin-attempt', payload);
        return respData;
    } catch (err) {
        console.log("Error in postBulletinStartAttempt : ", err);
    }
}

export const postBulletinEndAttempt = async ({ bulletinId }) => {
    try {
        const payload = {
            bulletInId: bulletinId
        }
        const respData = await axiosInstance.post('/bulletins/end-bulletin-attempt', payload);
        return respData;
    } catch (err) {
        console.log("Error in postBulletinEndAttempt : ", err);
    }
}

export const postAnnouncementViewed = async ({ bulletinId }) => {
    try {
        const payload = {
            bulletInId: bulletinId
        }
        const respData = await axiosInstance.post('/bulletins/bulletin-viewed', payload);
        return respData;
    } catch (err) {
        console.log("Error in postAnnouncementViewed : ", err);
    }
}