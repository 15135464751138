import React, { useRef, useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// mui
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import CircularProgress from '@mui/material/CircularProgress';

// context
import LearningAidContext from "../../context/learningAidContext";

// components
import Ila from "../ila";
import CoverCard from "../la-cards/cover-card";
import DynamicLaCard from "../la-cards/dynamic-la";
import LaWinScreen from "../ila/win-screen";

// redux
// import { getChallengeLists } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../utils/isEmpty";

// theme
import palette from "../../theme/palette";

// constants
// import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

const LearningAidCardDesign = ({ data, cardType, cardStyle, cardDetailsData, viewFullScreen, initialData, handlePrevClick, handleNextBtnClick }) => {
    const classes = useStyles();
    const contentObj = data.reduce((result, obj) => {
        result[obj.name] = obj;
        return result;
    }, {});

    return (
        <div className={classes.dynamicCardRoot} style={{ borderRadius: viewFullScreen ? '0' : null }}>
            {
                cardType.toUpperCase() !== 'COVER' ? (
                    <DynamicLaCard data={contentObj} viewFullScreen={viewFullScreen} cardDetailsData={cardDetailsData} handlePrevClick={handlePrevClick} handleNextBtnClick={handleNextBtnClick} />
                ) : (
                    <CoverCard data={contentObj} length={initialData.Cards.length} viewFullScreen={viewFullScreen} />
                )
            }
        </div>
    )
}

const LearningAidCardsContainer = (props) => {
    const {
        data,
        initialData,
        attemptedIdsRef,
        activeIndex,
        activeSubIndex,
        subCardIndex,
        maxScore,
        scoreListRef,
        showWinScreen,
        addScore,
        scoreValueRef,
        scoreRef,
        timeRef,
        handleChallengeAttemptData,
        func: { handleNext, handleNextBtnClick, handlePrevClick },
        setViewFullScreen,
        viewFullScreen,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        viewLaOnboardingScreen,
        cardsViewedRef,
    } = props;

    const { i18n } = useTranslation();

    const cardData = data.Cards;
    const classes = useStyles(i18n);
    // const navigate = useNavigate();
    const { files, handleDocumentBtnClick, isFileViewerOpen, fileType, setIsImageMagnify } = useContext(LearningAidContext);
    const imageBoxRef = useRef();
    const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);

    const getImageDimensions = (imgUrl) => {
        return new Promise((resolve, reject) => {
            let imageWidth = null;
            let imageHeight = null;
            const img = new Image();
            img.onload = function () {
                imageWidth = this.width;
                imageHeight = this.height;
                resolve({ width: imageWidth, height: imageHeight })
            };
            img.onerror = function () {
                reject({ width: 0, height: 0 })
            };
            img.src = imgUrl;
        });
    }

    const handleCardClickOnMobile = (e) => {
        if ((!isMobile && !isTablet) || e.target.id === 'documentLink' || isFileViewerOpen) return

        // check for ILA
        const cardDetails = cardData[activeIndex];
        if (!isEmpty(cardDetails.subCards) && !isEmpty(cardDetails.subCards[activeSubIndex])) {
            let currentCardTypeList = Object.keys(cardDetails.subCards[activeSubIndex]);
            if (currentCardTypeList.includes('ila')) return
        }

        const clickX = e.clientX; // horizontal coordinate of the click event
        const divLeftX = e.target.offsetLeft; // horizontal coordinate of the left edge of the div
        const divWidth = (() => {
            if (e.target.id === 'rotatedImage') return e.target.offsetHeight
            return e.target.offsetWidth
        })() // width of the div

        if (clickX < divLeftX + divWidth / 2) {
            let nodeName = e.target.nodeName.toUpperCase();
            if (nodeName === 'VIDEO' || nodeName === 'SVG' || nodeName === 'PATH') return
            handlePrevClick();
        } else {
            let nodeName = e.target.nodeName.toUpperCase();
            if (nodeName === 'VIDEO' || nodeName === 'SVG' || nodeName === 'PATH') return
            handleNextBtnClick();
        }
    }


    const showNextBtn = () => {
        let nextBtn = document.querySelector(`#next-btn`);
        let circularProgress = document.querySelector(`#circular-progressbar`);
        circularProgress.style.display = 'none';
        nextBtn.style.display = 'block';
    }

    const _handlePrevClick = () => {
        showNextBtn();
        handlePrevClick();
    }

    useEffect(() => {
        checkBtnActive()
    }, [activeIndex, activeSubIndex])

    const checkBtnActive = () => {
        const cardDetails = cardData[activeIndex];

        if (!isEmpty(cardDetails.subCards) && !isEmpty(cardDetails.subCards[activeSubIndex])) {
            let currentCardTypeList = Object.keys(cardDetails.subCards[activeSubIndex]);
            if (currentCardTypeList.includes('ila') && !isChallengeSubmitted) setIsNextBtnDisabled(true)
            else setIsNextBtnDisabled(false)
        }
    }

    const LearningAidCard = () => {
        const cardDetails = cardData[activeIndex];
        let subCards = cardDetails.subCards;
        getImageDimensions(cardDetails.thumbImage)
            .then(result => {
                let ratio = result.width / result.height;
                if (isEmpty(imageBoxRef.current)) return
                if (ratio > 1) {
                    imageBoxRef.current.style.width = '100%';
                } else {
                    imageBoxRef.current.style.height = '100%';
                }
            })
            .catch(error => {
                // console.error(error); 
            });

        return (
            <div className={`${classes.centerFlex} ${classes.container}`}>
                {
                    isEmpty(cardDetails.gameType) ? (
                        <LearningAidCardDesign data={cardDetails.card.contents}
                            initialData={initialData}
                            viewFullScreen={viewFullScreen}
                            cardType={cardDetails.cardType}
                            cardDetailsData={cardDetails}
                            handlePrevClick={handlePrevClick}
                            handleNextBtnClick={handleNextBtnClick}
                        />
                    ) : (
                        <>
                            {
                                !isEmpty(subCards) && Object.keys(subCards[activeSubIndex])[0] === 'thumbImage' && (
                                    <LearningAidCardDesign data={cardDetails.card.contents}
                                        initialData={initialData}
                                        viewFullScreen={viewFullScreen}
                                        cardType={cardDetails.cardType}
                                        cardDetailsData={cardDetails}
                                    />
                                )
                            }

                            {
                                !isEmpty(subCards) && Object.keys(subCards[activeSubIndex])[0] === 'context' && (
                                    <LearningAidCardDesign data={subCards[activeSubIndex].context[subCardIndex].contents}
                                        initialData={initialData}
                                        viewFullScreen={viewFullScreen}
                                        cardType={cardDetails.cardType}
                                        cardStyle={'context'}
                                        cardDetailsData={cardDetails}
                                    />
                                )
                            }

                            {
                                !isEmpty(subCards) && Object.keys(subCards[activeSubIndex])[0] === 'ila' && (
                                    <div className={`${classes.ilaBox}`}>
                                        <Ila data={cardDetails}
                                            prev={handlePrevClick}
                                            next={handleNext}
                                            attemptedIds={attemptedIdsRef}
                                            scoreListRef={scoreListRef}
                                            maxScore={maxScore}
                                            addScore={addScore}
                                            scoreValueRef={scoreValueRef}
                                            scoreRef={scoreRef}
                                            handleChallengeAttemptData={handleChallengeAttemptData}
                                            CorrectTemplate={CorrectTemplate}
                                            IncorrectTemplate={IncorrectTemplate}
                                            SkipButton={SkipButton}
                                            viewFullScreen={viewFullScreen}
                                            isChallengeSubmitted={isChallengeSubmitted}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
        )
    }

    const DocumentsHyperLink = () => {
        const cardDetails = cardData[activeIndex];
        let subCards = cardDetails.subCards;

        if (!isEmpty(files)) {
            if (!isEmpty(subCards) && Object.keys(subCards[activeSubIndex])[0] === 'ila') return ""
            else return <div onClick={() => handleDocumentBtnClick()} className={classes.cursorPointer}>
                {
                    fileType === 'link' && (
                        <LanguageIcon sx={{ fontSize: '1.8rem' }} />
                    )
                }
                {
                    fileType === 'document' && (
                        <img src="/images/icons/page_white.png" alt="icon" width={19} className={classes.documentLinkImg} />
                    )
                }
            </div>

        } else {
            return ""
        }
    }

    return (
        <>
            <div className={classes.learningAidRoot} style={{ gridTemplateRows: viewFullScreen ? '100% 0%' : null }}>
                <div className={classes.cardBox} style={{ padding: viewFullScreen ? '0' : null }}>
                    {
                        showWinScreen ? (
                            <LaWinScreen score={scoreRef.current} time={timeRef.current} maxScore={maxScore} />
                        ) : (
                            <div className={`${classes.card} ${classes.centerFlex}`} onClick={handleCardClickOnMobile}>
                                <LearningAidCard />
                            </div>
                        )
                    }
                </div>
                {
                    !isMobile && (
                        <div className={`${classes.bottomNavigationBox} ${viewFullScreen ? classes.controls : ''}  ${classes.centerFlex}`} id="bottomNavigationBox">
                            <div className={`${classes.controlsBox} ${classes.centerFlex}`} style={{
                                backgroundColor: viewFullScreen ? '#00000042' : isDarkMode ? palette.darkVoilet : palette.white
                            }}>
                                <div onClick={() => _handlePrevClick()} className={`${classes.cursorPointer}`}>
                                    <div className={`${classes.navBtnBox} ${classes.centerFlex}`}>
                                        {/* <KeyboardArrowLeftIcon sx={{ color: isNextBtnDisabled || isFileViewerOpen || viewLaOnboardingScreen ? palette.greyText2 : palette.orange }} /> */}
                                        <KeyboardArrowLeftIcon sx={{ color: isFileViewerOpen || viewLaOnboardingScreen ? palette.greyText2 : palette.orange }} />
                                    </div>
                                </div>
                                <div style={{ color: isDarkMode ? '#FFF' : viewFullScreen ? '#FFF' : '#000' }}>{activeIndex + 1} of {initialData.Cards.length}</div>

                                {/* <span id={`circular-progressbar`} style={{ display: `${cardsViewedRef.current[activeIndex] ? "none" : "block"}` }}>0%</span> */}

                                <span id={`circular-progressbar`} className={`${classes.circularProgressContainer}`} style={{ display: `${cardsViewedRef.current[activeIndex] ? "none" : "flex"}` }}>
                                    <span className={`${classes.circleText}`} id={`circular-progressbar-text`}>{cardData[activeIndex]?.card.minTimeSpent}</span>
                                    <div className={`${classes.circularProgress}`}>
                                        <svg className={classes.circularProgressSVG} viewBox="0 0 100 100">
                                            <circle className={classes.circularProgressBackground} cx="50" cy="50" r="45" />
                                            <circle className={classes.circularProgressForeground} cx="50" cy="50" r="45" id={`circle_foreground`} style={{ animationDuration: `${cardData[activeIndex]?.card.minTimeSpent}s` }} />
                                        </svg>
                                    </div>
                                </span>

                                <div onClick={handleNextBtnClick} id={`next-btn`} className={`${classes.cursorPointer} {classes.fill}`} style={{ display: `${cardsViewedRef.current[activeIndex] ? "block" : "none"}` }} >
                                    <div className={`${classes.navBtnBox} ${classes.centerFlex}`}>
                                        <KeyboardArrowRightIcon color="disabled" sx={{ color: isNextBtnDisabled || isFileViewerOpen || viewLaOnboardingScreen ? palette.greyText2 : palette.orange }} />
                                    </div>
                                </div>

                                <DocumentsHyperLink />
                                {/* 
                                <div className={classes.cursorPointer}>
                                    <div onClick={() => setIsImageMagnify(prev => !prev)}>
                                        <img src="/images/icons/magnifying-lens.svg" alt="icon" height={22} />
                                    </div>
                                </div> */}

                                <div className={classes.cursorPointer}>
                                    {/* <OpenInFullIcon onClick={() => setViewFullScreen(prev => !prev)} /> */}
                                    <div onClick={() => setViewFullScreen(prev => !prev)}>
                                        {
                                            !viewFullScreen ? (
                                                <img src={`/images/icons/maximize${isDarkMode ? "_white" : "_black"}.png`} alt="icon" height={22} />
                                            ) : (
                                                <img src="/images/icons/minimize_white.png" alt="icon" height={22} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default LearningAidCardsContainer;