import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// components
import CrazyCar from "../../components/gba/crazy-cars";
import TikiMonkey from "../../components/gba/tiki-monkey-new";
import TastyTruth from "../../components/gba/tasty-truth-new";
import Football from "../../components/gba/football";
import SoundsRight from "../../components/gba/sounds-right";
import BalloonPop from "../../components/gba/balloon-pop";
import SpaceRocks from "../../components/gba/space-rocks";
import TrashTrivia from "../../components/gba/trash-trivia";
import BuildingBlocks from "../../components/gba/building-blocks";
import RevealPicture from "../../components/gba/reveal-the-picture";
import DialogAlert from "../../components/dialog-alert";
import GbaPauseOverlay from "../../components/gba/common-screens/pause-overlay";
import StarArcadia from "../../components/gba/star-arcadia";
import GalacticWordSearch from "../../components/gba/galactic-word-search";
import CrackerMania from "../../components/gba/cracker-mania";
import LearnoCasino from "../../components/gba/learno-casino";
import MasterBlaster from "../../components/gba/master-blaster";
import HeaderBackButton from "../../components/layout-elements/header-back-button";
import BucketBlast from "../../components/gba/bucket-blast";
import PopcornMatch from "../../components/gba/popcorn-match";

// redux
import { getChallengeData } from "../../redux/microskill/microskill.api";
import { handleGbaGameState, handleGbaScreenState } from "../../redux/gba/gba-slice";
import { submitEndChallengeAttempt } from "../../redux/gba/gba.api";

// utils
import isEmpty from "../../utils/isEmpty";
import eventBus from "../../utils/eventBus";
import commonUtil from "../../utils/commonUtil";
import { isNativeApp } from '../../utils/commonUtil';

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";

// const HEIGHT_MARGIN = 2;
const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;
const SOUNDS_RIGHT_GRADIENT = 'linear-gradient(#5B0F8E, #E65151)';
const TIKI_MONKEY_GRADIENT = 'linear-gradient(#053E39, #051A19)';
// const TIKI_MONKEY_GRADIENT = 'linear-gradient(#011816, #023A35)';
const BALLOON_POP_GRADIENT = `linear-gradient(#800E31, #C010C6)`;
const SPACE_ROCKS_GRADIENT = `linear-gradient(#5e488b, #370674)`;
// const POPCORN_MATCH_GRADIENT = `url(/images/gba/popcorn_match_bg.jpg)`;
const POPCORN_MATCH_GRADIENT = `linear-gradient(45deg, #E0EEF6, #F0E4E8, #FADACE, #FDDAC6, #FDD2A6)`;
const TRASH_TRIVIA_GRADIENT = `linear-gradient(#707070, #484848)`;
const CRAZY_CAR_GRADIENT = `linear-gradient(#707070, #484848)`;
const BUILDING_BLOCKS_GRADIENT = `linear-gradient(#4577AC, #173A60)`;
const REVEAL_PICTURE_GRADIENT = `linear-gradient(#353146, #0A83B8)`;
const TASTY_TRUTH_GRADIENT = `url('/images/gba/tasty-truth/bg.png')`;
const MASTER_BLASTER_GRADIENT = `linear-gradient(#BFCAE0, #FFE8E3, #FED0C9)`;
const STAR_ARCADIA_GRADIENT = `linear-gradient(#000000, #1C82A8)`; // STAR_ARCADIA
const CRACKER_MANIA_GRADIENT = `linear-gradient(#7F1621, #B221B2)`; // CRACKER_MANIA
const GALACTIC_WORDSEARCH_GRADIENT = `linear-gradient(#000000, #000000)`; // GALACTIC_WORDSEARCH
const LEARNO_CASINO_GRADIENT = `linear-gradient(#185B0B, #185B0B)`; // LEARNO_CASINO
const BUCKET_BLAST_GRADIENT = `url(/images/gba/bucket_blast_wallpaper.jpg)`;
const FOOTBALL_GRADIENT = 'linear-gradient(#C0A45F, #CFB78F, #DAD7CD)';

const GameBasedAssessments = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');

    const gbaScreenState = useSelector(state => state?.gba.screenState);
    const gbaGameState = useSelector(state => state?.gba.gameState);
    const isNativeAppState = useSelector(state => state?.common?.isNativeApp);
    // const isBrowserTabFocused = useSelector(state => state?.common?.isBrowserTabFocused);
    const { microskillId, challengeId } = useParams();
    const [gameData, setGameData] = useState();
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [userMicroskillChallenge, setUserMicroskillChallenge] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isPauseOverlay, setIsPauseOverlay] = useState(false);
    const [dialogContent, setDialogContent] = useState('Unsaved Data');
    const [overlayGradient, setOverlayGradient] = useState('');
    const [dialogueDiscardBtnName, setDialogueDiscardBtnName] = useState('');
    const [dialogueConfirmBtnName, setDialogueConfirmBtnName] = useState('');
    const currentTimeRef = useRef(Date.now());

    const [addPause, setAddPause] = useState(false);

    const { t, i18n } = useTranslation()
    const classes = useStyles({ isMobile, i18n });
    const [fSize, setFSize] = useState(1);

    const backBtnListnerFunction = (data) => {
        handleBackButtonEvent(data.detail.screenStateGba);
    }

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    };

    window.addEventListener("resize", resFont);
    const handleTabChange = () => {
        if (gbaScreenState === GBA_SCREEN_STATE.GAME) {
            console.log('<======= pause the app if running');
            handleBackButtonEvent(GBA_SCREEN_STATE.GAME);
        }
    }

    // event listner for browser tab change
    useEffect(() => {
        if (isNativeAppState) return;
        window.addEventListener('visibilitychange', handleTabChange);

        return () => {
            console.log('remove event listner visibilitychange ========>');
            window.removeEventListener('visibilitychange', handleTabChange);
        }
    }, [isNativeAppState, gbaScreenState])



    // useEffect(() => {
    //     // if (!isBrowserTabFocused && gbaScreenState === GBA_SCREEN_STATE.GAME) handleBackButtonClick();
    // }, [isBrowserTabFocused])

    useEffect(() => {
        eventBus.on(BACK_BTN_EVENT, backBtnListnerFunction);

        return () => {
            eventBus.remove(BACK_BTN_EVENT, backBtnListnerFunction);
            dispatch(handleGbaScreenState(GBA_SCREEN_STATE.START));
            // window.onbeforeunload = "";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChallengeDataAsync = async () => {
        setGameData([])
        const respData = await getChallengeData(microskillId, challengeId);

        if (respData && [200, 201].includes(respData.status)) {

            // const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
            // setAttemptData(attemptResp.data.data);

            if (isEmpty(respData?.data?.data?.gameData?.questions)) {
                setIsDialogOpen(true);
                setDialogueConfirmBtnName('Okay');
                setDialogueDiscardBtnName('');
                setDialogContent(t("No question found for this challenge"));
                return;
            }


            // shuffle questions for MA games
            if (respData?.data?.data?.gameData?.designType === 'MA_SpaceRocks' || respData?.data?.data?.gameData?.designType === 'MA_BalloonPop' || respData?.data?.data?.gameData?.designType === "TF_BuildingBlocks" || respData?.data?.data?.gameData?.designType === "TF_CrazyCars" || respData?.data?.data?.gameData?.designType === "TF_TastyTruth") {
                // handleQuestionShuffle(respData?.data?.data?.gameData);
                setGameData(respData?.data?.data?.gameData);
                setUserMicroskillChallenge(respData?.data?.data?.userMicroskillChallenge);
            } else {
                if (respData?.data?.data?.gameData.designType.includes('MTRR')) {
                    setGameData(getMtrrGameData(respData?.data?.data?.gameData, 'questions'));
                } else {
                    setGameData(respData?.data?.data?.gameData);
                }
                setTotalQuestions(respData?.data?.data?.gameData.questions.length);
                setUserMicroskillChallenge(respData?.data?.data?.userMicroskillChallenge);
            }
        } else {
            setIsDialogOpen(true);
            setDialogueConfirmBtnName('Okay');
            setDialogueDiscardBtnName('');
            setDialogContent(t("Failed to fetch the challenge data"));
        }
    };

    useEffect(() => {
    }, [gbaScreenState])

    const handleQuestionShuffle = (data) => {
        data.questions.forEach(item => {
            item.answers = commonUtil.shuffleArray(item.answers);
        });
        data.questions = commonUtil.shuffleArray(data.questions);
        setGameData(data);
    }

    useEffect(() => {
        if (isEmpty(gameData)) return;
        if (gameData.designType === 'MCQ_TikiMonkey') setOverlayGradient(TIKI_MONKEY_GRADIENT);
        else if (gameData.designType === 'TF_SoundRight') setOverlayGradient(SOUNDS_RIGHT_GRADIENT);
        else if (gameData.designType === 'MA_BalloonPop') setOverlayGradient(BALLOON_POP_GRADIENT);
        else if (gameData.designType === 'MA_SpaceRocks') setOverlayGradient(SPACE_ROCKS_GRADIENT);
        else if (gameData.designType === 'MTRR_TrashTrivia') setOverlayGradient(TRASH_TRIVIA_GRADIENT);
        else if (gameData.designType === 'TF_CrazyCars') setOverlayGradient(CRAZY_CAR_GRADIENT);
        else if (gameData.designType === 'TF_BuildingBlocks') setOverlayGradient(BUILDING_BLOCKS_GRADIENT);
        else if (gameData.designType === 'MA_RevealThePicture') setOverlayGradient(REVEAL_PICTURE_GRADIENT);
        else if (gameData.designType === 'TF_TastyTruth') setOverlayGradient(TASTY_TRUTH_GRADIENT);
        else if (gameData.designType === 'MCQ_MasterBlaster') setOverlayGradient(MASTER_BLASTER_GRADIENT);
        else if (gameData.designType === 'MCQ_StarArcadia') setOverlayGradient(STAR_ARCADIA_GRADIENT);
        else if (gameData.designType === 'MTRR_CrackerMania') setOverlayGradient(CRACKER_MANIA_GRADIENT);
        else if (gameData.designType === 'MTRR_BucketBlast') setOverlayGradient(BUCKET_BLAST_GRADIENT);
        else if (gameData.designType === 'MA_WordSearch') setOverlayGradient(GALACTIC_WORDSEARCH_GRADIENT);
        else if (gameData.designType === 'MTRR_LearnoCasino') setOverlayGradient(LEARNO_CASINO_GRADIENT);
        else if (gameData.designType === 'MTRR_PopcornMatch') setOverlayGradient(POPCORN_MATCH_GRADIENT);
        else if (gameData.designType === 'MCQ_Football') setOverlayGradient(FOOTBALL_GRADIENT);
    }, [gameData])

    useEffect(() => {
        getChallengeDataAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeId]);

    const handleBackButtonEvent = (screenState) => {
        let screen = isEmpty(screenState) ? gbaScreenState : screenState;

        if (screen === GBA_SCREEN_STATE.GAME) {
            setIsPauseOverlay(true);
        } else {
            // navigate to challenge list if not in game screen
            navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
        }
    }

    const handleBackButtonClick = () => {
        console.log('gbaScreenState', gbaScreenState);
        if (gbaScreenState === GBA_SCREEN_STATE.START) {
            // if game is exited from start screen only
            submitEndChallengeAttempt({
                ...gbaGameState,
                status: constants.GBA_ILA_STATUS.exited,
                completion: false,
                timespent: 0
            });
            // navigate(-1);
            navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
            return
        }
        eventBus.dispatch(BACK_BTN_EVENT,
            { screenStateGba: gbaScreenState }
        );
    }

    useEffect(() => {
        const pauseInterval = setInterval(() => {
            if (window.pausedApp) {
                handleBackButtonEvent(GBA_SCREEN_STATE.GAME);
                // setAddPause(false);
            }

            let timeSpent = Math.floor((Date.now() - currentTimeRef.current) / 1000);
            if ((window.killedApp || timeSpent > 1800)) {
                dispatch(handleGbaScreenState(constants.GBA_SCREEN_STATE.START));
                submitEndChallengeAttempt({
                    ...gbaGameState,
                    status: constants.GBA_ILA_STATUS.exited,
                    completion: false,
                    timespent: gbaGameState.timespent || 0
                });
                clearInterval(pauseInterval)
                // setAddPause(false);
            }
        }, 1000); // Check every second

        return () => clearInterval(pauseInterval); // Cleanup interval on component unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.pausedApp, window.killedApp]);

    const handleGbaState = (state) => {
        if (state === 'GAME') {
            setAddPause(true);
        } else {
            setAddPause(false);
        }
        dispatch(handleGbaScreenState(state));
    }

    const handleConfirmBtnClick = () => {
        setIsDialogOpen(false);
        submitEndChallengeAttempt({
            ...gbaGameState,
            status: constants.GBA_ILA_STATUS.exited,
            completion: false,
            timespent: gbaGameState.timespent || 0
        });
        navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
        dispatch(handleGbaScreenState(constants.GBA_SCREEN_STATE.START));
    }

    const handleOverlayResumeClick = () => {
        setIsPauseOverlay(false);
    }

    const handleOverlayChallengeListClick = () => {
        setIsDialogOpen(true);
        setDialogueConfirmBtnName('Yes');
        setDialogueDiscardBtnName('No');
        setDialogContent(t("Going back will lead to loss of data. Do you really want to exit?"))
    }

    const getMtrrGameData = (data, shuffleOn) => {
        let shuffledData = data[shuffleOn];
        // let shuffledData = commonUtil.shuffleArray(data[shuffleOn]);
        // let shuffledData = commonUtil.shuffleArray(data[shuffleOn].slice(0, 3));
        let length = shuffledData.length;
        let tempDataArray = [];
        shuffledData.forEach(item => {
            let optionData = {
                ...item,
                isViewed: false,
            }
            let temp = {
                context: item.context,
                contextId: item.contextId,
                options: [optionData],
                totalOptions: [optionData],
                length: length
            }
            let sameContextIndex = tempDataArray.findIndex(d => d.contextId === item.contextId);
            if (isEmpty(tempDataArray) || sameContextIndex === -1) tempDataArray.push(temp);
            else {
                let optionValue = tempDataArray[sameContextIndex].options;
                let sameOptionIndex = optionValue.findIndex(m => m.answer.toUpperCase().trim() === item.answer.toUpperCase().trim());
                if (sameOptionIndex === -1) {
                    tempDataArray[sameContextIndex].options.push(optionData);
                }
                tempDataArray[sameContextIndex].totalOptions.push(optionData);
            }
        });
        if (tempDataArray[0].options.length === 2) {
            tempDataArray.forEach(m => {
                let index = '';
                m.options.forEach(d => {
                    index = m.totalOptions.findIndex(a => a.answer.toUpperCase().trim() === d.answer.toUpperCase().trim())
                });
                if (index !== -1) {
                    let removedElement = m.totalOptions.splice(index, 1)[0];
                    m.totalOptions.unshift(removedElement);
                }
            });
        }
        return {
            questionData: tempDataArray,
            designType: data.designType,
            gameType: data.gameType,
            setting: data.setting,
        }
    }

    const font_size = () => {
        let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
        return langObj.font
    }

    return (
        <div className={classes.gbaRootWrapper}>
            {
                isMobile && (
                    <div className={classes.gbaHeader} style={{ gridTemplateColumns: !isPauseOverlay && gbaScreenState !== GBA_SCREEN_STATE.WIN ? '12% 88%' : '' }} >

                        {!isPauseOverlay &&
                            gbaScreenState !== GBA_SCREEN_STATE.WIN && <HeaderBackButton onClick={handleBackButtonClick} />}
                        <div className={classes.headerName}>{queryParams.get("name")}</div>
                    </div>
                )
            }

            <div className={classes.gbaRoot}
                style={isNativeApp() ? { ...constants.FULL_HEIGHT_CENTER_NATIVE, fontSize: font_size() }
                    : (isMobile ? {
                        height: `calc(${window.innerHeight}px - ${constants.HEADER_HEIGHT})`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: font_size()
                    } : { ...constants.FULL_HEIGHT_CENTER, fontSize: font_size() })
                }
            >
                {
                    !isMobile && (
                        !isPauseOverlay && (
                            gbaScreenState !== GBA_SCREEN_STATE.WIN && (
                                <div className={`${classes.backBtnBox} ${classes.centerFlex}`} onClick={handleBackButtonClick}>
                                    <KeyboardBackspaceIcon sx={{ color: "#FFF" }} />
                                </div>
                            )
                        )
                    )
                }

                <div className={classes.gbaBox}
                    // style={{ height: `calc(${constants.FULL_HEIGHT_VALUE} - ${isMobile ? '0' : HEIGHT_MARGIN}rem)` }}
                    style={{ fontSize: `${fSize}em` }}
                >
                    {
                        isEmpty(gameData) ? (
                            <div style={constants.FULL_HEIGHT_CENTER}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                {
                                    gameData.designType === 'MCQ_MasterBlaster' && (
                                        <MasterBlaster
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }
                                {
                                    gameData.designType === 'MCQ_Football' && (
                                        <Football
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MCQ_TikiMonkey' && (
                                        <TikiMonkey
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MA_BalloonPop' && (
                                        <BalloonPop
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MA_SpaceRocks' && (
                                        <SpaceRocks
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MTRR_PopcornMatch' && (
                                        <PopcornMatch
                                            gameData={gameData}

                                            // gameData={getMtrrGameData(gameData, 'questions')}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MTRR_TrashTrivia' && (
                                        <TrashTrivia
                                            gameData={gameData}
                                            // gameData={getMtrrGameData(gameData, 'questions')}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'TF_CrazyCars' && (
                                        <CrazyCar
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            userMicroskillChallenge={userMicroskillChallenge}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'TF_TastyTruth' && (
                                        <TastyTruth
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'TF_SoundRight' && (
                                        <SoundsRight
                                            gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'TF_BuildingBlocks' && (
                                        <BuildingBlocks gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MA_RevealThePicture' && (
                                        <RevealPicture gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MCQ_StarArcadia' && (
                                        <StarArcadia gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            challengeData={userMicroskillChallenge}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MTRR_CrackerMania' && (
                                        <CrackerMania
                                            gameData={gameData}
                                            // gameData={getMtrrGameData(gameData, 'questions')}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            // challengeData={userMicroskillChallenge}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MTRR_BucketBlast' && (
                                        <BucketBlast
                                            gameData={gameData}
                                            // gameData={getMtrrGameData(gameData, 'questions')}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            // challengeData={userMicroskillChallenge}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }


                                {
                                    gameData.designType === 'MTRR_LearnoCasino' && (
                                        <LearnoCasino
                                            gameData={gameData}
                                            // gameData={getMtrrGameData(gameData, 'questions')}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    gameData.designType === 'MA_WordSearch' && (
                                        <GalacticWordSearch gameData={gameData}
                                            handleGbaState={handleGbaState}
                                            isPauseOverlay={isPauseOverlay}
                                            defaultGradient={overlayGradient}
                                            challengeData={userMicroskillChallenge.scored}
                                            totalQuestions={totalQuestions}
                                            setAddPause={setAddPause}
                                        />
                                    )
                                }

                                {
                                    isPauseOverlay && (
                                        <GbaPauseOverlay
                                            gradient={overlayGradient}
                                            handleResumeClick={handleOverlayResumeClick}
                                            handleChallengeListClick={handleOverlayChallengeListClick}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>

                {
                    isDialogOpen && (
                        <DialogAlert
                            isOpen={isDialogOpen}
                            content={dialogContent}
                            confirmBtnName={dialogueConfirmBtnName}
                            discardBtnName={dialogueDiscardBtnName}
                            handleDiscardBtnClick={() => setIsDialogOpen(false)}
                            handleConfirmBtnClick={handleConfirmBtnClick}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default GameBasedAssessments;