import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
// import moment from "moment";

// MUI Components
import { Button, CircularProgress, Dialog } from "@mui/material";
import { AccessAlarm } from "@mui/icons-material";

// Utils
import isEmpty from "../../utils/isEmpty";
// import commonUtils from "../../utils/commonUtil.js";
// import utilityFunctions from "../../utils/utilityFunctions.js";

// API's
import { fetchFormData, startFormAttempt, submitEndFormAttempt } from "../../redux/form/form.api";

// Styles
import useStyles from "./index.styles";

// Components
import FormInfoContainer from "./form-info-container";
import FormContentContainer from "./form-content-container";
import DialogAlert from "../dialog-alert";
import FormsWinScreen from "./win-screen/index.js";
import FormsLooseScreen from "./lose-screen/index.js";

// Constants
import constants from "../../constants";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";

//redux 
import { setFormIdData, setIsFormOpen } from "../../redux/form/form-slice.js";
import { useSearchParams } from "react-router-dom";

const FORM_SCREENS = {
    STARTED: "STARTED",
    WIN: "WIN",
    LOOSE: "LOOSE",
    TIMEOUT: "TIMEOUT",
}

const FormAsChallenge = (props) => {
    const { setFormSubmitted } = props
    // const { formId, setFormId, isDialog, openForm, setOpenForm, isChallenge, challengeId, microskillId } = props;

    const isChallenge = true;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [searchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');

    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);

    const { microskillId,
        challengeId,
        formId, } = useParams()

    const [loading, setLoading] = React.useState(true);

    const [formName, setFormName] = React.useState("");
    const [formDescription, setFormDescription] = React.useState("");
    const [isAssessment, setIsAssessment] = React.useState(false);
    const [questions, setQuestions] = React.useState([]);
    const [totalTime, setTotalTime] = React.useState(0);
    const [showResult, setShowResult] = React.useState(false);
    const [minScore, setMinScore] = React.useState(0);
    const [contexts, setContexts] = React.useState([]);
    const [score, setScore] = React.useState(0);
    const [maxScore, setMaxScore] = React.useState(0);
    const [totalMandatoryQuestions, setTotalMandatoryQuestions] = React.useState(0);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false);
    const [isFormCompleted, setIsFormCompleted] = React.useState(false);
    const [dialogDiscardBtn, setDialogDiscardBtn] = React.useState('');
    const [isTimeUp, setIsTimeUp] = React.useState(false);


    const [attemptId, setAttemptId] = React.useState("");
    const [formData, setFormData] = React.useState([]);
    // let attemptLevelData = useRef([]);
    const [attemptLevelData, setAttemptLevelData] = React.useState([]);

    // Timer
    const formTimerRef = React.useRef(null);
    const timeLimitRef = React.useRef(0);
    const timeSpentRef = React.useRef(0);

    // Dialog Alert
    const DIALOG_TEXT = {
        WARNING: `Are you sure you want to exit? Your progress will be lost.`,
        ERROR: `Something went wrong. Please try again later.`
    }
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState(DIALOG_TEXT.WARNING);
    const [dialogConfirmBtn, setDialogConfirmBtn] = React.useState("");

    // Form Submission Screen for Assesments
    const [showLooseScreen, setShowLooseScreen] = React.useState(false);

    // Styles
    const classes = useStyles();

    // Screen Resize Event handling
    const initialHeight = window.innerHeight;
    const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(false);

    const openDialogFunc = (text, confBtnTxt, discBtnTxt) => {
        setDialogContent(text);
        setDialogConfirmBtn(confBtnTxt);
        setDialogDiscardBtn(discBtnTxt);
        setIsDialogOpen(true);
    }

    const fetchFormDataUsingFormId = async (formId) => {
        const respData = await fetchFormData(formId);

        if (respData && [200, 201].includes(respData.status)) {
            const formData = respData.data.data;
            const formDataFromAPI = formData.Form;

            setFormName(formDataFromAPI.name);
            setFormDescription(formDataFromAPI.description);
            setIsAssessment(formDataFromAPI.assessment || false);
            setShowResult(formDataFromAPI.showScore)

            setQuestions(formDataFromAPI.Questions || []);

            // score, max score and mandatory questions
            setScore(formData.score || 0);
            setMaxScore(formData.maxScore || 0);
            setTotalMandatoryQuestions(formData.mandatoryQuestions || 0);

            // total time
            setTotalTime(formDataFromAPI.timeLimit || 0);

            // minimum score and contexts
            setMinScore(formDataFromAPI.settings?.minScore || 0);
            setContexts(formDataFromAPI.contexts || []);

            if (!formData.completed) {
                getAttemptIdForForm();
            } else {
                handleFormAlreadySubmitted();
            }
        } else {
            const errorMsg = respData?.response?.data?.errors[0]?.message;
            openDialogFunc(
                errorMsg || DIALOG_TEXT.ERROR, // dialog content/text
                isChallenge ? "Challenge List" : "Okay", // confirm btn text
                "" // diacard btn text
            )
        }

    }

    const handleFormAlreadySubmitted = () => {
        setIsFormSubmitted(true);
        setIsFormCompleted(true);
        setFormSubmitted(true);
    }

    const getAttemptIdForForm = async () => {
        let body = {
            formId
        };

        body.challengeId = challengeId;
        body.microskillId = microskillId;

        const resp = await startFormAttempt(body);
        if (resp.status === 200 || resp.status === 201) {
            const attemptId = resp.data.data.id;
            setAttemptId(attemptId);
        }
    }

    const startFormTimer = () => {
        if (isFormSubmitted) return;
        console.log("startFormTimer -> totalTime", totalTime, timeSpentRef.current)
        clearInterval(formTimerRef.current);
        timeSpentRef.current = 0;
        if (isAssessment) {
            formTimerRef.current = setInterval(() => {
                if (!isEmpty(timeLimitRef.current)) timeLimitRef.current.innerHTML = totalTime - timeSpentRef.current;
                timeSpentRef.current += 1;

                if (timeSpentRef.current > totalTime) {
                    setIsFormSubmitted(true);
                    setShowLooseScreen(true);
                    setFormSubmitted(true);
                    setIsTimeUp(true);
                    clearInterval(timeLimitRef.current);
                }
            }, 1000);
        } else {
            formTimerRef.current = setInterval(() => {
                if (!isEmpty(timeLimitRef.current)) timeLimitRef.current.innerHTML = timeSpentRef.current;
                timeSpentRef.current += 1;
            }, 1000);
        }
    }

    const submitForm = async () => {

        clearInterval(formTimerRef.current);

        let completion = true;
        let status = "COMPLETED";

        let scoreObtained = 0;

        attemptLevelData.forEach(data => {
            const correctAnswersCount = data.answers.filter(answer => answer.isCorrect === true).length;
            const questionScore = questions.find(ques => ques.id === data.questionId).scorePerResponse;
            scoreObtained += (correctAnswersCount * questionScore);
        });

        setScore(scoreObtained);
        if (isAssessment && (scoreObtained < minScore || isTimeUp)) {
            completion = false;
            status = isTimeUp ? 'TIMERLOSE' : 'FAILED';
            setShowLooseScreen(true);
        }

        let body = {
            formId,
            attemptId,
            completion,
            scored: scoreObtained,
            status,
            timespent: timeSpentRef.current,
            questions: attemptLevelData
        };

        if (isChallenge) {
            body.challengeId = challengeId
            body.microskillId = microskillId
        }

        const resp = await submitEndFormAttempt(body);
        if (resp.status !== 200 && resp.status !== 201) {
            openDialogFunc(DIALOG_TEXT.ERROR, `Okay`, ``);
        }
    };

    const markFormAttemptExited = async () => {
        clearInterval(formTimerRef.current);
        if (isEmpty(attemptId)) return;
        let body = {
            formId,
            attemptId,
            completion: false,
            scored: 0,
            status: "EXITED",
            timespent: totalTime - timeLimitRef.current,
            questions: []
        };

        if (isChallenge) {
            body.challengeId = challengeId
            body.microskillId = microskillId
        }

        const resp = await submitEndFormAttempt(body);
        if (resp && [200, 201].includes(resp.status)) {
            clearOnUmMount();
        } else {
            openDialogFunc(DIALOG_TEXT.ERROR, `Okay`, ``);
        }
    }

    const handleNextButtonClickOnForm = () => {
        clearOnUmMount();
    }

    const handleCloseForm = async () => {
        if (isChallenge) {
            if (isFormSubmitted) {
                clearOnUmMount();
                navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
                // window.location.replace(`/challenge-list/${microskillId}`);
                return
            }
            openDialogFunc(DIALOG_TEXT.WARNING, `Yes`, `No`)
        } else {
            if (isFormSubmitted) {
                window.location.reload();
                return;
            }
            openDialogFunc(DIALOG_TEXT.WARNING, `Yes`, ``)
            await markFormAttemptExited();
        }
    }

    const handleDialogAlertClose = async () => {
        if (!isFormSubmitted) {
            if (isChallenge) {
                await markFormAttemptExited();
                clearOnUmMount();
                setIsDialogOpen(false);
                navigate(`/challenge-list/${microskillId}${!isEmpty(journeyId) ? `?journeyId=${journeyId}` : ''}`);
            }
            else {
                setIsDialogOpen(false);
                clearOnUmMount();
            }
        }
    }

    const handleErrorAttemptDataSubmit = () => {
        setLoading(true);
        clearOnUmMount();
        openDialogFunc(DIALOG_TEXT.ERROR, `Okay`, ``);
    }

    const clearOnUmMount = () => {
        setLoading(true);
        dispatch(setFormIdData(null));
        // setFormId("");
        setIsTimeUp(false);
        // setOpenForm(false);
        dispatch(setIsFormOpen(false));
        setFormName("");
        setFormDescription("");
        setTotalTime(0);
        setShowResult(false);
        setMinScore(0);
        setTotalMandatoryQuestions(0);
        setSubmitBtnDisabled(false);
        clearInterval(formTimerRef.current);
        formTimerRef.current = null;
        setIsFormSubmitted(false);
        setFormSubmitted(false);
        setIsAssessment(false);
        setQuestions([]);
        setContexts([]);
        setAttemptId("");
        setFormData([]);
        setAttemptLevelData([]);
    }

    const handleSetAttemptLevelData = (completeAttemptData) => {
        setAttemptLevelData(completeAttemptData);
    }

    const handleTryAgain = () => {
        setLoading(true);
        getAttemptIdForForm();
        setFormData([]);
        setAttemptLevelData([]);
        setIsFormSubmitted(false);
        setFormSubmitted(false);
        setShowLooseScreen(false);
        setIsTimeUp(false);
        if (isAssessment) {
            startFormTimer();
        }
    }

    const handleSubmitBtnDisabled = () => {
        const mandatoryAnsweredCount = attemptLevelData.reduce((count, data) => {
            return data.isMandatory && !isEmpty(data.answers) ? count + 1 : count;
        }, 0);

        setSubmitBtnDisabled(mandatoryAnsweredCount !== totalMandatoryQuestions);
    };

    // Screen Resize Event handling
    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < initialHeight) {
                setIsKeyboardOpen(true);
            } else {
                setIsKeyboardOpen(false);
            }
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [initialHeight]);

    useEffect(() => {
        if (!isEmpty(attemptLevelData) && totalMandatoryQuestions > 0) {
            handleSubmitBtnDisabled();
        }

    }, [attemptLevelData, totalMandatoryQuestions]);

    useEffect(() => {
        const fetchData = async () => {
            await fetchFormDataUsingFormId(formId);
        }

        if (!isEmpty(formId)) {
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId]);

    useEffect(() => {
        // Clear any existing interval
        clearInterval(formTimerRef.current);

        const handleSubmit = async () => {
            if (isFormSubmitted && !isFormCompleted) {
                await submitForm();
            }
        };

        handleSubmit();
    }, [isFormSubmitted, isFormCompleted]);

    useEffect(() => {
        if (!isEmpty(formId) && (!isEmpty(attemptId) || isFormCompleted) && isEmpty(formData)) {

            if (!isEmpty(questions)) {
                const temporaryAttemptData = questions.map((question) => {
                    return { questionId: question.id, answers: [], contextId: question.contextId || "", sequence: question.sequence, isMandatory: question.mandatory };
                });

                setAttemptLevelData(temporaryAttemptData);
            }

            if (!isEmpty(questions)) {
                const contextIds = contexts.map(context => context.id);

                // filter questions which do not have context
                const questionsWithoutContext = questions.filter(question => !contextIds.includes(question.contextId));
                const questionsWithContext = questions.filter(question => contextIds.includes(question.contextId));

                const tempFormData = [];
                if (!isEmpty(questionsWithoutContext)) {
                    tempFormData.push({ contextId: "", context: "", questions: questionsWithoutContext });
                }

                if (!isEmpty(questionsWithContext)) {
                    contexts.forEach(context => {
                        const contextQuestions = questionsWithContext.filter(question => question.contextId === context.id);
                        tempFormData.push({ contextId: context.id, context: context.context, questions: contextQuestions });
                    });
                }

                setFormData({ formId, attemptId, challengeId, microskillId, contexts: tempFormData });
            }
        }
        else if (!isEmpty(formId) && !isEmpty(attemptId) && !isEmpty(formData)) {
            setLoading(false);
            if (isAssessment) {
                startFormTimer();
            }
        } else if (!isEmpty(formData) && isFormSubmitted) {
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, contexts, formId, attemptId, formData]);

    const handleDialogDiscardClick = () => {
        setIsDialogOpen(false);
    }

    return (
        <div className={classes.root}>
            {loading ? (
                <div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                    <CircularProgress />
                </div>
            ) : (
                !isFormSubmitted ?
                    <div className={classes.formContainer} style={{
                        height: (isMobile || isTablet) && isKeyboardOpen ? "calc(100dvh - 4rem)" : "100%",
                        fontSize: () => {
                            let langObj = constants.LANGUAGES_LIST.filter(e => e.key === i18n.language)[0]
                            return langObj.font;
                        },
                    }}>
                        <div className={`${classes.centerFlex} ${classes.iconContainer}`}>
                            {
                                isAssessment ? (
                                    <div className={`${classes.timerContainer} ${classes.centerFlex}`}>
                                        <AccessAlarm style={{ color: '#F4511E', margin: 'auto' }} />
                                        <div className={classes.timerDiv}>
                                            <span style={{ fontSize: '1.2rem' }} ref={timeLimitRef}>{totalTime}</span>
                                            <span style={{ fontSize: '1.2rem' }}>s</span>
                                        </div>
                                    </div>
                                ) : ''
                            }
                        </div>
                        <div className={classes.scrollingContainer}>
                            <FormInfoContainer
                                formName={formName}
                                formDescription={formDescription}
                                isAssessment={isAssessment}
                                ref={timeLimitRef}
                                totalTime={totalTime}
                            />

                            <FormContentContainer
                                formData={formData}
                                attemptLevelData={attemptLevelData}
                                handleSetAttemptLevelData={handleSetAttemptLevelData}
                                handleErrorAttemptDataSubmit={handleErrorAttemptDataSubmit}
                            />
                        </div>

                        <div className={`${classes.centerFlex} ${classes.formSubmitContainer}`}>
                            <Button
                                className={classes.formSubmitButton}
                                variant="contained"
                                disabled={submitBtnDisabled}
                                onClick={() => {
                                    if (!submitBtnDisabled) {
                                        setIsFormSubmitted(true);
                                        setFormSubmitted(true);
                                    }
                                }}

                                sx={{
                                    background: submitBtnDisabled ? "#787878" : "#F4511E"
                                }}
                            >
                                {t(`Submit`)}
                            </Button>
                        </div>
                    </div>
                    :
                    <>
                        {
                            showLooseScreen ? (
                                <FormsLooseScreen
                                    name={formName}
                                    description={formDescription}
                                    formData={formData}
                                    attemptData={attemptLevelData}
                                    handleCloseForm={handleCloseForm}
                                    isChallenge={isChallenge}
                                    handleTryAgain={handleTryAgain}
                                    showResult={showResult}
                                    score={score}
                                    maxScore={maxScore}
                                    isTimeUp={isTimeUp}
                                />
                            ) : (
                                <FormsWinScreen
                                    name={formName}
                                    description={formDescription}
                                    formData={formData}
                                    attemptData={attemptLevelData}
                                    handleCloseForm={handleCloseForm}
                                    isChallenge={isChallenge}
                                    showResult={showResult}
                                    score={score}
                                    maxScore={maxScore}
                                    challengeId={challengeId}
                                    setIsDialogOpen={setIsDialogOpen}
                                    handleNextButtonClickOnForm={handleNextButtonClickOnForm}
                                    journeyId={journeyId}
                                />
                            )
                        }
                    </>
            )
            }
            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={dialogConfirmBtn}
                        discardBtnName={dialogDiscardBtn}
                        handleConfirmBtnClick={handleDialogAlertClose}
                        handleDiscardBtnClick={handleDialogDiscardClick}
                    />
                )
            }
        </div>
    )
}

export default FormAsChallenge;