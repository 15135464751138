import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import constants from "../../constants";

const CRAD_BORDER_RADIUS = '0.4rem';

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridCenter: {
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    learningAidContainer: {
        // overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.headerBg,
        '&:focus-visible': {
            outline: 'none'
        },
        overflow: 'hidden',
        height: '100dvh',
        [theme.breakpoints.up('md')]: {
            height: `calc(${constants.FULL_HEIGHT_VALUE})`
        }
    },
    learningAidBox: {
        display: 'grid',
        gridTemplateColumns: '18% 82%',
        height: '100%',
        width: '100%',
        padding: '1rem 1rem 1rem 1rem',
        // backgroundColor: theme.palette.laBoxBg,
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: 'none',
            gridTemplateRows: `${constants.HEADER_HEIGHT} calc(100% - ${constants.HEADER_HEIGHT})`,
            padding: '0',
            width: '100%'
        }

    },
    section: {
        // background: '#FFFFFF7a'
    },
    sectionOne: {
        // backgroundColor: 'grey'
        background: theme.palette.mobilePerfPageCardsBg,
        padding: '1.5rem 1.5rem 0 1.5rem',
        height: '100%',
        display: 'grid',
        gridTemplateRows: `${constants.COLLAPSED_DRAWER_WIDTH} calc(100% - ${constants.COLLAPSED_DRAWER_WIDTH})`,
        borderRadius: '10px',
        overflow: 'hidden',
        [theme.breakpoints.down("md")]: {
            background: 'none',
            padding: '0',

        }
    },
    sectionTwo: {
        // backgroundColor: 'green',
        position: 'relative',
        overflow: 'hidden',
        padding: '0rem 2rem 0rem 2rem',
        touchAction: 'none',
        [theme.breakpoints.down("md")]: {
            padding: '0',
            height: '100%',
        }
    },
    customToastContainer: {
        position: 'absolute',
        top: '1%',
        left: '50%',
        transform: 'translate(-50%, -150%)',
        width: '70%',
        zIndex: '1200',
        padding: '1rem',
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.white,
        boxShadow: theme.shadows[5],
        transition: 'all 0.3s ease-in-out',
    },
    customToastText: {
        color: theme.palette.greyText4,
        fontSize: '1rem',
    },
    thumbCards: {
        // height: '1400px',
        height: '100%',
        overflow: 'auto',
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
            display: 'flex'
        }
    },
    card: {
        // height: '100px',
        // background: '#FFF',
        position: 'relative',
        margin: '14px auto',
        cursor: 'pointer',
        borderRadius: `${CRAD_BORDER_RADIUS}`,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        aspectRatio: '16/9',
        width: '100%',
        [theme.breakpoints.down("md")]: {
            // height: 'auto',
            // width: '30px',
            height: '2rem',
            margin: '0 0.2rem',
            padding: '0 0.2rem',
            aspectRatio: '3/4',
            borderRadius: `calc(${CRAD_BORDER_RADIUS} / 2)`,
        }
    },
    cardBeforeElement: {
        inset: '0 0 0 0',
        backgroundSize: 'cover',
        position: 'absolute',
        backgroundPosition: 'center',
        zIndex: 1,
        filter: 'blur(12px)',
        content: '""',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'inherit',
        overflow: 'hidden',
        borderRadius: `${CRAD_BORDER_RADIUS}`,
        [theme.breakpoints.down("md")]: {
            borderRadius: `calc(${CRAD_BORDER_RADIUS} / 2)`,
        }
    },
    backBtn: {
        height: 'auto',
        aspectRatio: 'auto',
        width: '100%',
        background: 'none !important',
        margin: '0'
    },
    backBtnText: {
        // paddingLeft: '0.1rem',
        fontWeight: '700'
    },
    lockOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 3,
        // backgroundColor: '#00000054',
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'transparent',
        }
    },
    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 2,
        overflow: 'hidden',
        borderRadius: `${CRAD_BORDER_RADIUS}`,
        // backdropFilter: 'blur(4px)',
        [theme.breakpoints.down("sm")]: {
            // backdropFilter: 'blur(1px)',
        }
    },
    lockImage: {
        position: 'relative',
        zIndex: '2',
        filter: theme.palette.mode === 'dark' ? 'invert(0)' : 'invert(1)', // to change color of lock icon in light mode
    },
    sideThumb: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        position: 'relative',
        zIndex: 2
    },
    ilaImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        overflow: 'hidden',
        position: 'relative',
        zIndex: '1',
        borderRadius: `${CRAD_BORDER_RADIUS}`,
        [theme.breakpoints.down("sm")]: {
            objectFit: 'contain',
        }
    },
    contextIlaBox: {
        position: 'relative',
        height: '100%',
        width: '100%',
        zIndex: '2',
    },
    contextSideImg: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '50%',
        height: '100%',
        objectFit: 'cover',
        zIndex: '2',
        [theme.breakpoints.down("sm")]: {
            objectFit: 'contain',
        }
    },

    customLaHeader: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '15% 70% 15%', // for audio player box
        // gridTemplateColumns: '15% 64% 20%', // for timer box 
        alignItems: 'center'
    },
    backButtonBox: {
        // height: '3rem',
        // width: '3rem',
        // borderRadius: '50%'
    },
    backButton: {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: '#f4511e2b'
    },
    timeButtonBox: {

    },
    timeButton: {
        gap: '0.5rem'
    },
    timeText: {
        color: palette.orange,
        fontWeight: 600
    },
    sidebarBox: {
        display: 'flex'
    },
    cardContainer: {
        position: 'relative',

        // background: 'pink',
        // padding: '0.5rem',
        // border: '5px solid black',

        [theme.breakpoints.down("md")]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    thumbnailBarBox: {
        position: 'relative',
        display: 'grid',
        // gridTemplateRows: '50% 50%',
        height: '100%',
        alignItems: 'center',
    },
    thumbnailBar: {
        width: '1.2rem',
        // backgroundColor: theme.palette.lightGrey1,
        backgroundColor: `${theme.palette.fontColor}42`,
        height: '0.4rem',
        margin: '0 0.3rem',
        borderRadius: '1rem',
        transition: 'all 0.4s ease, background 0.2s linear',
        position: 'relative',
    },
    minTimeProgress: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '0%',
        height: '100%',
        borderRadius: '1rem',
        // backgroundColor: "green",
        // backgroundColor: theme.palette.orange,
        transition: '1s ease-in-out',

    },
    lockImageOnBar: {
        position: 'absolute',
        left: '50%',
        top: '16%',
        transform: 'translateX(-50%)',
        filter: theme.palette.mode === 'dark' ? 'invert(0)' : 'invert(1)', // to change color of lock icon in light mode
    },

    timerBoxRoot: {
        position: 'absolute',
        top: '-3rem',
        zIndex: '9999',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    timerBox: {
        gap: '1rem',
        [theme.breakpoints.down("sm")]: {
            gap: '0.3rem',
        }
    },
    timerText: {
        color: palette.orange,
        fontSize: '2rem',
        fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.1rem',
            fontWeight: 500,
        }
    },
    correctIncorrectBox: {
        fontWeight: '800',
        color: '#FFF',
        fontSize: '2.5rem',
        bottom: '0rem',

    },
    skipButtonBox: {
        position: 'absolute',
        bottom: '2rem',
        right: 0,
        zIndex: 10
    },
    skipBtn: {
        border: '2px solid #fff !important',
        borderRight: 'none !important',
        color: `${palette.ilaBg} !important`,
        borderRadius: '2rem 0 0 2rem !important',
        fontWeight: '700 !important',
        fontSize: '1.2rem !important',
        fontFamily: 'Open sans !important',
        paddingLeft: '1.6rem !important',
        // paddingRight: '1.6rem !important',
        backgroundColor: `#fff !important`
    },

    // Laonboarding
    laOnboardingRoot: {
        position: 'absolute',
        inset: 0,
        [theme.breakpoints.up("sm")]: {
            height: '87%',
            width: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '0.8rem',
            borderRadius: '10px',
            overflow: 'hidden'
        }
    },
    onBoardingMain: {
        position: 'relative',
        height: '100%',
        cursor: 'pointer',
        backgroundColor: '#000000a6',
        color: theme.palette.white,
        [theme.breakpoints.up("sm")]: {
            borderRadius: '10px'
        }
    },
    onBoardingScreen1: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        height: '100%',
    },
    onBoardingSide1: {
        borderRight: '3px dashed #fff',
        gap: '0.5rem'
    },
    onBoardingSide2: {
        gap: '0.5rem'
    },
    onBoardingScreen2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '1rem',
        gap: '1rem'
    },
    backDropFilter: {
        position: 'absolute',
        inset: 0,
        backdropFilter: 'blur(9px)',
        // background: 'black',
        opacity: '0.7',
    },
    onBoardingScreen3: {
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center',
        paddingRight: '1rem',
        fontSize: '1.2rem',
        gap: '1rem',
        height: '100%',
        fontWeight: 600,
    },
    // Laonboarding


    // voice over player btn
    voiceOverPlayerBtn: {
        padding: '0.5rem',
        borderRadius: '50%',
        backgroundColor: `rgba(255, 255, 255, 0.75)`,
    },

}))

export default useStyles