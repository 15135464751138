import { createSlice } from '@reduxjs/toolkit';
import constants from '../../constants';

const initialState = {
  // MODULE_LIST: null,
  screenState: constants.SPOTLIGHT_SCREEN_STATE.SCENARIO,
  recordedVideoBlob: null,
};

const spotlightSlice = createSlice({
  name: 'spotlight',
  initialState,
  reducers: {
    handleSpotlightScreenState: (state, action) => {
      state.screenState = action.payload;
    },
    handleRecordedVideoBlob: (state, action) => {
      state.recordedVideoBlob = action.payload;
    },
  },
});

export const {
  handleSpotlightScreenState,
  handleRecordedVideoBlob
} = spotlightSlice.actions;

export default spotlightSlice.reducer;
