import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// styles
import useStyles from './index.styles';
import { Box, Button, CircularProgress, styled } from '@mui/material';

// material-ui 
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import isEmpty from '../../../../utils/isEmpty';
import Lottie from 'react-lottie-player';

//redux 
import { setFormIdData, setIsFormOpen } from "../../../../redux/form/form-slice";
import { postAllSearchedData } from '../../../../redux/microskill/microskill.api';

//lottie-assets 
import NO_DATA_FOUND from '../../../../lottie-assets/common/no-data-found.json';
import theme from '../../../../theme';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.Mui-selected": {
        backgroundColor: '#F4511E',
        border: '2px solid #F4511E',
        borderRadius: '2rem',
        fontWeight: '700',
        width: '100%',
        color: '#fff',
    },
    "&.Mui-selected:hover": {
        backgroundColor: '#F4511E',
    }
}
));

export const SELECTED_SEARCHED_DATA = {
    MICROSKILLS: {
        TYPE: 'Microskill',
        KEY: 'name',
    },
    LEARNING_JOURNEYS: {
        TYPE: 'journeyName',
        KEY: "",
    },
    FORMS: {
        TYPE: 'Form',
        KEY: 'name',
    }
};

const SearchToggle = (props) => {

    const { microskillDataList, learningJourneyDataList, formsDataList, setFilteredCategories, setSelectedSearchedData } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const classes = useStyles({ isDarkMode });

    const [alignment, setAlignment] = useState('microskills');

    const handleAlignment = (event, newAlignment) => {
        const alignmentOptions = [
            { type: 'microskills', dataList: microskillDataList, selectedData: SELECTED_SEARCHED_DATA.MICROSKILLS },
            { type: 'learningJourney', dataList: learningJourneyDataList, selectedData: SELECTED_SEARCHED_DATA.LEARNING_JOURNEYS },
            { type: 'forms', dataList: formsDataList, selectedData: SELECTED_SEARCHED_DATA.FORMS },
        ];

        for (const option of alignmentOptions) {
            if (newAlignment === option.type) {
                setAlignment(newAlignment);
                setFilteredCategories(option.dataList);
                setSelectedSearchedData(option.selectedData);
                return;
            }
        }
    };

    const toggleBtnStyling = {
        fontWeight: '700',
        minWidth: '33.33%',
        border: 'none',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
    }

    return (
        <Stack direction="row" spacing={1} className={classes.toggleContainer}>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="searched-data-list"

                style={{
                    width: '100%', border: 'none', backgroundColor: 'none',
                }}
            >
                <StyledToggleButton
                    value="microskills"
                    aria-label="microskills"
                    style={toggleBtnStyling}
                >
                    Microskills ({microskillDataList?.length})
                </StyledToggleButton>

                <StyledToggleButton
                    value="learningJourney"
                    aria-label="learningJourney"
                    style={toggleBtnStyling}
                >
                    Journeys ({learningJourneyDataList?.length})
                </StyledToggleButton>

                <StyledToggleButton
                    value="forms"
                    aria-label="forms"
                    style={toggleBtnStyling}
                >

                    Forms ({isEmpty(formsDataList) ? 0 : formsDataList?.length})
                </StyledToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}

const CategorySearch = (props) => {

    const { isCategorySearchLoading, microskillDataList, learningJourneyDataList, formsDataList, handleSearchBarClick, searchInputValue, setOpenCategoryFilter } = props;

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { isMobile, isTablet } = useSelector(state => state?.common);

    const classes = useStyles({ isDarkMode });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // states : 
    const [filteredCategories, setFilteredCategories] = useState(microskillDataList);
    // we've shorten the name of state selectedSearchedData to srchData : 
    const [srchData, setSrchData] = useState(SELECTED_SEARCHED_DATA?.MICROSKILLS);

    // refs : 
    const lottieRef = useRef(null);

    useEffect(() => {
        setFilteredCategories(microskillDataList)
    }, [microskillDataList, learningJourneyDataList, formsDataList]);


    const handleSearchClick = async (item) => {

        const microskillId = item?.microskillId;
        const journeyId = item?.journeyId;
        const formId = item?.formId;

        if (microskillId) {

            const payload = {
                "contentId": microskillId,
                "type": "MICROSKILL",
                "searchText": searchInputValue,
            }

            const response = await postAllSearchedData(payload);

            if (response.status === 500 || response.status === 404) {
                console.log('Error Found in Posting the Searched Data');
            }

            navigate(`/challenge-list/${microskillId}`);
            return;
        }

        if (journeyId) {

            const payload = {
                "contentId": journeyId,
                "type": "JOURNEY",
                "searchText": searchInputValue,
            }

            const response = await postAllSearchedData(payload);

            if (response.status === 500 || response.status === 404) {
                console.log('Error Found in Posting the Searched Data');
            }

            navigate(`/learning-journey/${journeyId}`);
            return;
        }

        if (formId) {

            const payload = {
                "contentId": formId,
                "type": "FORM",
                "searchText": searchInputValue,
            }

            const response = await postAllSearchedData(payload);

            if (response.status === 500 || response.status === 404) {
                console.log('Error Found in Posting the Searched Data');
            }

            handleSearchBarClick();

            if (!isMobile || !isTablet) {
                setOpenCategoryFilter(false);
            }

            const currentFormId = item?.formId;
            dispatch(setFormIdData(currentFormId));
            dispatch(setIsFormOpen(true));
            return;
        }

    }

    return (
        <Box component="nav">

            <div className={classes.searchPageContainer}>

                <div className={classes.searchesContainer}>

                    <div className={classes.searchToggleContainer}
                    >

                        <SearchToggle
                            microskillDataList={microskillDataList}
                            learningJourneyDataList={learningJourneyDataList}
                            formsDataList={formsDataList}
                            setFilteredCategories={setFilteredCategories}
                            setSelectedSearchedData={setSrchData}
                        />

                    </div>


                    {!isCategorySearchLoading ?
                        <>
                            {!isEmpty(filteredCategories) ?

                                (<section className={classes.statusWiseSearchesContainer}>
                                    <ul className={`${classes.listWrapper} scrollbar`}>

                                        {filteredCategories?.map((item, index) => (

                                            <Button variant="text"
                                                className={classes.navigationText} key={index} sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    borderRadius: '0',
                                                    gap: '0.5rem',
                                                    [theme.breakpoints.up('md')]: {
                                                        padding: '0.5rem 4rem',
                                                        cursor: 'pointer',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                        color: `${theme.palette.fontColor}`,
                                                    },
                                                }} onClick={() => { handleSearchClick(item) }}>

                                                <p className={classes.searchedText}>
                                                    {isEmpty(srchData?.KEY) ?
                                                        (item[srchData?.TYPE] ? item[srchData.TYPE] : '') :
                                                        (item[srchData?.TYPE]?.[srchData.KEY] ? item[srchData.TYPE][srchData.KEY] : '')}
                                                </p>

                                                <div className={`${classes.navigationBtn} ${classes.centerFlex}`}>
                                                    <img src="/images/icons/left_arrow.svg" alt="icon" width={15} />
                                                </div>

                                            </Button>


                                        ))
                                        }
                                    </ul>
                                </section>) :

                                <div className={classes.noDataFound}>
                                    <Lottie
                                        ref={lottieRef}
                                        animationData={NO_DATA_FOUND}
                                        style={{ width: '100%', height: '100%' }}
                                        loop={true}
                                        play={true}
                                        onComplete={() => console.log('Animation completed')}
                                    />

                                </div>}

                        </> : (
                            <div className={classes.noDataFound}>
                                <CircularProgress />
                            </div>
                        )
                    }


                </div>
            </div>

        </Box>
    )
}

export default CategorySearch;