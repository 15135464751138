import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import DraggableList from "react-draggable-list"


// mui
import { Button, CircularProgress } from "@mui/material";

// components
import ScoreBox from "../score-box";

// utils
import isEmpty from "../../../utils/isEmpty";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";
import { SEQ_ILA_GRID_INITIAL_MOBILE, SEQ_ILA_GRID_INITIAL } from './index.styles'

const SAFE_RESPONSES_LIMIT = 5;

const IlaSequenceOne = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        viewFullScreen
    } = props

    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);
    const [activeQindex, setActiveQindex] = useState(0);
    // const [showTemplate, setShowTemplate] = useState(false);

    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);

    const [fSize, setFSize] = useState(1);

    const [answerList, setAnswerList] = useState([]);
    const [isSequenced, setIsSequenced] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    const incorrectAttemptsIdsRef = useRef([]);
    const optionsBoxRef = useRef();
    const optionRef = useRef();
    const answersResponseRef = useRef([])
    const scrollingContainerRef = useRef()

    const bufferTime = 400;
    const transitionMs = 1000;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    useEffect(() => {
        window.addEventListener("resize", resFont);
        return window.removeEventListener("resize", resFont);
    }, [])

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    const setOrderToAnswers = () => {
        const newData = data[activeQindex].answers
        const newAnswers = newData.map((elem, index) => {
            elem["newOrder"] = index + 1

            return elem
        })
        setAnswerList(newAnswers)
    }
    useEffect(() => {
        setOrderToAnswers()
    }, [activeQindex])

    // submit button handler
    const handleSubmitBtnClick = async () => {
        // console.log("submit btn clicked", answerList)

        // handle score logic
        setIsTransitioning(true)
        let currentTime = Date.now();
        let item = data[activeQindex];
        let showScore = data[activeQindex].score > 0;

        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        let allCorrect = [];
        let draggable_items = document.querySelectorAll(".draggable-item");

        // using while loop to add some effects
        let index = 0;
        while (index < answerList.length) {
            let elem = answerList[index]
            if (elem.sequence === elem.newOrder) {
                draggable_items[index].style = `
                    background: ${palette.ilaCorrect1};
                `
                allCorrect.push(true)
            } else {
                draggable_items[index].style = `
                    background: ${palette.ilaInCorrect1};
                `
                allCorrect.push(false)
            }
            await sleep(bufferTime)
            if (index >= SAFE_RESPONSES_LIMIT && !isEmpty(scrollingContainerRef.current)) {
                scrollingContainerRef.current.scrollTo({
                    top: SAFE_RESPONSES_LIMIT * draggable_items[index]?.offsetHeight ?? 0,
                    behavior: 'smooth',
                })
            }
            index++
        }

        await sleep(bufferTime)

        // Handle Incorrect attempt
        if (allCorrect.includes(false)) {
            // console.log("something incorrect")
            isCorrectRef.current = false;

            handleChallengeAttemptData({
                qId: item.id,
                cardId: item.id,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: false
            });


        }
        // Handle Correct attempt
        else {
            // console.log("all correct")
            setIsSequenced(true)

            isCorrectRef.current = true;
            handleChallengeAttemptData({
                qId: item.id,
                cardId: item.id,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: true
            });
        }
        currentTimeRef.current = currentTime;

        if (showScore && !allCorrect.includes(false)) {
            // waiting to display score
            await sleep(bufferTime);

            if (isMobile) {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL_MOBILE;
            } else {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
                if (window.innerWidth < 1300 && window.innerWidth > 1024 && !viewFullScreen) {
                    optionsBoxRef.current.style.marginTop = '-0.5rem';
                }
            }
            scoreListRef.current.add(`${data[activeQindex].id}-${data[activeQindex].score}`);
            await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, true);

            await sleep(bufferTime * 2) // wait for score animation
        }
        // setShowTemplate(true);

        if (data.length - 1 > activeQindex) {
            if (data[activeQindex].mandatory && allCorrect.includes(false)) {
                // mandatory and incorrect
                setIsSequenced(false)

                // for try again template
                await sleep(transitionMs * 1.5)
                // setIsSequenced(null)
                handleRetryBtnClick()
                return;
            }
            else {
                // CORRECT
                await sleep(bufferTime * 3)
                setActiveQindex(prev => prev + 1)
            };
        } else {
            await sleep(bufferTime * 3)
            if (data[activeQindex].mandatory && allCorrect.includes(false)) {
                // mandatory and incorrect
                setIsSequenced(false)

                // for try again template
                await sleep(transitionMs * 1.5)
                // setIsSequenced(null)
                handleRetryBtnClick()
                return
            }
            attemptedIds.current.push(ilaId)
            next();
        }

        // // reset all style
        await sleep(bufferTime);

        isCorrectRef.current = null;
        setIsSequenced(null);
        setIsTransitioning(false)
        if (!isEmpty(optionsBoxRef.current)) optionsBoxRef.current.style.marginTop = '0';
        if (!isEmpty(ilaBoxRef.current)) {
            if (isMobile) {
                ilaBoxRef.current.style.gridTemplateRows = SEQ_ILA_GRID_INITIAL_MOBILE
            } else {
                ilaBoxRef.current.style.gridTemplateRows = SEQ_ILA_GRID_INITIAL
            }
        }
    }

    // Component for submit button
    const SubmitButton = () => {
        return (
            <div className={classes.btnBox}>
                <Button variant="contained"
                    className={`${classes.submitBtn}`}
                    onClick={handleSubmitBtnClick}>
                    Submit
                </Button>
            </div>
        )
    }

    // // Component for retry button
    const RetryButton = () => {
        return (
            // <div className={classes.btnBox}>
            //     <Button variant="contained"
            //         className={`${classes.submitBtn} ${classes.centerFlex}`}
            //         onClick={handleRetryBtnClick}>
            //         Try Again!
            //     </Button>
            // </div>
            <div
            // className={classes.correctIncorrectTextBox}
            >
                <IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />
            </div>
        )
    }

    const handleRetryBtnClick = async () => {
        // setAnswerList(data[activeQindex].answers)
        setOrderToAnswers()
        document.querySelectorAll(".draggable-item").forEach(elem => elem.style = "")
        setIsSequenced("")
        await sleep(bufferTime * 3 / 4)
        setIsSequenced(null)
        setIsTransitioning(false)
        // setAnswersResponse([])
        answersResponseRef.current = []
    }


    const _onListChange = (newList) => {
        let newSeqList = newList.map((elem, index) => {
            elem["newOrder"] = index + 1
            return elem
        })
        setAnswerList(newSeqList);
    };

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef} style={{
                        fontSize: `${fSize}rem`
                    }}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeQindex].questionNo}`}</div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {data[activeQindex].question}
                            </div>
                            <div className={`${classes.optionsContainer} scrollbar`} ref={scrollingContainerRef}>
                                <div className={classes.optionsBox} ref={optionsBoxRef}
                                    style={{ touchAction: "manipulation" }}
                                // style={{ touchAction: "pan-y" }}
                                >
                                    {
                                        isTransitioning && <div className={classes.optionContainerOverlay}></div>
                                    }
                                    <DraggableList
                                        itemKey="id"
                                        template={DraggableItem}
                                        list={answerList}
                                        commonProps={{
                                            isMobile,
                                            isTablet,
                                            scrollingContainerRef,
                                            isDragging
                                        }}
                                        onMoveEnd={(newList) => _onListChange(newList)}
                                        container={() => optionsBoxRef.current}
                                        springConfig={{ stiffness: 300, damping: 42 }}
                                        // autoScrollMaxSpeed={100}
                                        autoScrollRegionSize={30000}
                                        constrainDrag={true}
                                        onDragStart={() => {
                                            setIsDragging(true);
                                            let body = document.getElementsByTagName("body")[0]
                                            body.style.overscrollBehavior = 'none';
                                            body.style.overflow = 'hidden';
                                            if (!isEmpty(scrollingContainerRef.current)) scrollingContainerRef.current.style = "overflow-y: hidden"
                                        }}
                                        onDragEnd={() => {
                                            setIsDragging(false);
                                            let body = document.getElementsByTagName("body")[0]
                                            body.style.overscrollBehavior = '';
                                            body.style.overflow = '';
                                            if (!isEmpty(scrollingContainerRef.current)) scrollingContainerRef.current.style = ""
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                isCorrectRef.current !== true && <div
                                    className={classes.submitBtnContainer}
                                    style={{
                                        alignSelf: viewFullScreen || isMobile ? 'flex-start' : '',
                                    }}
                                >
                                    {
                                        isSequenced === null
                                            ? !isTransitioning && <SubmitButton /> // if transitioning is running, hide submit button
                                            : isSequenced === false && <RetryButton />
                                    }
                                </div>
                            }
                            {
                                // additional styling optional rendering logic is writting to overcome ui merging conflicts
                                !isEmpty(isSequenced) && isSequenced === true && <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'flex-end',
                                }}>
                                    <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} styling={{ alignContent: 'flex-end' }} />
                                </div>
                            }

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton /> </div>
                                )
                            }
                        </div>
                    </div >
                )
            }
        </>
    )
}

export default IlaSequenceOne;


const DraggableItem = ({ item, itemSelected, dragHandleProps, commonProps }) => {
    const scrollSpeed = 100;
    const edgeThreshold = 50;

    const { isMobile, isTablet, scrollingContainerRef, isDragging } = commonProps;
    const { onMouseDown, onTouchStart } = dragHandleProps;

    const nodeRef = React.useRef();
    const isScrollingRef = useRef(false);

    const classes = useStyles();

    const handleStarttouch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let parentElement = document.querySelector(`#draggable-item-${item.id}`)

        parentElement.style = `
            box-shadow: ${constants.ILA_OPTION_BOX_SHADOW};
            background-color: ${palette.linearGradientPrimary}
        `

        if (event.type === "touchstart") {
            event.target.addEventListener('touchmove', (e) => handleDrag(e, 'touch'))
            onTouchStart(event)
        } else {
            event.target.addEventListener('mousemove', (e) => handleDrag(e, 'mouse'))
            onMouseDown(event)
        }
    }

    const handleTouchEnd = (event) => {
        let parentElement = document.querySelector(`#draggable-item-${item.id}`)
        parentElement.style = ""

        if (event.type === "touchstart") {
            event.target.removeEventListener('touchmove', handleDrag)
        } else {
            event.target.removeEventListener('mousemove', handleDrag)
        }
        event.target.style.boxShadow = ""
    }

    const handleDrag = (event, eventType) => {
        const container = scrollingContainerRef.current;
        if (isEmpty(container)) return;

        const { top, bottom } = container.getBoundingClientRect();
        const clientY =
            eventType === "touch" ? event.touches[0].clientY : event.clientY;

        if (clientY < top + edgeThreshold) autoScroll(container, -scrollSpeed);
        else if (clientY > bottom - edgeThreshold) autoScroll(container, scrollSpeed);
    };

    const autoScroll = (container, amount) => {
        if (isScrollingRef.current) return;
        isScrollingRef.current = true;

        requestAnimationFrame(() => {
            container.scrollBy({ top: amount, behavior: "smooth" });
            isScrollingRef.current = false;
        });
    };

    useEffect(() => {
        if (!isDragging) {
            let element = document.querySelector(`#btn-1-${item.id}`);
            element.removeEventListener('touchmove', handleDrag)
            element.removeEventListener('mousemove', handleDrag)
        }
    }, [isDragging])

    return (
        <div
            className={classes.draggbleItem}
            ref={nodeRef}
        >
            <div
                className="draggable-item"
                id={`draggable-item-${item.id}`}
                // style={{
                //     width: "100%",
                //     height: "100%",
                // }}
                draggable={false}
            >
                <div className={`${classes.option} ${classes.centerFlex}`}>
                    <div
                        className={`${classes.indexBtn} ${classes.centerFlex} cursor-grab`}
                        id={`btn-1-${item.id}`}
                        draggable={false}
                        onTouchStart={(event) => handleStarttouch(event)}
                        onMouseDown={(event) => handleStarttouch(event)}
                        onTouchEnd={(event) => handleTouchEnd(event)}
                        onMouseUp={(event) => handleTouchEnd(event)}
                    >
                        <img
                            width="100%"
                            height={isMobile ? "70%" : "50%"}
                            src="/images/icons/drag-icon.svg" alt="drag"
                            className={`disableSelection ${classes.dragImg}`}
                        />
                    </div>
                    <div className={classes.answerBtn} id={`btn-2-${item.id}`}>{item.answer}</div>
                </div>
            </div>
        </div>
    )
}
